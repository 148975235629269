import axios from "axios";

export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";

export interface BlogItem {
  author: string;
  content: string;
  description: string;
  link: string;
  thumbnail: string;
  title: string;
}

export const getBlogsSuccess = (blogs: BlogItem[]) => {
  return {
    type: FETCH_BLOGS_SUCCESS,
    blogs
  }
}

export const getBlogs =
  () => async (dispatch: any) => {
    try {
      const { data } = await axios.get(
        'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2F%40officialroyale%2Ffeed&api_key=gyua35lqvo5dcqmpxno4fompbzexgwlq4umntehj'
      );

      if (data && data.items) {
        dispatch(getBlogsSuccess(data.items));
      }
    } catch (err) {
      console.log('get medium blogs failed', err);
    }
  };