/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, {
  DurationInputArg1,
  DurationInputArg2,
  unitOfTime,
} from 'moment-timezone';

import { Storage } from './storage';

export class DateHelper {
  static get default() {
    return Storage.timezone ?? moment.tz.guess();
  }
  static set default(value) {
    Storage.timezone = value || moment.tz.guess(true);
  }

  static moment(date: any): moment.Moment {
    return moment(date).tz(DateHelper.default, false);
  }

  static parse(value: any, formatter: string | null = null, timezone = null) {
    if (!value) return null;

    let result = null;
    const tz = timezone || DateHelper.default;

    if (formatter) result = moment.tz(value, formatter, tz);
    else result = moment.tz(value, tz);

    return result?.toDate();
  }

  static format(date: Date, format = 'YYYY-MM-DD hh:mm A') {
    if (!date) {
      return '';
    }

    return moment(date).tz(DateHelper.default, false).format(format);
  }

  // Compare
  static isSame(a: Date, b: Date, formatter = 'YYYY-MM-DD hh:mm A') {
    if (a === b) return true;
    if (!a || !b) {
      return false;
    }
    return DateHelper.format(a, formatter) === DateHelper.format(b, formatter);
  }

  static isBefore(a: Date, b: Date) {
    if (!a) return true;
    return moment(a).isBefore(b);
  }

  static isSameDay(a: Date, b: Date) {
    return DateHelper.isSame(a, b, 'YYYY-MM-DD');
  }
  static isSameMonth(a: Date, b: Date) {
    return DateHelper.isSame(a, b, 'YYYY-MM');
  }
  static isSameYear(a: Date, b: Date) {
    return DateHelper.isSame(a, b, 'YYYY');
  }

  // Get
  static getDayOfYear(date = new Date()) {
    return parseInt(DateHelper.format(date, 'DDD DDDD'));
  }

  static getHour(date = new Date()) {
    const result = DateHelper.format(date, 'HH');
    return parseInt(result);
  }

  static getMinute(date = new Date()) {
    const result = DateHelper.format(date, 'mm');
    return parseInt(result);
  }

  /** @returns {"am"|"pm"} */
  static getAmPm(date = new Date()) {
    const result = DateHelper.format(date, 'a');
    return result;
  }

  static period(date = new Date()) {
    if (!date) return '';

    const hour = DateHelper.getHour(date);
    const ampm = DateHelper.getAmPm(date);
    if (ampm === 'am') return 'morning';
    if (hour < 17) return 'afternoon';
    return 'evening';
  }

  // Calculation
  static startOf(date = new Date(), unit: unitOfTime.StartOf) {
    if (!date) return null;
    return moment(date)?.tz(DateHelper.default, false)?.startOf(unit)?.toDate();
  }
  static startOfHour(date: Date) {
    return DateHelper.startOf(date, 'hour');
  }
  static startOfDay(date: Date) {
    return DateHelper.startOf(date, 'day');
  }
  static startOfWeek(date: Date) {
    return DateHelper.startOf(date, 'week');
  }
  static startOfMonth(date: Date) {
    return DateHelper.startOf(date, 'month');
  }

  static endOf(date: Date, unit: unitOfTime.StartOf) {
    if (!date) return null;
    return moment(date)?.tz(DateHelper.default, false)?.endOf(unit)?.toDate();
  }
  static endOfDay(date: Date) {
    return DateHelper.endOf(date, 'day');
  }
  static endOfWeek(date: Date) {
    return DateHelper.endOf(date, 'week');
  }
  static endOfMonth(date: Date) {
    return DateHelper.endOf(date, 'month');
  }

  static add(date: Date, value: DurationInputArg1, unit: DurationInputArg2) {
    return DateHelper.moment(date)?.add(value, unit)?.toDate();
  }
  static addHour(date = new Date(), hours: number) {
    return DateHelper.add(date, hours, 'hours');
  }
  static addMinute(date = new Date(), minutes: number) {
    return DateHelper.add(date, minutes, 'minutes');
  }
  static addSeconds(date = new Date(), minutes: number) {
    return DateHelper.add(date, minutes, 'seconds');
  }

  static addDay(date = new Date(), days: number) {
    return DateHelper.add(date, days, 'days');
  }
  static addWeek(date = new Date(), days: number) {
    return DateHelper.add(date, days, 'weeks');
  }
  static addMonth(date = new Date(), months: number) {
    return DateHelper.add(date, months, 'months');
  }
  static addYear(date = new Date(), years: number) {
    return DateHelper.add(date, years, 'years');
  }

  // Compare
  static min(a: Date, b: Date) {
    return moment.min([DateHelper.moment(a), DateHelper.moment(b)])?.toDate();
  }
  static max(a: Date, b: Date) {
    return moment.max([DateHelper.moment(a), DateHelper.moment(b)])?.toDate();
  }

  // Timezone
  static isDSTTimezone(timezone = DateHelper.default) {
    return moment(new Date()).tz(timezone, false).isDST();
  }
  static isDST(date = Date.now()) {
    return moment(date).tz(DateHelper.default, false).isDST();
  }
  static applyTimezone(date: Date, timezone = null) {
    if (!date) return null;

    const tz = timezone || DateHelper.default;

    return moment(date).tz(tz, false).toDate();
  }

  static merge(params: { day: Date; time: Date }) {
    const dateText = `${DateHelper.format(
      params.day,
      'YYYY-MM-DD'
    )} ${DateHelper.format(params.time, 'hh A')}`;
    const deliveryDate = DateHelper.parse(dateText, 'YYYY-MM-DD hh A');
    return deliveryDate;
  }

  static compare(date: any, byDate = Date.now()) {
    return moment(date).valueOf() >= byDate.valueOf();
  }
}
