import {
  Header,
  LotsFormContainer,
  StakingLotsField,
  ErrorText,
  BtnContainerSecondary,
  BuyLotButton,
  CancelLotButton,
  MaxButton,
  LotName,
  RightArrowContainer,
  RoyaAmount,
  LotAmountText,
  LoadingText,
} from "../style";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ArrowRightIcon } from "../../images";
import { useSelector, useDispatch } from "react-redux";
import { sellKnightroyaLot } from "../../logic/actions";
import { getConfig, StakingLotModals, LotPrice } from "../../utils";
import { useState } from "react";

const initialValues = {
  lotAmount: "1",
  nftLotAmount: 1,
  nftDiscount: "",
};

interface Props {
  closeModal: () => void;
  showLockModal: (show: boolean) => void;
  lotBought: number;
  nftLotBought: number;
}

const SellKnightRoya = (props: Props) => {
  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );
  const [royaSelected, setRoyaSelected] = useState(true);

  const { lotOperation, totalKroyaLot, totalKNFTLot } = useSelector(
    (state: any) => state.stakingLots
  );

  const dispatch = useDispatch();

  const { closeModal, showLockModal, lotBought, nftLotBought } = props;

  const handleSellKnightRoya = (values: any) => {
    const { lotAmount, nftLotAmount } = values;
    if (royaSelected && lotBought < 7) {
      showLockModal(true);
      return;
    }
    if (!royaSelected && nftLotBought < 7) {
      showLockModal(true);
      return;
    }
    if (walletConnected) {
      if (royaSelected) {
        dispatch(
          sellKnightroyaLot(userAddress, parseInt(lotAmount), royaSelected)
        );
      } else {
        dispatch(
          sellKnightroyaLot(userAddress, parseInt(nftLotAmount), royaSelected)
        );
      }
    }
  };

  const renderRoyaText = (value: string): string => {
    if (!isNaN(parseInt(value))) {
      const lotAmount = parseInt(value);
      if (lotAmount >= 1) {
        return `${(lotAmount * LotPrice.KnightLot).toLocaleString()} $WEE`;
      }

      return "0 $WEE";
    } else {
      return "0 $WEE";
    }
  };

  const schema = Yup.object().shape({
    lotAmount: Yup.string()
      .required("Enter value")
      .test(
        "lowAmount",
        `Should be greater than or equal to 1`, 
        (val) => parseFloat(val) >= 1
      )
      .test("InsufficientFunds", `Insufficient Lot`, (val) =>
        !royaSelected ? true : BigInt(val as string) <= BigInt(totalKroyaLot)
      ),

    nftLotAmount: Yup.string()
      .required("Enter value")
      .test(
        "lowAmount",
        `Should be greater than or equal to 1`, 
        (val) => parseFloat(val) >= 1
      )
      .test(
        "InsufficientFunds",
        `Insufficient Balance`, 
        (val) => (royaSelected ? true : totalKNFTLot >= parseInt(val as string))
      ),
    nftDiscount: Yup.string(),
  });

  const rendertotalKroyaLots = () => {
    if (parseInt(totalKroyaLot) >= 0) {
      return parseInt(totalKroyaLot);
    }

    return 0;
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSellKnightRoya}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Header>UNSTAKE Knight $WEE</Header>
          <LotsFormContainer>
            <label className="checkbox-label staking-lot-modal-check">
              <input
                type="checkbox"
                id="agree"
                alt=""
                checked={royaSelected}
                onChange={() => setRoyaSelected(true)}
              />
              <span className="mark2"></span>
            </label>
            <StakingLotsField
              name="lotAmount"
              value={values.lotAmount}
              onValueChange={(vals: any) =>
                setFieldValue("lotAmount", vals.value)
              }
              decimalScale={"0"}
            />

            <LotName>Knight $WEE</LotName>
            <RightArrowContainer>
              <img src={ArrowRightIcon.default} alt="arrow" />
            </RightArrowContainer>

            <RoyaAmount className="notranslate">
              {renderRoyaText(values.lotAmount)}
            </RoyaAmount>

            <MaxButton
              type="button"
              onClick={() =>
                setFieldValue("lotAmount", rendertotalKroyaLots().toString())
              }
            >
              MAX
            </MaxButton>
          </LotsFormContainer>
          <ErrorText>
            <ErrorMessage name="lotAmount" />
          </ErrorText>
          <LotAmountText>
            Your Balance:{" "}
            <span className="notranslate">
              {`${rendertotalKroyaLots()} Knight $WEE`}
            </span>
          </LotAmountText>

          <LotsFormContainer>
            <label className="checkbox-label staking-lot-modal-check">
              <input
                type="checkbox"
                id="agree"
                alt=""
                checked={!royaSelected}
                onChange={() => setRoyaSelected(false)}
              />
              <span className="mark2"></span>
            </label>
            <StakingLotsField
              name="nftLotAmount"
              value={values.nftLotAmount}
              onValueChange={(vals: any) =>
                setFieldValue("nftLotAmount", vals.value)
              }
              decimalScale={"0"}
            />

            <LotName>Settler NFT</LotName>
            <RightArrowContainer>
              <img src={ArrowRightIcon.default} alt="arrow" />
            </RightArrowContainer>

            <RoyaAmount className="notranslate">
              {values.nftLotAmount}
            </RoyaAmount>

            <MaxButton
              type="button"
              onClick={() =>
                setFieldValue("nftLotAmount", totalKNFTLot.toString())
              }
            >
              MAX
            </MaxButton>
          </LotsFormContainer>
          <ErrorText>
            <ErrorMessage name="nftLotAmount" />
          </ErrorText>
          <LotAmountText>
            Your Balance:{" "}
            <span className="notranslate">{`${totalKNFTLot} Knight NFT`}</span>
          </LotAmountText>
          <BtnContainerSecondary>
            <BuyLotButton
              type="submit"
            >
              Unstake
            </BuyLotButton>
            <CancelLotButton type="button" onClick={closeModal}>
              Cancel
            </CancelLotButton>
          </BtnContainerSecondary>
          {lotOperation === StakingLotModals.sellKnight && (
            <LoadingText>{getConfig().transactionText}</LoadingText>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SellKnightRoya;
