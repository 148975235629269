import {SET_LOADING} from "../actions";

const initialState = {
  loading: false
}

const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    default:
      return state;
  }
}

export default loadingReducer;
