//@ts-ignore
const Images = require.context('./images', true);

//@ts-ignore
const Icons = require.context('./images/icons', true);

// export const RoyaleLogo = Images('./royale-logo.svg');

// export const UserIcon = Images('./user-icon.svg');

// export const HistoryIcon1 = Images('./history1.svg');

// export const HistoryIcon2 = Images('./history2.svg');

// export const HistoryIcon3 = Images('./history3.svg');

// export const HistoryIcon4 = Images('./history4.svg');

// export const bgIcon = Images('./bg.png');

// export const checkIcon = Images('./checkmark.png');

// export const holo1Icon = Images('./holo1.png');

// export const holo2Icon = Images('./holo2.png');

// export const QuestionCircle = Images('./question-circle.svg');

// export const RoundIcon = Images('./round-bg.png');

// export const GameLogo = Images('./game/game-icon.png');

// export const CylinderIcon = Images('./cylinder-icon.svg');

// export const RoyaAnalyticsImg = Images('./roya-analytics.svg');

// export const RoyaleFlushImg = Images('./roya-splash.png');

// export const RoyaleKingImg = Images('./king-lots.png');

// export const RoyaleQueenImg = Images('./queen-lots.png');

// export const MenuIcon = Images('./menu.svg');

// export const TwitterIcon = Images('./icon-twitter.png');

// export const TelegramIcon = Images('./icon-telegram.png');

// export const MediumIcon = Images('./icon-medium.png');

// export const QueenNftICon = Images('./royale-queen-NFT.jpg');

// export const NetworkStatBg = Images('./network-stats-bg.svg');

// export const RightArrowWhite = Images('./arrow-white.svg');

// export const EtherScanIcon = Images('./etherscan-icon.svg');

// export const BscScanIcon = Images('./bscscan-icon.svg');

// export const MaticScanIcon = Images('./matic-icon.svg');

// export const KingLotIcon = Images('./king-lots.png');

// export const NetworkArrow = Images('./network-arrow.svg');

// export const ConvertArrow = Images('./convert-arrow.svg');

// export const GradientBorder = Images('./gradient-border.png');

// export const CircleGreen = Images('./circle-green.png');

// export const CirclePurple = Images('./circle-purple.png');

// export const allTierImg = Images('./group-tier.png');

// export const roadMapIcon = Images('./roadmapslide.png');

// export const RoyaLogoIcon = Images('./Logo.png');

// export const LogoIcon = Images('./Logo.png');

// export const MenuIcon = Images('./menu-icon.png');

// export const TwitterIcon = Images('./icon-twitter.png');

// export const TelegramIcon = Images('./icon-telegram.png');

// export const MediumIcon = Images('./icon-medium.png');

// export const InstagramIcon = Images('./icon-instagram.png');

// export const TitokIcon = Images('./icon-tiktok.png');

// export const LikeIcon = Images('./icon-like.png');

// export const CommentIcon = Images('./icon-comment.png');

// export const BottomLeftBg = Images('./bottom-left.png');

// export const TopRightImg = Images('./top-right.png');

// export const LeftMiddleImg = Images('./left-middle.png');

// export const RightMiddleImg = Images('./right-middle.png');

// export const RightBottomImg = Images('./right-bottom.png');

// export const VideoFile = Images('./animation-bg.webm');

// export const modalclose = Images('./modal-close.png');

// export const ModalClose = Icons('./close.svg');

// export const CloseBtn = Images('./icons/close-43.svg');

// export const Pit1 = Images('./pit_1.png');

// export const Pit2 = Images('./pit_2.png');

// export const Pit3 = Images('./pit_3.png');

// export const Pit4 = Images('./pit_4.png');

// export const CoverIcon = Images('./cover.jpg');

// export const SideBar = Images('./sidebar-img.png');

// export const Coingecko = Images('./coingecko.svg');

// export const MarketIcon = Images('./market.svg');

// export const GamingIcon = Images('./gaming-icon.png');

// export const StakeDynamicIcon = Images('./stake-dynamic-img.png');

// export const StackIcon = Images('./stake-img.png');

// export const UnstackIcon = Images('./unstake-img.png');

// export const RoundWrapper = Images('./round-wrapper.svg');

// export const LinkedinIcon = Images('./linkedin.svg');

// export const FacebookIcon = Images('./facebook-2.svg');

// export const NewLogoIcon = Images('./new-logo-icon.png');

// export const logodropdown = Images('./logo-dropdown.png');

// export const ImgIcon = Images('./img100.png');

// export const walletgroup = Images('./walletgroup.png');

// export const connect = Images('./connect.png');

// export const BannerIcon = Images('./banner-text-img.png');

// export const GameIcon = Images('./game-logo.png');

// export const mediaTarget = Images('./media-target.png');

// export const countCircle = Images('./count-circle.png');

// export const CheckmarkIcon = Images('./checkmark.png');

// export const Image101Icon = Images('./image101.png');

// export const Image102Icon = Images('./image102.png');

// export const TierIcon = Images('./tier-img.png');

// export const whiteDropdown = Images('./white-down-arrow-png.png');

// export const IncreaseIcon = Images('./increase-icon.png');

// export const Image02Icon = Images('./partner_logos/Birdeye-final(break font)-18.png');

// export const Image09Icon = Images('./09.png');

// export const Image10Icon = Images('./10.png');

// export const Image11Icon = Images('./11.png');

// export const Image12Icon = Images('./12.png');

// export const Image13Icon = Images('./13.png');

// export const Image14Icon = Images('./14.png');

// export const Image15Icon = Images('./15.png');

// export const Image16Icon = Images('./16.png');

// export const BinanceIcon = Images('./binance.png');

// export const Team03Icon = Images('./team-img03.jpg');

// export const Team01Icon = Images('./team-img01.jpg');

// export const Team02Icon = Images('./team-img02.jpg');

// export const Newgamesidebar = Images('./Rectangle-1611.png');

// export const confirmationlogo = Images('./confirmationlogo.png');

// export const bg2Icon = Images('./bg-2.png');

// export const darkIcon = Images('./Frame 481903.png');

// export const completeIcon = Images('./Frame 481920.png');

// export const submitIcon = Images('./submit.png');

// export const dropdownicon = Images('./dropdownicon.svg');

// export const SubtractIcon = Images('./subtract.svg');

// export const HomeHeroImg = Images('./home-background.svg');

// export const Tier1 = Images('./1.png');

// export const Tier2 = Images('./2.png');

// export const Tier3 = Images('./3.png');

// export const Tier4 = Images('./4.png');

// export const Tier5 = Images('./5.png');

// export const CheckMarkIcon = Images('./check-mark.svg');

// export const PoolSelect = Images('./pool-select.svg');

// export const PlusWhiteIcon = Images('./icons/plus-white.svg');

// export const MinusWhiteIcon = Images('./icons/minus-white.svg');

// export const SuccessCheckGraIcon = Images('./icons/success-check-gra.png');

// export const FullInfoImage = Images('./full-info-image.svg');

// export const ProjectAvatarImage = Images('./project-avatar.png');

// export const DefaultGameImage = Images('./default-game-image.svg');

// export const DefaultImageIcon = Images('./default-image-icon.png');

// export const DefaultVideoImage = Images('./default-video-icon.png');

// export const SmallLine = Images('./small-line.svg');

// export const AlertCircle = Images('./icons/alert-circle.png');

// export const CheckStatus = Images('./icons/check-status.png');

// export const UncheckStatus = Images('./icons/uncheck-status.png');

// export const ArrowDownWhite = Images('./icons/arrow-down-white.svg');

// export const ArrowUpWhite = Images('./icons/arrow-up-white.svg');

// export const LogoBorder = Images('./logo-border.svg');

// export const AlertCircleWhite = Images('./icons/alert-circle-white.svg');

// export const Avatar = Images('./profile-avatar.svg');

// export const PoolIcon = Images('./participation-pool.svg');

// export const PanelIcon = Images('./admin-panel.svg');

// export const RoyaIcon = Images('./roya-exclusive.svg');

// export const PageNotFoundGra = Images('./404.png');

// export const PDFIcon = Images('./pdf_icon.png');

// export const ArrowDown = Images('./dropdown-icon-white.svg');

// export const ArrowDownBlack = Images('./dropdown-icon-black.svg');

// export const DarkMode = Images('./darkMode.png');

// export const Frame = Images('./frame.png');

// export const ImgCoverEllipse = Images('./imgCoverEllipse.png');

// export const Blog_img = Images('./blog_img.png');

// export const RoadMapNew = Images('./roadmapnew.png');

// export const HomeBannerSlide = Images('./homeBannerSlide.png');

// export const SlideArrow = Images('./slideArrow.png');

// export const SubscribeTitleImg = Images('./SubscribeTitleImg.png');

// export const Modalbg = Images('./modalbg.png');

// export const AdminPanelIcon = Images('./adminPanelIcon.png');

// export const BackArrow = Images('./backArrow.png');

// export const CheckedBackground = Images('./checkedBackground.png');

// export const StartNow = Images('./startNow.png');


// ----------------- Image ----------------- //

export const WeeweepadLogoGifImg = Images('./weeweepad-logo-gif.gif');

export const PoopManImg = Images('./poop-man.gif');

export const Loading = Images('./loading.gif');

export const UnstakeButtonImg = Images('./box/orange_buttons/orange_button_1.png');

export const AuthereumImg = Images('./authereum.svg');

export const BurnerImg = Images('./burner.svg');

export const UniLoginImg = Images('./unilogin.svg');

export const WalletConnectImg = Images('./wallet-connect.svg');

export const MewWalletImg = Images('./mewwallet.svg');

export const GraphLineGreenImg = Images('./graph-line-green.png');

export const GraphLineRedImg = Images('./graph-line-red.png');

export const PlaceHolderImg = Images('./placeholder.png');

export const PoolDataImg = Images('./pool.png');

export const TiersImg = Images('./tiers.png');

export const FlowImg = Images('./flow.png');

export const SepoliaLogoImg = Images('./sepolia-logo.svg');

export const ModalBackgroundImg = Images('./submit-form-wrapper.png');

export const Tier01GifImg = Images('./tiers/tier01.gif');

export const Tier02GifImg = Images('./tiers/tier02.gif');

export const Tier03GifImg = Images('./tiers/tier03.gif');

export const Tier04GifImg = Images('./tiers/tier04.gif');

export const Tier05GifImg = Images('./tiers/tier05.gif');

export const Tier01Img = Images('./tiers/tier01-img.png');

export const Tier02Img = Images('./tiers/tier02-img.png');

export const Tier03Img = Images('./tiers/tier03-img.png');

export const Tier04Img = Images('./tiers/tier04-img.png');

export const Tier05Img = Images('./tiers/tier05-img.png');

export const Partner01Img = Images('./partner_logos/partner01.png');

export const Partner02Img = Images('./partner_logos/partner02.png');

export const Partner03Img = Images('./partner_logos/partner03.png');

export const Partner04Img = Images('./partner_logos/partner04.png');

export const Partner05Img = Images('./partner_logos/partner05.png');

export const Partner06Img = Images('./partner_logos/partner06.png');

export const Partner07Img = Images('./partner_logos/partner07.png');

export const Partner08Img = Images('./partner_logos/partner08.png');

export const EthImg = Images('./ethereum.png');

export const BaseImg = Images('./base.png');

export const SolanaImg = Images('./solana.png');

export const TopbackCover01Img = Images('./topback-cover01.png');

export const TopbackCover02Img = Images('./topback-cover02.png');

export const TopbackCover03Img = Images('./topback-cover03.png');

export const LeftbackCoverImg = Images('./leftback-cover.png');

export const RightbackCoverImg = Images('./rightback-cover.png');

export const CompanyImg = Images('./company.svg');

export const CheckMarkImg = Images('./selectmark.png');

export const SuccessMarkImg = Images('./success-mark.png');

export const CoinbaseImg = Images('./coinbase.png');

export const TrustWalletImg = Images('./trustwallet.png');

export const MetamaskImg = Images('./metamask.png');

export const CardDefaultImg = Images('./card-default.png');

export const CardSelectImg = Images('./card-select.png');

export const NoListImg = Images('./no-list.png');

export const BlockPassImg = Images('./block-pass.png');


// ----------------- Icon ----------------- //

export const StakePlusIcon = Icons('./plus-black.svg');

export const StakeMinusIcon = Icons('./minus-black.svg');

export const ArrowRightIcon = Icons('./arrow-right.svg');

export const ArrowLeftIcon = Icons('./arrow-left.png');

export const ArrowUpIcon = Icons('./arrow-up.png');

export const ArrowDownBlackIcon = Icons('./arrow-down-black.svg');

export const ArrowDownWhiteIcon = Icons('./arrow-down-white.svg');

export const ArrowRedIcon = Icons('./arrow-red.svg');

export const ArrowGreenIcon = Icons('./arrow-green.svg');

export const AttentionIcon = Icons('./attention.svg');

export const CheckBlueIcon = Icons('./check-blue.svg');

export const CheckGreenIcon = Icons('./check-green.svg');

export const UnCheckGreenIcon = Icons('./uncheck-green.svg');

export const CrossIcon = Icons('./cross.svg');

export const GroupIcon = Icons('./group.png');

export const UploadBlackIcon = Icons('./upload-black.svg');

export const UploadWhiteIcon = Icons('./upload-white.svg');

export const UploadYellowIcon = Icons('./upload-yellow.svg');

export const RectangleIcon = Icons('./rectangle.png');

export const CopyIcon = Icons('./copy.svg');

export const MinusIcon = Icons('./minus-white.svg');

export const PlusIcon = Icons('./plus-white.svg');

export const CircleYellowIcon = Icons('./circle-yellow.png');

export const CloseIcon = Icons('./close.svg');

export const OptomizerIcon = Icons('./optimiser.png');

export const RptIcon = Icons('./rpt.png');

export const PeriodIcon = Icons('./period.png');

export const TelegramIcon = Icons('./telegram.svg');

export const TwitterIcon = Icons('./twitter.svg');

export const FacebookIcon = Icons('./facebook.svg');

export const BrowserIcon = Icons('./web-icon.svg');

export const WeeTokenIcon = Icons('./wee-token.svg');

export const HamburgerIcon = Icons('./hamburger.svg');

export const PlayIcon = Icons('./play.svg');

export const LinkIcon = Icons('./link.svg');

export const DownloadIcon = Icons('./download.svg');

export const LogoRemoveIcon = Icons('./logo-remove.png');

export const ProfileIcon = Icons('./profile.svg');

export const GameListIcon = Icons('./game-list.svg');

export const ParticipatedIcon = Icons('./participated.svg');

export const KycIcon = Icons('./kyc.svg');

export const NonEyeIcon = Icons('./noneye.png');

export const AlertCircleGradientIcon = Icons('./alert-circle-gradient.png');

export const AlertCircleRedIcon = Icons('./alert-circle-red.png');

export const AlertCircleWhiteIcon = Icons('./alert-circle-white.png');

