import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { setLoading } from "../../../../logic/actions/loading.action";
import {
  Header,
  IconContainer,
  TextContainer,
  AcceptCont,
  CheckBoxLabel,
  CheckBox,
} from "./style";
import { AttentionIcon, CheckBlueIcon } from "../../../../images";
import { StoreageKey, Paths } from "../../../../utils";

interface Props {
  handleAcceptTerms: () => Promise<void>;
}

const Accept = (props: Props) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(true);
  const { handleAcceptTerms } = props;

  const history = useHistory();

  const handleAccept = async () => {
    if (checked) {
      localStorage.setItem(StoreageKey.doNotShowAgain, "true");
    } else {
      localStorage.setItem(StoreageKey.doNotShowAgain, "false");
    }

    dispatch(setLoading(true));
    await handleAcceptTerms();
    dispatch(setLoading(false));
  };

  const goToTerms = () => {
    history.push(Paths.terms);
  };

  return (
    <Fragment>
      <Header>Attention</Header>
      <IconContainer>
        <img src={AttentionIcon.default} alt="attention" />
      </IconContainer>
      <TextContainer>
        Please accept the <span onClick={goToTerms}>terms and conditions</span>{" "}
        before continuing to the WeeWee app
      </TextContainer>
      <CheckBoxLabel>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <CheckBox>
          <img src={CheckBlueIcon.default} alt="checked" />
        </CheckBox>
        <span>Do not show it again</span>
      </CheckBoxLabel>

      <AcceptCont>
        <button className="btn-action-link" onClick={handleAccept}>I accept and agree</button>
      </AcceptCont>
    </Fragment>
  );
};

export default Accept;
