/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToInstance, Transform } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';

import {
  ADD_GAME_REGISTER,
  CHECK_IS_REGISTERED,
  GET_PARTICIPATING_GAMES_BY_USER,
  GET_PARTICIPATING_GAMES_BY_GAME_ID,
  GET_REGISTER_INFO_BY_GAMEID
} from './queries';
import { Game } from '../game';

export class GameRegisterInfo extends BaseModel {
  id: number;
  email: string;
  status: string;
  @Transform(({ value }) => Game.fromJson(value)) game?: Game;

  static fromJson(json: any) {
    return plainToInstance(GameRegisterInfo, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => GameRegisterInfo.fromJson(x)) : [];
  }

  static async addRegisterInfo(gameId: any, payload: any) {
    const { data, ...rest } = await Api.request(ADD_GAME_REGISTER, {
      gameId: gameId,
      data: payload
    });
    return { ...rest, results: get(data, 'addRegisterInfo', {}) };
  }

  static async getParticipatingGamesByUser() {
    const { data, ...rest } = await Api.request(
      GET_PARTICIPATING_GAMES_BY_USER
    );
    return { ...rest, results: GameRegisterInfo.fromJsonArray(get(data, 'getParticipatingGamesByUser', [])) };
  }

  static async getParticipatingGamesByGameId(id: number) {
    const { data, ...rest } = await Api.request(GET_PARTICIPATING_GAMES_BY_GAME_ID, {
      gameId: id
    });
    return { ...rest, results: get(data, 'getParticipatingGamesByGameId', []) };
  }

  static async checkIsRegistered(gameId: any) {
    const { data, ...rest } = await Api.request(CHECK_IS_REGISTERED, {
      gameId: gameId
    });

    return { ...rest, result: get(data, 'checkRegistered', false) };
  }

  static async getRegisterInfoByGameId(gameId: number) {
    const { data, ...rest } = await Api.request(GET_REGISTER_INFO_BY_GAMEID, {
      gameId: gameId
    });

    return { ...rest, result: get(data, 'getRegisterInfoByGameId', {}) };
  }
}
