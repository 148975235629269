import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ModalBackgroundImg } from "src/images";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 53px;
  background-image: url(${ModalBackgroundImg.default});
  background-position: center;
  background-size: 100% 100%;
  padding: 50px 20px;
  height: max-content;
  .mx-10 {
    margin: 0 10px;
  }

  @media screen and (max-width: 1200px) {
    margin-top: 39px;
  }
  @media screen and (max-width: 991px) {
    flex-direction: row;
    padding: 20px 20px;
  }
`;

export const Link = styled(NavLink).attrs((props) => ({
  activeClassName: props.activeClassName || "active",
}))`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding-left: 18px;
  color: white;
  width: 367px;
  height: 47px;
  font-size: 16px;
  line-height: 37px;
  letter-spacing: 1px;
  border-radius: 4px;
  color: var(--Black-Black-40, rgba(0, 0, 0, 0.40));
  font-family: "Gloria Hallelujah";
  font-size: 20px;
  line-height: 120%; /* 24px */
  $:hover {
    text-decoration:none;
  }
  &.${(props) => props.activeClassName} {
    background: var(--primary-orange-hover, #D0872C);
    box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;
    color: white;
    & > img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  & > span {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    width: 173px;
  }

  @media screen and (max-width: 991px) {
    width: 25% !important;
    height: 25% !important;
    padding: 8px 10px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > img {
      width: 40px !important;
      height: 40px !important;
      margin-right: 0px !important;
    }
  }

  @media screen and (max-width: 1200px) {
    width: unset;
    height: 37px;
    padding-left: 7px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 1px;
    transform: rotate(0.23deg);

    &:first-child {
      border-radius: 16px 16px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 16px 16px;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;

    & > a:nth-child(3) {
      margin-top: 2px;
    }

    & > a:nth-child(4) {
      margin-top: 2px;
    }
  }
`;

export const MobileLink = styled(NavLink).attrs((props) => ({
  activeClassName: props.activeClassName || "active",
}))`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding-left: 14px;
  color: white;
  background: linear-gradient(
    224.95deg,
    rgba(255, 255, 255, 0) 6.71%,
    rgba(255, 255, 255, 0.15) 98.54%
  );
  backdrop-filter: blur(20px);
  border-radius: 4px;
  transform: matrix(1, 0, 0.03, 1, 0, 0);
  height: 46px;
  font-size: 14px;
  letter-spacing: 2px;

  &.${(props) => props.activeClassName} {
    height: 47px;
    background: rgba(0, 0, 0, 0.37);
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.16),
      inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
  }

  & > img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 49%;
  }
`;
