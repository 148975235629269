/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Game } from 'src/core';
import {
  DateHelper,
  Paths,
  TokenType,
  getDaiContract,
  getPoolContract,
  getUsdcContract,
  getUsdtContract,
  shortenAddress
} from 'src/utils';
import {
  powToNumber,
  withThreeDecimals
} from 'src/utils/number';
import wallet from 'src/utils/wallet';

interface Props {
  className?: string;
  gameInfo: Game;
}

export const GameDetailCardContainer: React.FC<Props> = ({
  className = '',
  gameInfo
}) => {
  const [isStarted, setIsStarted] = useState('Upcoming');
  const [tokenLeft, setTokenLeft] = useState('');
  const [depositAmount, setDepositAmount] = useState('0');
  const [whitelistAmount, setWhitelistAmount] = useState(0);
  const [decimals, setDecimals] = useState(18);

  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );

  const checkStarted = () => {
    if (!gameInfo?.poolInfo) return;

    const currentDate = new Date();
    const startDate = gameInfo.poolInfo?.startTime;
    const endDate = gameInfo.poolInfo?.endTime;
    const startFlag = +currentDate - +startDate;
    const endFlag = +currentDate - +endDate;
    if (startFlag > 0 && endFlag < 0) {
      setIsStarted('In-progress');
    } else if (endFlag > 0) {
      setIsStarted('Finished');
    }
  };
  
  const getContractInternalValues = async () => {
    if (gameInfo.poolInfo?.poolAddress) {
      const tokenDecimals = gameInfo.poolInfo.tokenDecimals;
      if (tokenDecimals) setDecimals(tokenDecimals);
      const leftTokens = await getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.tokensLeft()
        .call();
      setTokenLeft((leftTokens / Math.pow(10, tokenDecimals)).toFixed(0));

      if (gameInfo.poolInfo?.tokenVarious === 0) {
        const balance = await wallet.web3.eth.getBalance(
          gameInfo.poolInfo?.poolAddress
        );
        setDepositAmount(
          withThreeDecimals(+balance / Math.pow(10, tokenDecimals))
        );
      } else if (gameInfo.poolInfo?.tokenVarious === 1) {
        const balance = await getUsdtContract()
          .methods.balanceOf(gameInfo.poolInfo?.poolAddress)
          .call();
          setDepositAmount(
            withThreeDecimals(balance / Math.pow(10, tokenDecimals))
          );
        } else if (gameInfo.poolInfo?.tokenVarious === 2) {
        const balance = await getUsdcContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, tokenDecimals))
        );
      } else if (gameInfo.poolInfo?.tokenVarious === 4) {
        const balance = await getDaiContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, tokenDecimals))
        );
      }

      const whitelist = await getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.getWhitelistedAddresses()
        .call();
      setWhitelistAmount(whitelist.length);
    }
  };

  useEffect(() => {
    if (gameInfo?.poolInfo) {
      checkStarted();
      getContractInternalValues();
    }
  }, [gameInfo, walletConnected, checkStarted]);

  return (
    <div className={`game-detail-card ${className}`}>
      <div className="game-detail-card-header">
        <div className="game-detail-card-header-ido">
          <div>Status: {isStarted}</div>
          {gameInfo?.poolInfo && (
            <div className="date">
              {DateHelper.format(gameInfo.poolInfo.startTime, 'MMM Do, YYYY')}
            </div>
          )}
        </div>
        <Link to={`${Paths.gameDetails}/${gameInfo.id}/edit`}>
          <button className="btn-action-link">Edit Project</button>
        </Link>
      </div>
      <div className="game-detail-card-body">
        <div className="body-item">
          <div>FundRaise Goal</div>
          <div>
            {gameInfo.poolInfo
              ? `${Math.round(
                  powToNumber(gameInfo.poolInfo?.totalTokens) /
                    Math.pow(10, decimals) /
                    +gameInfo.poolInfo?.tokenPrice
                ).toFixed(0)} ${TokenType[gameInfo.poolInfo?.tokenVarious]}`
              : 'TBD'}
          </div>
        </div>
        <div className="body-divider" />
        <div className="body-item">
          <div>Trade Value</div>
          <div>
            {gameInfo.poolInfo
              ? `1 ${TokenType[gameInfo.poolInfo?.tokenVarious]} = ${parseInt(
                  gameInfo.poolInfo?.tokenPrice
                ).toFixed(0)} ${gameInfo.poolInfo?.tokenSymbol}`
              : 'TBD'}
          </div>
        </div>
        <div className="body-divider" />
        <div className="body-item">
          <div>Total Token</div>
          <div>
            {gameInfo.poolInfo
              ? `${Math.round(
                  (powToNumber(gameInfo.poolInfo?.totalTokens) ?? 0) /
                    Math.pow(10, decimals)
                )}`
              : 'TBD'}
          </div>
        </div>
        <div className="body-divider" />
        <div className="body-item">
          <div>Raised Amount</div>
          <div>
            {gameInfo.poolInfo && gameInfo.poolInfo.poolAddress
              ? `${depositAmount} ${TokenType[gameInfo.poolInfo?.tokenVarious]}`
              : 'XXX'}
          </div>
        </div>
      </div>
      <div className="game-detail-card-footer">
        <div className="game-detail-card-footer-row">
          <div>
            <div>Total Safelist</div>
            <div>{gameInfo ? gameInfo.totalSafelist : 'XXX'}</div>
          </div>
          <div>
            <div>Total Whitelist</div>
            <div>{gameInfo.poolInfo ? whitelistAmount : 'XXX'}</div>
          </div>
          <div>
            <div>Remaining Tokens </div>
            <div>
              {gameInfo.poolInfo && gameInfo.poolInfo.poolAddress
                ? tokenLeft
                : 'XXX'}
            </div>
          </div>
        </div>
        <div className="game-detail-card-footer-row">
          <div>
            <div>Fee percentage</div>
            <div>
              {gameInfo.poolInfo
                ? gameInfo.poolInfo.feePercentage / 100
                : 'XXX'}
            </div>
          </div>
          <div>
            <div>Fee address</div>
            <div>
              {gameInfo.poolInfo
                ? shortenAddress(gameInfo.poolInfo.feeAddress)
                : 'XXX'}
            </div>
          </div>
          <div>
            <div>Treasure address</div>
            <div>
              {gameInfo.poolInfo
                ? shortenAddress(gameInfo.poolInfo.treasureAddress)
                : 'XXX'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
