import styled from "styled-components";

export const LoaderComponent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    z-index: 99999;

    img {
        position: fixed;
        width: 300px;
        height: 300px;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
    }
`;
