import { useEffect, useState } from 'react';
import { InputField, ErrorText } from './style';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import {
  stakeRoya,
  getRoyaReserveStakeBalance,
  getRoyaBalance,
  getRoyaRewards,
  activateCoolDown,
  claimRoyaRewards,
  getCoolDownStatus,
  closeRoyaReserveModal,
  getStakerCooldown,
  getTotalStakedRoya,
  getRoyaStats,
  openRoyaReserveModal
} from '../../logic/actions';
import { Modal, RoyaReserveUnstake, CooldownActivated } from '../../components';
import {
  RoyaReserveModal,
  renderTokenAmountText,
  fromMroya,
  fromRoya,
  toRoya,
  thousandSeparator,
  calculateRoyaApy
} from '../../utils';
import { RptIcon, PeriodIcon, OptomizerIcon } from '../../images';
const initialValues = {
  royaAmount: ''
};

export const RoyaReserve = () => {
  const dispatch = useDispatch();
  const { currentPrice } = useSelector((state: any) => state.royaAnalytics);
  const { userAddress, walletConnected, network } = useSelector(
    (state: any) => state.user
  );
  useEffect(() => {
    if (walletConnected) {
      dispatch(getRoyaReserveStakeBalance(userAddress));
      dispatch(getRoyaBalance(userAddress));
      dispatch(getRoyaRewards());
      dispatch(getCoolDownStatus());
      dispatch(getStakerCooldown(userAddress));
      dispatch(getTotalStakedRoya());
      dispatch(getRoyaStats());
    }
  }, [walletConnected, userAddress, dispatch]);

  useEffect(() => {
    const fetchCoolDownStatus = () => {
      dispatch(getCoolDownStatus());
    };

    const fetchRoyaRewards = () => {
      dispatch(getRoyaRewards());
    };

    const fetchCoolDownId = setInterval(fetchCoolDownStatus, 10000);

    const fetchRoyaRewardsId = setInterval(fetchRoyaRewards, 10000);

    return () => {
      clearInterval(fetchCoolDownId);
      clearInterval(fetchRoyaRewardsId);
    };
  }, [userAddress, dispatch]);

  const [, setCooldownTime] = useState(0);

  const {
    stakedRoyaBalance,
    userRoyaBalance,
    rewardBalance,
    isActivating,
    isClaiming,
    activeModal,
    coolDownStatus,
    totalRoyaStaked,
    stakersCooldown
  } = useSelector((state: any) => state.royaReserve);

  const handleStakeRoya = (values: any) => {
    const { royaAmount } = values;

    if (walletConnected) {
      dispatch(stakeRoya(royaAmount, userAddress));
    }
  };

  useEffect(() => {
    let updateId = setInterval(() => {
      if (coolDownStatus === '1' && stakersCooldown > 0) {
        setCooldownTime(stakersCooldown + 864000 - Date.now() / 1000);
      }
    }, 1000);

    return () => {
      clearInterval(updateId);
    };
  }, [stakersCooldown, coolDownStatus]);

  useEffect(() => {
    let updateId = setInterval(() => {
      if (coolDownStatus === '2' && stakersCooldown > 0) {
        setCooldownTime(stakersCooldown + 172800 - Date.now() / 1000);
      }
    }, 1000);

    return () => {
      clearInterval(updateId);
    };
  }, [stakersCooldown, coolDownStatus]);

  const handleActivateCooldown = () => {
    if (walletConnected) {
      if (coolDownStatus === '2') {
        dispatch(openRoyaReserveModal(RoyaReserveModal.unstake));
      } else {
        dispatch(activateCoolDown(userAddress));
        setCooldownTime(0);
      }
    }
  };

  const handleClaim = () => {
    if (walletConnected) {
      dispatch(claimRoyaRewards(userAddress, rewardBalance));
    }
  };

  const closeModal = () => {
    dispatch(closeRoyaReserveModal());
  };

  const schema = Yup.object().shape({
    royaAmount: Yup.string()
      .required('Enter value')
      .test(
        'lowAmount',
        `Should be greater than 0`, 
        (val) => parseFloat(val) > 0
      )
      .test(
        'InsufficientFunds',
        `Insufficient Funds`, 
        (val) => BigInt(toRoya(!!val ? val : '0')) <= BigInt(userRoyaBalance)
      )
  });

  const renderMRoyaToDollar = () => {
    const result = currentPrice * parseFloat(fromMroya(rewardBalance));

    if (isFinite(result)) return result.toFixed(2);

    return '0';
  };

  return (
    <>
      <div className="container">
        <div className="roya-reserve-heading">
          <div className="heading-row">
            <div className="headingbox">
              <h2>$WEE Staking</h2>
            </div>
          </div>
          <p>$WEE Staking is an additional way to earn yield on your $WEE.</p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleStakeRoya}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="reserve-stake-box">
                <h4 className="text-color-white">How much would you like to stake?</h4>
                <div className="pool-amount-col reserve-box">
                  <div className="pool-form-control">
                    <InputField
                      name="royaAmount"
                      value={values.royaAmount}
                      onValueChange={(vals: any) =>
                        setFieldValue('royaAmount', vals.value)
                      }
                      decimalScale={'18'}
                    />
                    <ErrorText>
                      <ErrorMessage name="royaAmount" />
                    </ErrorText>
                  </div>
                  <div
                    className="max-btn"
                    onClick={() =>
                      setFieldValue('royaAmount', fromRoya(userRoyaBalance))
                    }
                  >
                    MAX
                  </div>
                </div>
                <div className="reserve-stake-display">
                  <span className="stake-available notranslate">
                    Available to stake:{' '}
                    {renderTokenAmountText(fromRoya(userRoyaBalance))}
                  </span>
                  <button type="submit" className="btn-action-link">
                    Stake
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className="roye-stake-row">
          <div className="row">
            <div className="col-md-6">
              <div className="roye-box">
                <div className="roye-stake-box text-center">
                  <h4 className="text-color-white">$WEE Staked</h4>
                  <span className="total-volume notranslate">
                    {!!stakedRoyaBalance &&
                      renderTokenAmountText(fromRoya(stakedRoyaBalance))}
                  </span>
                  <button
                    type="button"
                    className="btn-action-link"
                    onClick={handleActivateCooldown}
                    disabled={
                      isActivating ||
                      coolDownStatus === '1' ||
                      coolDownStatus === '' ||
                      stakedRoyaBalance === '' ||
                      stakedRoyaBalance === '0'
                    }
                  >
                    {coolDownStatus === '0' && 'Activate Cooldown'}
                    {(coolDownStatus === '1' || coolDownStatus === '2') &&
                      'Unstake'}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="roye-box">
                <div className="roye-stake-box text-center">
                  {network === '0x1' && <h4 className="text-color-white">Claimable $WEE</h4>}
                  {network !== '0x1' && <h4 className="text-color-white">Claimable $WEE</h4>}
                  <span className="total-volume notranslate">
                    {!!rewardBalance &&
                      renderTokenAmountText(fromMroya(rewardBalance))}
                  </span>
                  <button
                    type="button"
                    className="btn-action-link"
                    onClick={handleClaim}
                    disabled={
                      isClaiming ||
                      rewardBalance === '0' ||
                      rewardBalance === ''
                    }
                  >
                    Claim
                  </button>
                  <span className="claim-amount">
                    $ {thousandSeparator(renderMRoyaToDollar())}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="volume-wrap reserve-volume-wrap">
          <div className="row volume-row">
            <div className="col-md-4">
              <div className="volume-box-wrap">
                <div className="volume-box">
                  <div className="volume-icon-box1">
                    <img src={OptomizerIcon.default} alt="" />
                  </div>
                  <h4 className="text-color-white">$WEE Staked</h4>
                  <span className="total-volume notranslate">
                    {!!stakedRoyaBalance &&
                      renderTokenAmountText(fromRoya(stakedRoyaBalance))}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="volume-box-wrap">
                <div className="volume-box">
                  <div className="volume-icon-box">
                    <img src={PeriodIcon.default} alt="" />
                  </div>
                  <h4 className="text-color-white">Cooldown Period</h4>
                  <span className="total-volume">10 days</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="volume-box-wrap">
                <div className="volume-box">
                  <div className="volume-icon-box">
                    <img src={RptIcon.default} alt="" />
                  </div>
                  <h4 className="text-color-white">Staking APY</h4>
                  <span className="total-volume notranslate">
                    {thousandSeparator(
                      calculateRoyaApy(fromRoya(totalRoyaStaked))
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={activeModal === RoyaReserveModal.unstake}
        closeModal={() => closeModal()}
      >
        <RoyaReserveUnstake closeModal={() => closeModal()} />
      </Modal>

      <Modal
        show={activeModal === RoyaReserveModal.cooldownActivated}
        closeModal={() => closeModal()}
      >
        <CooldownActivated closeModal={closeModal} />
      </Modal>
    </>
  );
};
