import { RoyaStakeModel } from 'src/utils';

export const renderLotsItem = (
  model: RoyaStakeModel,
  lotAmount: number,
) => (
  <div className="tier-com-applications-list-item">
    <div className="tier-applications-col">
      <div className="applications-original-img1">
        <img src={model.icon} alt={model.title} />
      </div>
      <span>{model.title}</span>
    </div>
    <div className="tier-applications-col amount-label">{lotAmount}</div>
  </div>
);

export const renderLotsItemMobile = (
  model: RoyaStakeModel,
  lotAmount: number
) => (
  <div className="tier-com-applications-list-item">
    <div className="tier-com-applications-list-item-header">
      <img src={model.icon} alt={model.title} />
      <span>{model.title}</span>
    </div>
    <div className="tier-com-applications-list-item-row">
      <span>STAKING LOTS</span>
      <span>{lotAmount}</span>
    </div>
  </div>
);
