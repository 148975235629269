/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transform, plainToInstance } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';
import { getPoolContract } from 'src/utils';

import { POOL_BY_ID, UPSERT_POOL } from './queries';

export class PoolInfoModel extends BaseModel {
  id: number;
  gameId: number;
  type: boolean;
  poolAddress: string;
  poolCreator: string;
  tokenAddress: string;
  tokenSymbol: string;
  tokenPrice: string;
  totalTokens: string;
  minRaise: string;
  startTime: Date;
  endTime: Date;
  enableLock: boolean = false;
  min1: number;
  max1: number;
  max2: number;
  max3: number;
  max4: number;
  max5: number;
  isPrivate: boolean = false;
  ratio: number;

  static fromJson(json: any) {
    return plainToInstance(PoolInfoModel, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => PoolInfoModel.fromJson(x)) : [];
  }

  static async findPoolInfoById(id: number) {
    const { data, ...rest } = await Api.request(POOL_BY_ID, {
      id: id
    });

    return {
      ...rest,
      results: get(data, 'findPoolInfoById', [])
    };
  }

  static async upsertPool(params: any) {
    const { data, ...rest } = await Api.request(UPSERT_POOL, { data: params });

    return {
      ...rest,
      results: PoolInfoModel.fromJson(get(data, 'upsertPoolInfo'))
    };
  }
}

export class Whitelist {
  walletAddress: string;
  @Transform(({ value }) => parseInt(value)) tier: number;
  isPublic: boolean;
  email: string;
  name: string;
  twitter: string;

  constructor(json: any) {
    const [isPublic, walletAddress, tier] = json;

    this.isPublic = isPublic;
    this.walletAddress = walletAddress;
    this.tier = parseInt(tier);
  }

  static fromJson(json: any) {
    return new Whitelist(json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => Whitelist.fromJson(x)) : [];
  }

  static async findPrivateWhitelist(poolAddress: string) {
    const response = await getPoolContract(poolAddress)
      .methods.getWhitelistedAddresses()
      .call();
    const results = Whitelist.fromJsonArray(response);
    return results.filter((x) => !x.isPublic);
  }

  static async findPublicWhitelist(poolAddress: string) {
    const response = await getPoolContract(poolAddress)
      .methods.getWhitelistedAddresses()
      .call();
    const results = Whitelist.fromJsonArray(response);
    return results.filter((x) => x.isPublic);
  }
}
