import { Fragment } from "react";
import { useDispatch } from 'react-redux';

import { setLoading } from "../../../../logic/actions/loading.action";
import { Header, IconContainer, ConnectCont } from "./style";
import { Divider } from "../select/style";
import {
  MetamaskImg,
  AuthereumImg,
  BurnerImg,
  UniLoginImg,
  WalletConnectImg,
  MewWalletImg,
} from "../../../../images";
import { WalletTypes } from "../../../../utils";

interface Props {
  handleConnect: () => Promise<void>;
  selectedWallet: number;
}

const Connect = (props: Props) => {
  const dispatch = useDispatch();

  const { handleConnect, selectedWallet } = props;

  // const setWalletName = (): string => {
  //   switch (selectedWallet) {
  //     case WalletTypes.metamask:
  //       return "metamask";
  //     case WalletTypes.walletConnect:
  //       return "WalletConnect";
  //     case WalletTypes.authereum:
  //       return "Authereum";
  //     case WalletTypes.burnerConnect:
  //       return "BurnerConnect";
  //     case WalletTypes.uniLogin:
  //       return "UniLogin";
  //     case WalletTypes.mewWallet:
  //       return "MEW Wallet";
  //     default:
  //       return "";
  //   }
  // };

  const setWalletImageSource = (): string => {
    switch (selectedWallet) {
      case WalletTypes.metamask:
        return MetamaskImg.default;
      case WalletTypes.walletConnect:
        return WalletConnectImg.default;
      case WalletTypes.authereum:
        return AuthereumImg.default;
      case WalletTypes.burnerConnect:
        return BurnerImg.default;
      case WalletTypes.uniLogin:
        return UniLoginImg.default;
      case WalletTypes.mewWallet:
        return MewWalletImg.default;
      default:
        return "";
    }
  };

  const handleOnClickConnect = async (): Promise<void> => {
    dispatch(setLoading(true));
    await handleConnect();
    dispatch(setLoading(false));
  };

  return (
    <Fragment>
      <Header>Connect Wallet</Header>
      <Divider />
      <IconContainer>
        <img src={setWalletImageSource()} alt="wallet icon" />
      </IconContainer>
      <ConnectCont>
        <button
          data-text="Connect"
          onClick={handleOnClickConnect}
          className="btn-action-link"
        >
          Connect
        </button>
      </ConnectCont>
    </Fragment>
  );
};

export default Connect;
