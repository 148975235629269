export const powToNumber = (x: any) => {
  if(!x){
    return 0;
  }
  if (Math.abs(+x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return Math.round(x);
};

export function withThreeDecimals(val: any) {
  if (val !== null && val !== undefined) {
    var with3Decimals = val.toString().match(/^-?\d+(?:\.\d{0,3})?/);
    if (with3Decimals && with3Decimals.length > 0) {
      return with3Decimals[0];
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function withFiveDecimals(val: any) {
  if (val) {
    var with5Decimals = val.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0];
    if (with5Decimals && with5Decimals.length > 0) {
      return with5Decimals[0];
    } else {
      return '';
    }
  } else {
    return '';
  }
}
