/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

import { Game } from "src/core";
import {
  Paths,
  TokenType,
  getPoolContract,
  setTokenFromAmount,
  useAppSelector,
} from "src/utils";
import { withThreeDecimals } from "src/utils/number";
import { PlaceHolderImg } from "src/images";

interface Props {
  className?: string;
  value: Game;
}

enum StatusProp {
  UPCOMING,
  ACTIVE,
  FINISHED,
}

export const GameDetailCard: React.FC<Props> = ({ className = "", value }) => {
  const [fundRaiseGoal, setFundRaiseGoal] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [poolType, setPoolType] = useState(false);
  const [status, setStatus] = useState<StatusProp>();
  const [, setDays] = useState(0);
  const [, setHours] = useState(0);
  const [, setMins] = useState(0);

  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  const getProgress = () => {
    let result = 1;
    if (!value.poolInfo) {
      return result;
    }

    const currentDate = new Date();
    const startDate = value.poolInfo.startTime;
    const endDate = value.poolInfo.endTime;
    const startFlag = +currentDate - +startDate;
    const periodFlag = +endDate - +startDate;
    const endFlag = +currentDate - +endDate;

    if (startFlag < 0) {
      const elaps = Date.now() - +startDate;
      result = elaps / startFlag;
    } else if (startFlag > 0 && endFlag < 0) {
      const elaps = Date.now() - +startDate;
      result = elaps / periodFlag;
    } else if (startFlag > 0 && periodFlag > 0 && endFlag > 0) {
      const elaps = Date.now() - +endDate;
      result = elaps / endFlag;
    }

    return result;
  };

  const getAllVals = async () => {
    if (!value.poolInfo) {
      setStatus(0);
      return true;
    }
    // set pool type
    value && setPoolType(value.poolInfo.poolType);

    // set FundRaise Goal value
    if (value && value.poolInfo) {
      const tokenVarious = value.poolInfo.tokenVarious;
      const calcAmount = await setTokenFromAmount(
        value.poolInfo.totalTokens,
        tokenVarious
      );

      if (calcAmount) {
        const val =
          +value.poolInfo.totalTokens /
          parseFloat(value.poolInfo.tokenPrice) /
          Math.pow(10, value.poolInfo.tokenDecimals);
        if (val) {
          setFundRaiseGoal(withThreeDecimals(val));
        }
      }
    }

    // set Date Status
    const currentDate = new Date();
    const startDate = value.poolInfo.startTime;
    const endDate = value.poolInfo.endTime;
    const startFlag = +currentDate - +startDate;
    const periodFlag = +endDate - +startDate;
    const endFlag = +currentDate - +endDate;

    if (startFlag < 0) {
      setStatus(0);
      const calDays = Math.floor(Math.abs(startFlag) / (60 * 60 * 24 * 1000));
      const calHours = Math.floor(
        (Math.abs(startFlag) - calDays * 60 * 60 * 24 * 1000) / (60 * 60 * 1000)
      );
      const calMinutes = Math.floor(
        (Math.abs(startFlag) -
          calDays * 60 * 60 * 24 * 1000 -
          calHours * 60 * 60 * 1000) /
          60000
      );
      setDays(calDays);
      setHours(calHours);
      setMins(calMinutes);
    } else if (startFlag > 0 && endFlag < 0) {
      setStatus(1);
      const calDays = Math.floor(periodFlag / (60 * 60 * 24 * 1000));
      const calHours = Math.floor(
        (periodFlag - calDays * 60 * 60 * 24 * 1000) / (60 * 60 * 1000)
      );
      const calMinutes = Math.floor(
        (periodFlag -
          calDays * 60 * 60 * 24 * 1000 -
          calHours * 60 * 60 * 1000) /
          60000
      );
      setDays(calDays);
      setHours(calHours);
      setMins(calMinutes);
    } else if (startFlag > 0 && periodFlag > 0 && endFlag > 0) {
      setStatus(2);
      const calDays = Math.floor(endFlag / (60 * 60 * 24 * 1000));
      const calHours = Math.floor(
        (endFlag - calDays * 60 * 60 * 24 * 1000) / (60 * 60 * 1000)
      );
      const calMinutes = Math.floor(
        (endFlag - calDays * 60 * 60 * 24 * 1000 - calHours * 60 * 60 * 1000) /
          60000
      );
      setDays(calDays);
      setHours(calHours);
      setMins(calMinutes);
    } else {
      setStatus(1);
      const calDays = Math.floor(periodFlag / (60 * 60 * 24 * 1000));
      const calHours = Math.floor(
        (periodFlag - calDays * 60 * 60 * 24 * 1000) / (60 * 60 * 1000)
      );
      const calMinutes = Math.floor(
        (periodFlag -
          calDays * 60 * 60 * 24 * 1000 -
          calHours * 60 * 60 * 1000) /
          60000
      );
      setDays(calDays);
      setHours(calHours);
      setMins(calMinutes);
    }
  };

  const getMinimumRaiseAchieved = async () => {
    if (!value.poolInfo) return "";

    try {
      if (walletConnected && value) {
        const poolAddress = value.poolInfo.poolAddress;
        if (poolAddress) {
          const poolResult = await getPoolContract(poolAddress)
            .methods.minimumRaiseAchieved()
            .call({ from: userAddress });
          if (poolResult) setIsSuccess(true);
        }
      }
    } catch (e) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    if (value) {
      getProgress();
      getAllVals();
      if (value.poolInfo) {
        getMinimumRaiseAchieved();
      }
    }
  }, [value, walletConnected]);

  return (
    <div key={value.id} className={`game-detail-card ${className}`}>
      <div className="game-detail-card-container">
        <div className="image-container">
          {status === 0 && <p>Upcoming</p>}
          {status === 1 && <p style={{ display: "none" }}>Upcoming</p>}
          {status === 2 &&
            (poolType ? (
              <p
                style={{
                  background:
                    "linear-gradient(223.88deg, rgba(203, 79, 223, 0) 1.79%, #802DFD 98.47%)",
                }}
              >
                Success
              </p>
            ) : isSuccess ? (
              <p
                style={{
                  background:
                    "linear-gradient(223.88deg, rgba(203, 79, 223, 0) 1.79%, #802DFD 98.47%)",
                }}
              >
                Success
              </p>
            ) : (
              <p
                style={{
                  background:
                    "linear-gradient(223.88deg, rgba(182, 187, 189, 0) 1.79 %, #676189 98.47 %)",
                }}
              >
                Failure
              </p>
            ))}

          <img
            className="thumbnail"
            src={
              value?.thumbnail
                ? value?.thumbnail.split(",")[0]
                : PlaceHolderImg.default
            }
            alt="meme"
          />
          <div className="logo">
            <img
              src={value?.logo ? value?.logo : PlaceHolderImg.default}
              alt="meme logo"
            />
          </div>
        </div>
        <div className="game-card-body">
          <div className="card-body-title">{value.name}</div>
          <div className="card-body-row">
            <span>Fundraise goal</span>
            <span>
              {value.poolInfo
                ? `${
                    fundRaiseGoal ? fundRaiseGoal : ""
                  } ${
                    typeof TokenType[value.poolInfo.tokenVarious] ===
                    "undefined"
                      ? "No Pool"
                      : TokenType[value.poolInfo.tokenVarious]
                  }`
                : "TBD"}
            </span>
            {/* <span>
              {value.poolInfo
                ? `${fundRaiseGoal} ${TokenType[value.poolInfo.tokenVarious]}`
                : "TBD"}
            </span> */}
          </div>
          <div className="card-body-row">
            <span>Max Allocation</span>
            <span>
              {value.poolInfo
                ? value.poolInfo?.max5 > 0
                  ? value.poolInfo?.max5
                  : "Unlimited"
                : "TBD"}
            </span>
          </div>
          {/* <div className="progress-bar-container">
            <div className="progress-bar-description">
              {status === 0 &&
                (value.poolInfo ? "Registration ends in" : "Upcoming soon")}
              {status === 1 && "IPO ends in"}
              {status === 2 && "Finished"}
              &nbsp;
              {days !== 0 && `${days} days`} {hours !== 0 && `${hours} hours`}{" "}
              {mins !== 0 && `${mins} mins`}
              &nbsp;
              {status === 2 && "Ago"}
            </div>
            <ProgressBar value={getProgress()} />
          </div> */}
        </div>
        <div className="game-card-footer">
          <Link
            className="btn-action-link"
            to={`${Paths.gameDetails}/${value.id}`}
            style={{ paddingLeft: "3rem", paddingRight: "3rem", fontSize: '18px' }}
          >
            View
          </Link>
        </div>
      </div>
    </div>
  );
};
