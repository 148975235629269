/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import "./style.scss";

import {
  fromRoya,
  renderTokenAmountText,
  StoreageKey,
  Paths,
  Storage,
} from "src/utils";

import Modal from "../modal";
import LoginModalContainer from "../login-modal";
import wallet from "../../utils/wallet";
import {
  WeeweepadLogoGifImg,
  HamburgerIcon,
  WeeTokenIcon,
  ArrowDownWhiteIcon,
} from "../../images";
import Drawer from "./drawer/drawer";

import { User } from "../../core";
import {
  getBlogs,
  getEthBalance,
  getMusdtBalance,
  getRoyaBalance,
  getStakeDateforAllTiers,
  getUserTotalLots,
  login,
} from "../../logic/actions";
import { toast } from "react-toastify";
import WrongNetwork from "../login-modal/components/wrongNetwork";

// @ts-ignore
const web3 = new Web3(Web3.givenProvider);

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = Storage.accessToken;

  const { walletConnected, userAddress, networkInfo } = useSelector(
    (state: any) => state.user
  );
  const { userRoyaBalance } = useSelector((state: any) => state.royaReserve);

  const { library, deactivate } = useWeb3React();

  const [show, setShow] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setIsOpen] = useState({ project: false, ticket: false });
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseLeave = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.relatedTarget)) {
      setIsOpen({ ...isOpen, project: false, ticket: false });
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click occurred outside the dropdown, so close it
        setIsOpen({ ...isOpen, project: false });
      }
    };

    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeModal = () => {
    setShow(false);
  };

  const onMenuClick = () => {
    setOpenDrawer(!openDrawer);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleWalletConnect = async () => {
    if (walletConnected) {
      try {
        await wallet.disconnect();
        deactivate();
        localStorage.removeItem(StoreageKey.walletType);
        localStorage.removeItem("weeweepad-accessToken");
        localStorage.removeItem("weeweepad-refreshToken");
        toast.info("Wallet disconnected");
        setShowDisconnect(!showDisconnect);
      } catch (e) {
        console.log("something went wrong in logout wallet ", e);
      }
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchUserInfo();
    }
    dispatch(getBlogs());
  }, []);

  useEffect(() => {
    if (walletConnected) {
      /// need to replace
      dispatch(getStakeDateforAllTiers(userAddress));
      dispatch(getUserTotalLots(userAddress));
      dispatch(getMusdtBalance(userAddress));
      // dispatch(getMusdcBalance(userAddress));
      // dispatch(getMdaiBalance(userAddress));
      // dispatch(getBusdBalance(userAddress));
    }
  }, [userAddress, dispatch, walletConnected, userRoyaBalance]);

  useEffect(() => {
    if (walletConnected) {
      fetchUserInfo();
      dispatch(getRoyaBalance(userAddress));
      dispatch(getEthBalance(userAddress));
      if (networkInfo !== parseInt(process.env.REACT_APP_NETWORK_ID_HEX)) {
        setIsWrongNetwork(true);
      } else {
        setIsWrongNetwork(false);
      }
    }
  }, [walletConnected]);

  const fetchUserInfo = async () => {
    User.fetchUserInfo().then(async (result) => {
      if (result && result.data) {
        const currentWallet = localStorage.getItem(StoreageKey.walletType);
        const chainIdWalletConnect = await web3.eth.getChainId();

        
        await wallet.setProvider(+currentWallet);
        if (library && currentWallet) {
          await wallet.reconnect(+currentWallet, library);
        }
        dispatch(
          login(
            {
              userId: result.data.id,
              address: result.data.walletAddress,
              kycVerified: result.data.kycVerified,
            },
            web3.utils.toHex(chainIdWalletConnect.toString()),
            chainIdWalletConnect
          )
        );
      }
    });
  };

  const handleGotoSubmitGamePage = () => {
    if (walletConnected) {
      history.push(Paths.submitGame);
    } else {
      toast("Please connect wallet to submit meme", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  return (
    <>
      <div className="header-wrap">
        <div className="container">
          <div className="header-nav">
            <div className="header-navbar">
              <div className="header-logo">
                <Link to={Paths.landingZone} className="weeweepad-logo">
                  <img src={WeeweepadLogoGifImg.default} className="logo-custom-position" alt="" />
                </Link>
              </div>
              <div className="headermenu">
                <ul ref={dropdownRef} onMouseLeave={handleMouseLeave}>
                  <li>
                    <Link
                      to={"#"}
                      onClick={() =>
                        setIsOpen({ ticket: false, project: !isOpen?.project })
                      }
                    >
                      Defi{" "}
                      <img
                        src={ArrowDownWhiteIcon.default}
                        alt=""
                        className={isOpen?.project ? "flip-rotate" : ""}
                      />
                    </Link>
                    {isOpen.project && (
                      <div className="dropdown-list">
                        <div className="dropdown-list-item">
                          <Link
                            to={{
                              pathname: Paths.allProjects,
                              state: { selectedState: 1 },
                            }}
                            onClick={() =>
                              setIsOpen({ ...isOpen, project: false })
                            }
                            id="dropdown-list-item-link"
                          >
                            Active Projects
                          </Link>
                        </div>
                        <div className="dropdown-list-item">
                          <Link
                            to={{
                              pathname: Paths.allProjects,
                              state: { selectedState: 2 },
                            }}
                            onClick={() =>
                              setIsOpen({ ...isOpen, project: false })
                            }
                            id="dropdown-list-item-link"
                          >
                            Archived Projects
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>

                  <li>
                    <Link
                      onClick={() =>
                        setIsOpen({ project: false, ticket: !isOpen?.ticket })
                      }
                      style={{ color: "white" }}
                      to={"#"}
                    >
                      Staking Lots {""}
                      <img
                        src={ArrowDownWhiteIcon.default}
                        alt=""
                        className={isOpen?.ticket ? "flip-rotate" : ""}
                      />
                    </Link>
                    {isOpen.ticket && (
                      <div className="dropdown-list">
                        <div className="dropdown-list-item">
                          <Link
                            onClick={() =>
                              setIsOpen({ ...isOpen, ticket: false })
                            }
                            to={Paths.nfts}
                            id="dropdown-list-item-link"
                          >
                            Buy NFT
                          </Link>
                        </div>
                        <div className="dropdown-list-item">
                          <Link
                            onClick={() =>
                              setIsOpen({ ...isOpen, ticket: false })
                            }
                            to={Paths.stakingLots}
                            id="dropdown-list-item-link"
                          >
                            Buy Staking Lots
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>

                  {walletConnected && (
                    <li>
                      <Link style={{ color: "white" }} to={Paths.account}>
                        My Account
                      </Link>
                    </li>
                  )}
                  {!walletConnected && (
                    <li>
                      <Link to={Paths.dashboard} style={{ color: "white" }}>
                        Network Status
                      </Link>
                    </li>
                  )}
                  <li className="dropdown">
                    <a
                      style={{ color: "white" }}
                      href="https://weeweepad.gitbook.io/weeweepaper"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Docs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="submit-col">
              <div className="header-bottom-row">
                <div className="header-bottom-right1">
                  <ul>
                    {
                      walletConnected && (
                        <li>
                          <img className="wee-symbol" src={WeeTokenIcon.default} alt="" />
                          {
                            <>
                              <div className="notranslate">{`${
                                !!userRoyaBalance &&
                                renderTokenAmountText(fromRoya(userRoyaBalance))
                              }`}</div>
                              <img
                                src={ArrowDownWhiteIcon.default}
                                alt=""
                                className="arrowimg"
                              />
                            </>
                          }{" "}
                        </li>
                      )
                    }
                    <li>
                      {walletConnected && (
                        <>
                          <div
                            className="cursor"
                            onClick={() => setShowDisconnect(!showDisconnect)}
                          >
                            {userAddress[0]}
                            {userAddress[1]}
                            {userAddress[2]}
                            {userAddress[3]}
                            {userAddress[4]}
                            {userAddress[5]}
                            ...
                            {userAddress[userAddress.length - 4]}
                            {userAddress[userAddress.length - 3]}
                            {userAddress[userAddress.length - 2]}
                            {userAddress[userAddress.length - 1]}{" "}
                          </div>
                          <img
                            src={ArrowDownWhiteIcon.default}
                            alt=""
                            className={"arrowimg " + (showDisconnect ? "flip-rotate" : " ")}
                          />
                        </>
                      )}
                      {!walletConnected && (
                        <div className="cursor" onClick={handleWalletConnect}>
                          Connect Wallet
                        </div>
                      )}
                      {showDisconnect && (
                        <div className="disconnect-container">
                          <label onClick={() => handleWalletConnect()}>
                            Disconnect
                          </label>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <button onClick={handleGotoSubmitGamePage} className="btn-action-link">
                Submit Meme
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="header-wrap-mobile">
        <Link to={Paths.landingZone} className="mobile-logo">
          <img src={WeeweepadLogoGifImg.default} alt="WeeWee" />
        </Link>
        <div
          className="royale-amount"
          onClick={() => walletConnected && setShowDisconnect(!showDisconnect)}
        >
          {!walletConnected ? (
            <div className="full-width">
              <div
                className="cursor"
                style={{ color: "#ffffff" }}
                onClick={handleWalletConnect}
              >
                Connect Wallet
              </div>
            </div>
          ) : (
            <>
              <div className="notranslate">
                {userAddress[0]}
                {userAddress[1]}
                {userAddress[2]}
                {userAddress[3]}
                {userAddress[4]}
                {userAddress[5]}
                ...
                {userAddress[userAddress.length - 4]}
                {userAddress[userAddress.length - 3]}
                {userAddress[userAddress.length - 2]}
                {userAddress[userAddress.length - 1]}{" "}
              </div>
              {showDisconnect && (
                <div className="disconnect-container">
                  <label onClick={() => handleWalletConnect()}>
                    Disconnect Wallet
                  </label>
                </div>
              )}
            </>
          )}
        </div>
        <div
          role="button"
          className={`hamburger-menu  ${
            openDrawer ? "menu-icon-hide " : "menu-icon-show"
          }`}
          onClick={onMenuClick}
        >
          <img src={HamburgerIcon.default} alt="open" />
        </div>
      </div>
      <Modal show={show} closeModal={closeModal}>
        <LoginModalContainer show={show} closeModal={closeModal} />
      </Modal>
      <Modal show={isWrongNetwork} closeModal={() => setIsWrongNetwork(false)}>
        <WrongNetwork
          show={isWrongNetwork}
          closeModal={() => setIsWrongNetwork(false)}
        />
      </Modal>
      <Drawer openDrawer={openDrawer} onClose={onCloseDrawer} />
    </>
  );
};

export default Header;
