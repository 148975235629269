import store from '../logic/reducers';
import wallet from './wallet';
import { ContractAddress } from './address';
import { Networks } from './constants';
const usdcAbi = require('./abis/usdc.json');
const royaleLp = require('./abis/royalelp.json');
const usdtAbi = require('./abis/usdt.json');
const daiAbi = require('./abis/dai.json');
const rptokenAbi = require('./abis/rptoken.json');
const rptStakingAbi = require('./abis/rptStaking.json');
const royaTokenAbi = require('./abis/royaToken.json');
const royaReserveAbi = require('./abis/royaReserve.json');
const allStakingLotsAbi = require('./abis/allStakingLots.json');
const royaNftAbi = require('./abis/royaNft.json');
const bscTokenAbi = require('./abis/bscToken.json');
const mRoyaMainnetAbi = require('./abis/mRoyaMainnet.json');
const swapTokenAbi = require('./abis/swapToken.json');
const poolFactory = require('./abis/pool-factory.json');
const TierAbi = require('./abis/tier.json');
const TierNftAbi = require('./abis/tier-nft.json');
const poolToken = require('./abis/pooltoken.json');
const tokenAbi = require('./abis/user-token.json');
const poolAbi = require('./abis/pool.json');
const royaHolderProfile = require('./abis/roya-holder-profile.json');

export const getDaiContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        daiAbi['abi'],
        ContractAddress.sepolia.mDai
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        daiAbi['abi'],
        ContractAddress.mainnet.mDai
      );

    default:
      return new wallet.web3.eth.Contract(
        daiAbi['abi'],
        ContractAddress.mainnet.mDai
      );
  }
};
export const getUsdcContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        usdcAbi['abi'],
        ContractAddress.sepolia.mUsdc
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        usdcAbi['abi'],
        ContractAddress.mainnet.mUsdc
      );
    default:
      return new wallet.web3.eth.Contract(
        usdcAbi['abi'],
        ContractAddress.mainnet.mUsdc
      );
  }
};
export const getUsdtContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        usdtAbi['abi'],
        ContractAddress.sepolia.mUsdt
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        usdtAbi['abi'],
        ContractAddress.mainnet.mUsdt
      );
    default:
      return new wallet.web3.eth.Contract(
        usdtAbi['abi'],
        ContractAddress.mainnet.mUsdt
      );
  }
};
export const getRoyaleLpContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        royaleLp['abi'],
        ContractAddress.sepolia.royaleLP
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        royaleLp['abi'],
        ContractAddress.mainnet.royaleLP
      );
    default:
      return new wallet.web3.eth.Contract(
        royaleLp['abi'],
        ContractAddress.mainnet.royaleLP
      );
  }
};
export const getRptTokenContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        rptokenAbi['abi'],
        ContractAddress.sepolia.rpToken
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        rptokenAbi['abi'],
        ContractAddress.mainnet.rpToken
      );

    default:
      return new wallet.web3.eth.Contract(
        rptokenAbi['abi'],
        ContractAddress.mainnet.rpToken
      );
  }
};
export const getRptStakingContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        rptStakingAbi['abi'],
        ContractAddress.sepolia.rptStaking
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        rptStakingAbi['abi'],
        ContractAddress.mainnet.rptStaking
      );

    default:
      return new wallet.web3.eth.Contract(
        rptStakingAbi['abi'],
        ContractAddress.mainnet.rptStaking
      );
  }
};

export const getTokenContract = (address: string) => {
  return new wallet.web3.eth.Contract(bscTokenAbi['abi'], address);
};

export const getRoyaTokenContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        royaTokenAbi['abi'],
        ContractAddress.sepolia.royaToken
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        royaTokenAbi['abi'],
        ContractAddress.mainnet.royaToken
      );

    default:
      return new wallet.web3.eth.Contract(
        royaTokenAbi['abi'],
        ContractAddress.mainnet.royaToken
      );
  }
};
export const getRoyaReserveContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        royaReserveAbi['abi'],
        ContractAddress.sepolia.royaReserve
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        royaReserveAbi['abi'],
        ContractAddress.mainnet.royaReserve
      );

    default:
      return new wallet.web3.eth.Contract(
        royaReserveAbi['abi'],
        ContractAddress.mainnet.royaReserve
      );
  }
};
export const getKnightLotContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.knightLot
      );

    case Networks.sepolia:
        return new wallet.web3.eth.Contract(
          allStakingLotsAbi['abi'],
          ContractAddress.sepolia.knightLot
        );

    default:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.knightLot
      );
  }
};
export const getSettlerLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.settlerLot
      );

    case Networks.sepolia:
        return new wallet.web3.eth.Contract(
          allStakingLotsAbi['abi'],
          ContractAddress.sepolia.settlerLot
        );
  
    default:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.settlerLot
      );
  }
};
export const getMerchantLotContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.merchantLot
      );

    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.sepolia.merchantLot
      );
    default:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.merchantLot
      );
  }
};
export const getArchonContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.archonLot
      );

    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.sepolia.archonLot
      );
    default:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.archonLot
      );
  }
};
export const getMonarchLotContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.monarchLot
      );

    case Networks.sepolia:
        return new wallet.web3.eth.Contract(
          allStakingLotsAbi['abi'],
          ContractAddress.sepolia.monarchLot
        );
    default:
      return new wallet.web3.eth.Contract(
        allStakingLotsAbi['abi'],
        ContractAddress.mainnet.monarchLot
      );
  }
};
export const getRoyaNftContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        royaNftAbi['abi'],
        ContractAddress.mainnet.royaNFT
      );

    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        royaNftAbi['abi'],
        ContractAddress.sepolia.royaNFT
      );
    default:
      return new wallet.web3.eth.Contract(
        royaNftAbi['abi'],
        ContractAddress.mainnet.royaNFT
      );
  }
};
export const getMroyaTokenContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        mRoyaMainnetAbi['abi'],
        ContractAddress.mainnet.mRoya
      );

    default:
      return new wallet.web3.eth.Contract(
        mRoyaMainnetAbi['abi'],
        ContractAddress.mainnet.mRoya
      );
  }
};
export const getSwapTokenContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        swapTokenAbi['abi'],
        ContractAddress.mainnet.tokenSwap
      );

    default:
      return new wallet.web3.eth.Contract(
        swapTokenAbi['abi'],
        ContractAddress.mainnet.tokenSwap
      );
  }
};

export const getpoolFactoryContract = () => {
  const { network } = store.getState().user;

  switch (network) {
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        poolFactory['abi'],
        ContractAddress.sepolia.poolFactory
      );

    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        poolFactory['abi'],
        ContractAddress.mainnet.poolFactory
      );

    default:
      return new wallet.web3.eth.Contract(
        poolFactory['abi'],
        ContractAddress.mainnet.poolFactory
      );
  }
};

export const getPoolContract = (poolAddress: any) => {
  return new wallet.web3.eth.Contract(poolAbi['abi'], poolAddress);
};

export const getPoolDetailsContract = (address: any) => {
  return new wallet.web3.eth.Contract(poolAbi['abi'], address);
};

export const getPoolTokenDetailsContract = (address: string) => {
  return new wallet.web3.eth.Contract(poolToken['abi'], address);
};

export const getsettlerLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi['abi'],
    ContractAddress.sepolia.settlerLot
  );
};

export const getmerchantLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi['abi'],
    ContractAddress.sepolia.merchantLot
  );
};

export const getKnightLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi['abi'],
    ContractAddress.sepolia.knightLot
  );
};

export const getArchonLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi['abi'],
    ContractAddress.sepolia.archonLot
  );
};
export const getMonarchLotBalance = () => {
  return new wallet.web3.eth.Contract(
    TierAbi['abi'],
    ContractAddress.sepolia.monarchLot
  );
};

export const getSettlerNFTLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.settlerNFTLot
      );
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.sepolia.settlerNFTLot
      );
    default:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.settlerNFTLot
      );
  }
};

export const getMerchantNFTLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.merchantNFTLot
      );
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.sepolia.merchantNFTLot
      );
    default:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.merchantNFTLot
      );
  }
};

export const getKnightNFTLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.knightNFTLot
      );
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.sepolia.knightNFTLot
      );
    default:
      return new wallet.web3.eth.Contract(
        poolFactory['abi'],
        ContractAddress.mainnet.poolFactory
      );
  }
};

export const getArchonNFTLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.archonNFTLot
      );
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.sepolia.archonNFTLot
      );
    default:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.archonNFTLot
      );
  }
};
export const getMonarchNFTLotContract = () => {
  const { network } = store.getState().user;
  switch (network) {
    case Networks.mainnet:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.monarchNFTLot
      );
    case Networks.sepolia:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.sepolia.monarchNFTLot
      );
    default:
      return new wallet.web3.eth.Contract(
        TierNftAbi['abi'],
        ContractAddress.mainnet.monarchNFTLot
      );
  }
};

export const createPoolContract = (poolAddress: string): any =>
  new wallet.web3.eth.Contract(poolAbi.abi, poolAddress);

export const royaHolderProfileContract = () =>
  new wallet.web3.eth.Contract(
    royaHolderProfile.abi,
    ContractAddress.sepolia.royaHolderProfile
  );

export const createTokenContract = (tokenAddress: string): any =>
  new wallet.web3.eth.Contract(tokenAbi.abi, tokenAddress);

export const getDecimalContract = async (contractAddress: string) => {
  const token = new wallet.web3.eth.Contract(tokenAbi.abi, contractAddress);
  try {
    return await token.methods.decimals().call();
  } catch (err) {
    return err;
  }
};
