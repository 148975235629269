import gql from "graphql-tag";

export const USER = gql`
  query me {
    me {
      id
      walletAddress
      kycVerified
      nonce
    }
  }
`;
export const CHECKADMIN = gql`
  query IsAdmin($userAddress: String!) {
    isAdmin(data: { wallet: $userAddress })
  }
`;
