/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import BtnClose from "../btn-close/btn-close";

import "./drawer.scss";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Paths } from "src/utils";

export type DrawerProps = {
  openDrawer: boolean;
  onClose: () => void;
};

const Drawer: React.FC<DrawerProps> = ({ onClose, openDrawer }) => {
  const [isActive, setIsActive] = React.useState({
    project: false,
    ticket: false,
  });

  const { walletConnected } = useSelector(
    (state: any) => state.user
  );

  const links = [
    {
      title: "Defi",
      link: "",
      class: "link text-white",
      slug: "project",
      dropdown: [
        {
          href: Paths.allProjects,
          name: "Active Projects",
          state: 1,
        },
        {
          href: Paths.allProjects,
          name: "Archived Projects",
          state: 2,
        },
      ],
    },
    {
      title: "Staking Lots",
      link: "",
      slug: "ticket",
      class: "link text-white",
      dropdown: [
        {
          href: Paths.nfts,
          name: "Buy NFT",
        },
        {
          href: Paths.stakingLots,
          name: "Buy Staking Lots",
        },
      ],
    },
    {
      title: "My Account",
      class: "link text-white",
      link: "/account",
    },
    {
      title: "Docs",
      link: "https://weeweepad.gitbook.io/weeweepaper",
      class: "link text-white",
    },
  ];

  const history = useHistory();
  const GoToPage = (arr: any) => {
    setIsActive({ project: false, ticket: false });
    onClose();
    history.push(arr);
  };

  const handleGotoSubmitGamePage = () => {
    if (walletConnected) {
      onClose();
      history.push(Paths.submitGame);
    } else {
      toast("Please connect wallet to submit meme", {
        type: toast.TYPE.WARNING,
      });
    }
  };

  return (
    <div className={"draw-container" + (openDrawer ? " d-flex" : " d-none")}>
      <div className="draw-container-ele">
        <ul className={"nav"}>
          {links.map((link, lindex) => {
            return (
              <li
                key={"li_" + lindex}
                className={`nav-item ${
                  openDrawer
                    ? `item-show-animation-${lindex}`
                    : `item-close-animation-${lindex}`
                }`}
              >
                {link.link !== "" ? (
                  link.title === "Docs" ? 
                  <a
                    href={link.link}
                    target="_blank"
                    className={`nav-link ${link.class} ${
                      isActive[link.slug] && "active"
                    }`} rel="noreferrer"
                  >
                    {link.title}
                  </a>
                  :
                  <a
                    onClick={() => GoToPage(link.link)}
                    target="_self"
                    className={`nav-link ${link.class} ${
                      isActive[link.slug] && "active"
                    }`}
                  >
                    {link.title}
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      setIsActive(() => ({
                        ...isActive,
                        [link.slug]: !isActive[link.slug],
                      }))
                    }
                    className={`nav-link ${link.class}`}
                  >
                    {link.title}
                  </a>
                )}

                {isActive[link.slug] && (
                  <div className={"drop-down-container"}>
                    <ul>
                      {link.dropdown &&
                        link.dropdown.map((dropItem, index) => (
                          <li key={"dli_" + index}>
                            <Link
                              className={`nav-link ${isActive && "active"}`}
                              to={{
                                pathname: dropItem.href,
                                state: { selectedState: dropItem?.state },
                              }}
                              onClick={() => GoToPage(dropItem.href)}
                            >
                              {dropItem.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        <div className="header-footer">
          <button onClick={handleGotoSubmitGamePage} className="btn-action-link">
            Submit Meme
          </button>
        </div>
      </div>

      <BtnClose onClose={onClose} showClose={openDrawer} />
    </div>
  );
};

export default Drawer;
