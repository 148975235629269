import styled from 'styled-components';
import { Color } from '../../utils';

export const Wrapper = styled.div`
  width: 70%;
  background: ${Color.bg1};
  padding: 20px;
  margin-top: 20px;
`;

export const Icon = styled.div`
  fill: ${Color.cyanPrimary};
`;

export const P = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 20px;
`;

export const H2 = styled.h2`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 30px;
  color: ${Color.white};
`;

export const H4 = styled.h4<{ completed?: boolean }>`
  margin: 0 10px;
  font-size: ${(props) => (props.completed ? '18px' : '16px')};
  font-weight: 600;
  color: #ffffff;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  background: ${Color.cyanLight};
  padding: 10px 40px 10px 20px;
  border-radius: 10px;
  border: 1px solid ${Color.cyanPrimary};
`;

export const CheckMarkContainer = styled.span<{ completed?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.completed ? Color.cyanSecondary : Color.lightBluePrimary)};
  color: ${(props) => (props.completed ? null : '#010C2A')};
`;

export const BtnContainer = styled.div`
  margin-top: 20px;
`;

export const InputField = styled.input`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a2b8db;
  padding: 10px 15px 12px 15px;
  width: 350px;
  background: #0d193c;
  border: 1px solid #18355b;
  border-radius: 2px;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #FFF);
  box-shadow: 4px 4px 0px 0px #111;
`;

export const Tr = styled.tr<{ header?: boolean }>`
  border-bottom: ${(props) => (props.header ? `1px solid ${Color.cyanLight}` : null)};
`;

export const Th = styled.th`
  /* width: 180px; */
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-right: 40px;
  color: #FFA12B;
  padding-top: 35px;
  padding-bottom: 29px;

  &:first-child {
    white-space: nowrap;
    padding-right: 0;
    padding-left: 40px;
  }

  @media screen and (max-width: 1200px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 991px) {
    &:nth-child(2) {
      display: none
    }
    &:nth-child(4) {
      display: none
    }
    &:nth-child(5) {
      display: none
    }
  }

  @media screen and (max-width: 524px) {
    &:nth-child(3) {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 376px) {
    &:nth-child(3) {
      max-width: 127px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Td = styled(Th) <{ variant?: string }>`
  width: ${(props) => (props.variant === 'status' ? '180px' : null)};
  color: var(--Black-Black-40, rgba(0, 0, 0, 0.40));
`;