/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Game } from "../../core";
import { GameDetailCard } from "src/components";
import { setLoading } from '../../logic/actions';

import "./style.scss";
interface LocationState {
  selectedState?: number;
}
const AllProjects = () => {
  const dispatch = useDispatch();
 
  const [allGames, setAllGames] = useState<Array<Game>>([]);
  const { state }: { state?: LocationState } = useLocation(); 
  const [selectedState] = useState<number>(state?.selectedState || 0); 
  const [showMore, setShowMore] = useState(false);

  const { userAddress, walletConnected } = useSelector(
    (state: any) => state.user
  );

  const getAllGames = async () => {
    dispatch(setLoading(true));
    const { error, results } = await Game.findGames();
    dispatch(setLoading(false));
    if (error) {
      return;
    }
    setAllGames(results);
  };

  useEffect(() => {
    if (walletConnected) {
      getAllGames();
    }
  }, [walletConnected, userAddress]);
  const getData = () => {
    return (
      allGames &&
      allGames?.filter((item: any) =>
        selectedState === 1
          ? item.isActive
          : selectedState === 2
          ? !item.isActive
          : true
      )
    );
  };

  return (
    <div className="all-projects-wrap">
      <div className="heading-row">
        <div className="headingbox1">
          <h2>All Projects</h2>
        </div>
      </div>
      <div className={"container"}>
        <div className="upcoming-projects-row">
          {getData().length > 0 ? (
            <div className="project-list">
              {getData()
                .slice(0, 5)
                .map((item: any, index: number) => (
                  <GameDetailCard value={item} key={index} />
                ))}
              {showMore &&
                getData()
                  .slice(5)
                  .map((item: any, index: number) => (
                    <GameDetailCard value={item} key={index} />
                  ))}
            </div>
          ) : (
            <span className="game-name">
              There is no{" "}
              {selectedState === 1
                ? "active"
                : selectedState === 2 && "archived"}{" "}
              projects.
            </span>
          )}
          {getData()?.length > 5 && (
            <button
              className="btn-action-link"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "View Less" : "View More"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
