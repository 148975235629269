import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { LogoRemoveIcon, UploadYellowIcon } from "../../images";
import {  endpoint_api } from "../../lib/network/apiClient";
import { Storage } from "src/utils";

interface Props {
  type?: "image" | "video";
  className?: string;
  imgUrl: string;
  label: string;
  options: Array<string>;
  text?: string;
  setMediaURL: (mediaURL: string) => void;
  mediaURL: string;
}

const totalProgress = {};

export const UploadLogoContainer: React.FC<Props> = ({
  className,
  label,
  options,
  text,
  setMediaURL,
  mediaURL,
}) => {
  const inputRef = useRef(null);

  const [dragActive, setDragActive] = useState(false);
  const [progress, setProgress] = useState(1);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const getTotalProgress = (prog: any, totalCount: any) => {
    let sum = 0;
    Object.keys(prog).forEach((one) => {
      sum += prog[one];
    });
    setProgress(Math.round(sum / totalCount));
  };

  const handleFile = async (files: any) => {
    const fileName = files[0].name;
    const fileType = files[0].type;
    const fileSize = files[0].size;

    const requestHeaders = Storage.accessToken
      ? {
          authorization: `Bearer ${Storage.accessToken}`,
        }
      : null;
    try {
      const response = await axios.get(
        `${endpoint_api}/start-upload`,
        {
          params: {
            fileName,
            fileType,
          },
          // query: `{fileName,fileType,fileSize}`,

          headers: requestHeaders,
        }
      );
      const { uploadId } = response.data;

      let FILE_CHUNK_SIZE;
      // if file size is large than 2GB
      if (fileSize >= 2000000000) {
        FILE_CHUNK_SIZE = 20000000; // 20MB
      } else {
        // if file size is less than 2GB
        FILE_CHUNK_SIZE = 10000000; // 10MB
      }

      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
      const promisesArray = [];
      let start;
      let end;
      let blob;

      /* eslint-disable no-await-in-loop */
      for (let index = 1; index < NUM_CHUNKS + 1; index += 1) {
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = index * FILE_CHUNK_SIZE;
        blob =
          index < NUM_CHUNKS
            ? files[0].slice(start, end)
            : files[0].slice(start);

        // (1) Generate presigned URL for each part
        const getUploadUrlRes = await axios.get(
          `${endpoint_api}/presigned-url`,
          {
            params: {
              fileName,
              partNumber: index,
              uploadId,
            },
            headers: requestHeaders,
          }
        );

        const { presignedUrl } = getUploadUrlRes.data;

        // Puts each file part into the storage server
        const indexSub = index;
        const uploadRes = axios.put(presignedUrl, blob, {
          headers: { "Content-Type": fileType },
          onUploadProgress: (data) => {
            const percent = Math.round((data.loaded * 100) / data.total);
            // @ts-ignore
            totalProgress[indexSub] = percent;
            getTotalProgress(totalProgress, NUM_CHUNKS);
          },
        });

        promisesArray.push(uploadRes);
      }
      /* eslint-enable no-await-in-loop */

      const resolvedArray = await Promise.all(promisesArray);

      // @ts-ignore
      const uploadPartsArray = [];
      resolvedArray.forEach((resolvedPromise, index) => {
        uploadPartsArray.push({
          ETag: resolvedPromise.headers.etag,
          PartNumber: index + 1,
        });
      });

      // Calls the CompleteMultipartUpload endpoint in the backend server

      const completeUploadRes = await axios.post(
        `${endpoint_api}/complete-upload`,
        {
          params: {
            fileName,
            // @ts-ignore
            parts: uploadPartsArray,
            uploadId,
          },
        },
        {
          headers: requestHeaders,
        }
      );

      if (completeUploadRes.data) {
        setMediaURL(decodeURIComponent(completeUploadRes.data.data.Location));
        toast("Uploaded file successfully.", {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      alert("Please try again");
    }
  };

  return (
    <>
      <div
        className={`upload-logo-container ${className ?? ""}`}
        id="form-file-upload"
        onDragEnter={handleDrag}
      >
        <div className="logo-container">
          {mediaURL ? (
            <>
              <div className="logo-container-media">
                <img src={mediaURL} alt="avatar" className="uploaded-img" />
                <img
                  src={LogoRemoveIcon.default}
                  alt="modal close icon"
                  height={24}
                  width={24}
                  onClick={() => {
                    setMediaURL("");
                    setProgress(1);
                  }}
                />
              </div>
            </>
          ) : (
            <label
              htmlFor="image-logo-upload"
              className={`logo-container-wrap ${dragActive ? "active" : ""}`}
            >
              {progress === 1 && (
                <img src={UploadYellowIcon.default} alt="upload logo" />
              )}
              <input
                ref={inputRef}
                type="file"
                id="image-logo-upload"
                multiple
                onChange={handleChange}
                accept="image/png, image/jpg, image/jpeg"
              />
              {progress > 1 && (
                <div className="container-wrap-progress">
                  <p>{progress} %</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              )}
            </label>
          )}
        </div>
        <div className="right-container">
          <p className="upload-container-wrap-label">{label}</p>
          <div className="upload-container-wrap-option">
            {options &&
              options.map((item, index) => (
                <div
                  key={index}
                  className="upload-container-wrap-option-element"
                >
                  <div className="upload-container-wrap-option-rectangle"></div>
                  <p>{item}</p>
                </div>
              ))}
          </div>
          <p className="upload-container-wrap-text">{text}</p>
        </div>
        {dragActive && (
          <div
            id="drag-file-element"
            className="upload-container-drag absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
    </>
  );
};
