/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, useFormikContext } from "formik";
import { ErrorContainer } from "../../style";
import React, { useEffect, useState } from "react";

interface Props {
  setIsChanged: (val: boolean) => void;
  values?: any;
}

const SetDates: React.FC<Props> = ({ values, setIsChanged }) => {
  const formikProps = useFormikContext();

  const currentDate = new Date();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if(values.startDate) {
      setStartDate(new Date(values.startDate));
    } else {
      const tmpStartDate = new Date();
      tmpStartDate.setHours(tmpStartDate.getHours() + 2);
      tmpStartDate.setMinutes(0);
      tmpStartDate.setSeconds(0);

      // setStartDate(tmpStartDate);
      formikProps.setFieldValue("startDate", tmpStartDate, true);
    }

    if(values.endDate) {
      setEndDate(new Date(values.endDate));
    } else {
      const tmpEndDate = new Date();
      tmpEndDate.setHours(tmpEndDate.getHours() + 3);
      tmpEndDate.setMinutes(0);
      tmpEndDate.setSeconds(0);

      // setEndDate(tmpEndDate);
      formikProps.setFieldValue("endDate", tmpEndDate, true);
    }

    if(!values.startDate || !values.endDate) {
      setIsChanged(true);
    }
  }, [values]);

  const handleChangeDate = (isStartDate: boolean, y: string, m: string, d: string, h: string, mi: string, s: string) => {
    let newDate: Date;

    isStartDate ? (newDate = new Date(startDate)) : (newDate = new Date(endDate));

    y && newDate.setFullYear(+y);
    m && newDate.setMonth(+m);
    d && newDate.setDate(+d);
    h && newDate.setHours(+h);
    mi && newDate.setMinutes(+mi);
    s && newDate.setSeconds(+s);

    if(isStartDate) {
      // setStartDate(newDate);
      formikProps.setFieldValue("startDate", newDate, true);
    } else {
      // setEndDate(newDate);
      formikProps.setFieldValue("endDate", newDate, true);
    }

    setIsChanged(true);
  }

  const getTotalDates = (year: number, month: number): number => {
    const startDate = new Date(year, month, 1).getDate();
    const endDate = new Date(year, month + 1, 0).getDate();

    return endDate - startDate + 1;
  }

  return (
    <div className="pool-step-01">
      <div className="pool-name-box">
        <div className="set-date-info-box">
          <div className="info-icon-box"></div>
          <span className="set-date-text">
            Start Date must be 60 mins from now, and end date must be 60 mins
            from start date. Check with your timezone.
          </span>
        </div>
        <h5>UTC Start Date</h5>

        <ErrorContainer>
          <ErrorMessage name="startDate" />
        </ErrorContainer>

        <div className="set-dates-grid">
          <div className="step-form-control ">
            <select
              className="submit-form-control"
              value={startDate.getFullYear()}
              onChange={(e) => handleChangeDate(true, e.target.value, '', '', '', '', '')}
            >
              {
                Array.from({ length: 5 }, (_, index) => index).map((item, index) => {
                  const year = currentDate.getFullYear() + index;
                  return (
                    <option value={year} key={`startyear_${year}`}>{year}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={startDate.getMonth()}
              onChange={(e) => handleChangeDate(true, '', e.target.value, '', '', '', '')}
            >
              {
                Array.from({ length: 12 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startmonth_${index}`}>{index + 1}</option>
                ))
              }
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={startDate.getDate()}
              onChange={(e) => handleChangeDate(true, '', '', e.target.value, '', '', '')}
            >
              {
                Array.from({ length: getTotalDates(startDate.getFullYear(), startDate.getMonth()) }, (_, index) => index).map((item, index) => (
                  <option value={index + 1} key={`startdate_${index}`}>{index + 1}</option>
                ))
              }
            </select>
          </div>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={startDate.getHours()}
              onChange={(e) => handleChangeDate(true, '', '', '', e.target.value, '', '')}
            >
              {
                Array.from({ length: 24 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`starthour_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={startDate.getMinutes()}
              onChange={(e) => handleChangeDate(true, '', '', '', '', e.target.value, '')}
            >
              {
                Array.from({ length: 60 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startminute_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={startDate.getSeconds()}
              onChange={(e) => handleChangeDate(true, '', '', '', '', '', e.target.value)}
            >
              {
                Array.from({ length: 60 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startsecond_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
        </div>
        <h5>UTC End Date</h5>

        <ErrorContainer>
          <ErrorMessage name="endDate" />
        </ErrorContainer>

        <div className="set-dates-grid">
          <div className="step-form-control ">
            <select
              className="submit-form-control"
              value={endDate.getFullYear()}
              onChange={(e) => handleChangeDate(false, e.target.value, '', '', '', '', '')}
            >
              {
                Array.from({ length: 5 }, (_, index) => index).map((item, index) => {
                  const year = currentDate.getFullYear() + index;
                  return (
                    <option value={year} key={`startyear_${year}`}>{year}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={endDate.getMonth()}
              onChange={(e) => handleChangeDate(false, '', e.target.value, '', '', '', '')}
            >
              {
                Array.from({ length: 12 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startmonth_${index}`}>{index + 1}</option>
                ))
              }
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={endDate.getDate()}
              onChange={(e) => handleChangeDate(false, '', '', e.target.value, '', '', '')}
            >
              {
                Array.from({ length: getTotalDates(endDate.getFullYear(), endDate.getMonth()) }, (_, index) => index).map((item, index) => (
                  <option value={index + 1} key={`endDate_${index}`}>{index + 1}</option>
                ))
              }
            </select>
          </div>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={endDate.getHours()}
              onChange={(e) => handleChangeDate(false, '', '', '', e.target.value, '', '')}
            >
              {
                Array.from({ length: 24 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`starthour_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={endDate.getMinutes()}
              onChange={(e) => handleChangeDate(false, '', '', '', '', e.target.value, '')}
            >
              {
                Array.from({ length: 60 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startminute_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={endDate.getSeconds()}
              onChange={(e) => handleChangeDate(false, '', '', '', '', '', e.target.value)}
            >
              {
                Array.from({ length: 60 }, (_, index) => index).map((item, index) => (
                  <option value={index} key={`startsecond_${index}`}>{index}</option>
                ))
              }
            </select>
          </div>
        </div>

        {/* <div>
          <div className="time-lock">
            <label htmlFor="myCheck" className="">
              <input
                type="checkbox"
                id="myCheck"
                checked={timeLock}
                onChange={timeLockHandle}
              />
              <span className="input"></span>
              Enable Time Lock
            </label>
            <div className="info-text">
              <span className="icon"></span>
              (Optional)
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SetDates;
