import styled from "styled-components";

export const DetailsContainer = styled.div`
  padding: 5%;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 3%;
  padding-bottom: 20px;
  border-bottom: 2px solid #000;
  
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .divider {
    border: 1px solid #E1E1E1;
    transform: rotate(90deg);
    width: 46px;
    height: 0;
  }
`;

export const TimeLeft = styled.div`
  width: 100%;
  color: #000000;
  padding: 6px;
  background: var(--Black-Black-10, rgba(0, 0, 0, 0.10));
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 10px;
`;

export const ProgressBar = styled.div<{ progress: number;}>`
  background: #9AA1AC;
  border-radius: 97px;
  height: 11px;
  
  .filled {
    display: block;
    height: inherit;
    background: #FFA12B;
    width: ${(props) => (props.progress ? `${props.progress}%` : 0)};
  }
`;
