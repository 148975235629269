import React from "react";

import { Paths } from "../../utils";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
}

export const TireStakeBox: React.FC<Props> = ({ className = "" }) => {
  const [classValue, setClassValue] = React.useState(true);

  return (
    <div className="dashboard-tier-stake-box">
      <div className="staking-nav">
        <button
          className={`${classValue ? "active" : ""}`}
          onClick={() => setClassValue(true)}
        >
          Stake
        </button>
        <button
          className={`${classValue ? "" : "active"}`}
          onClick={() => setClassValue(false)}
        >
          Unstake
        </button>
      </div>
      <h4 className="success-info-color">Stake Ticker TBD to participate in safelist for upcoming IDOS.</h4>
      <h4 className="success-info-color">
        You don't own any Staking Lot right now. In order to start staking you
        need to stake in one of our Tiers.{" "}
      </h4>
      <div className="dashboard-stake-action">
        <Link className="btn-action-link" to={Paths.stakingLots}>
          BUY TIERS
        </Link>
      </div>
    </div>
  );
};
