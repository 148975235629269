import styled from 'styled-components';
import { Color } from '../../utils';
import { ReactComponent as CheckMark } from '../../images/icons/check-black.svg';

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  color: ${Color.white};
  background: transparent;
  border: 1px solid ${Color.cyanLight};
  &:disabled {
    opacity: 0.7;
    border: 1px solid ${Color.lightBluePrimary};
  }
`;

export const FileInput = styled.input`
  visibility: hidden;
  opacity: 0;
  width: 0;
`;

/**
 * Checkbox
 */

export const CheckboxLabel = styled.label<{ checked?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid ${Color.cyanLight};
  border-radius: 5px;
  color: ${(props) => (props.checked ? Color.cyanPrimary : 'transparent')};
`;

export const CheckboxInput = styled.input`
  visibility: hidden;
  opacity: 0;
  width: 0;
  outline: none;
`;

interface CheckboxProps {
  checked?: boolean;
  className?: string;
  onChange?: (e: any) => void;
}

export const CheckBox = ({ checked, onChange, className }: CheckboxProps): JSX.Element => (
  <CheckboxLabel checked={checked} className={className}>
    <CheckMark fill="currentColor" />
    <CheckboxInput type="checkbox" onChange={onChange} />
  </CheckboxLabel>
);

/**
 * Switch
 */

const SwitchInput = styled.input`
  width: inherit;
  visibility: hidden;
  opacity: 0;
`;

const SwicthLabel = styled.label<{ checked?: boolean }>`
  cursor: pointer;
  width: 48px;
  height: 28px;
  display: inline-block;
  border-radius: 30px;
  background: ${(props) => (props.checked ? Color.cyanSecondaryLight : Color.cyanLight)};
  position: relative;
  &::before {
    position: absolute;
    display: inline-block;
    content: '';
    width: 24px;
    height: 24px;
    transition: left 0.1s, transform 0.1s;
    left: ${(props) => (props.checked ? '21px' : '3px')};
    top: 2px;
    border-radius: 50%;
    background: ${(props) => (props.checked ? Color.cyanSecondary : Color.cyanPrimary)};
  }
`;

interface SwitchProps {
  name?: string;
  checked?: boolean;
  className?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
}

export const Switch = ({
  checked,
  name,
  onChange,
  onClick,
  className,
}: SwitchProps): JSX.Element => (
  <SwicthLabel checked={checked} className={className}>
    <SwitchInput
      name={name}
      type="checkbox"
      defaultChecked={checked}
      onChange={onChange}
      onClick={onClick}
    />
  </SwicthLabel>
);
