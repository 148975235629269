import clsxm from 'src/lib/clsxm';
import { CreatePoolSteps } from '../../../../utils';

interface Props {
  step: number;
}

const Steps = (props: Props) => {
  const { step } = props;

  return (
    <>
      <div className="pool-step-wrap">
        <div
          className={clsxm(
            'pool-step-col',
            step === CreatePoolSteps.setPool
              ? 'active'
              : CreatePoolSteps.setPool < step
              ? 'passed'
              : ''
          )}
        >
          <div className="pool-step-round">1</div>
          <span className="pool-step-name">Set Pool</span>
        </div>
        <div
          className={clsxm(
            'pool-step-col',
            step === CreatePoolSteps.tokenDetails
              ? 'active'
              : CreatePoolSteps.tokenDetails < step
              ? 'passed'
              : ''
          )}
        >
          <div className="pool-step-round">2</div>
          <span className="pool-step-name">Token Details</span>
        </div>
        <div
          className={clsxm(
            'pool-step-col',
            step === CreatePoolSteps.swapDetails
              ? 'active'
              : CreatePoolSteps.swapDetails < step
              ? 'passed'
              : ''
          )}
        >
          <div className="pool-step-round">3</div>
          <span className="pool-step-name">Swap Details</span>
        </div>
        <div
          className={clsxm(
            'pool-step-col',
            step === CreatePoolSteps.setDates
              ? 'active'
              : CreatePoolSteps.setDates < step
              ? 'passed'
              : ''
          )}
        >
          <div className="pool-step-round">4</div>
          <span className="pool-step-name">Set Dates</span>
        </div>
        <div
          className={clsxm(
            'pool-step-col',
            step === CreatePoolSteps.poolAllocation
              ? 'active'
              : CreatePoolSteps.poolAllocation < step
              ? 'passed'
              : ''
          )}
        >
          <div className="pool-step-round">5</div>
          <span className="pool-step-name">Pool Allocation</span>
        </div>
      </div>
    </>
  );
};

export default Steps;
