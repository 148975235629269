import React from 'react';
import { useSelector } from 'react-redux';

import './styles.scss';
import { RoyaStaking } from '../../../utils';
import { Sidebar } from '../sidebar';

export const Profile: React.FC = () => {
  const {
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    // totalSNFTLot,
    // totalMNFTLot,
    // totalKNFTLot,
    // totalANFTLot,
    // totalMoNFTLot
  } = useSelector((state: any) => state.stakingLots);

  return (
    <>
      <div className="my-game-wrapper profile">
        {/* <MobileSidebar /> */}
        <Sidebar />
        <div className="content">
          <div className="title-action">Profile</div>
          <div className="admin-table-box">
            <div className="profile-table">
              <div className="profile-table-left">
                <div className="table-header-row">TIER</div>
                {[
                  RoyaStaking.settler,
                  RoyaStaking.merchant,
                  RoyaStaking.knight,
                  RoyaStaking.archon,
                  RoyaStaking.monarch
                ].map((x, index) => (
                  <div key={index} className="table-row">
                    <img src={x.icon} alt={x.title} />
                    <span>{x.title}</span>
                  </div>
                ))}
              </div>
              <div className="profile-table-right">
                <div className="table-header">
                  <div>Staked</div>
                </div>
                <div className="table-row">
                  <div>{totalSroyaLot}</div>
                </div>
                <div className="table-row">
                  <div>{totalMroyaLot}</div>
                </div>
                <div className="table-row">
                  <div>{totalKroyaLot}</div>
                </div>
                <div className="table-row">
                  <div>{totalAroyaLot}</div>
                </div>
                <div className="table-row">
                  <div>{totalMoroyaLot}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
