import React, {
  useState,
  useRef,
  DetailedHTMLProps,
  VideoHTMLAttributes,
} from "react";
import { toast } from "react-toastify";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { endpoint_api } from "../../lib/network/apiClient";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
import closebtn from "../../images/icon-close.svg";
import { Storage } from "src/utils";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
interface Props {
  title?: string;
  validFormat?: Array<string>;
  thumbnail?: Array<string>;
  setThumbnail?: React.Dispatch<React.SetStateAction<string[]>>;
  showClip?: boolean;
  setShowClip?: React.Dispatch<React.SetStateAction<boolean>>;
  fileType?: string;
  fileUrl?: string;
  onUpload?: (fileUrl: string) => void;
  description?: string;
}

const totalProgress = {};

export default function Croper({
  title,
  validFormat,
  thumbnail,
  setThumbnail,
  showClip,
  setShowClip,
  description,
  fileType,
}: Props) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const [scale, setScale] = useState(1);
  const [aspect, setAspect] = useState(1 / 1);
  const [isUploading, setUploading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setName(e.target.files[0].name);
      setType(e.target.files[0].type);
    }
    if (setShowClip) {
      setShowClip(true);
    }
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const [progress, setProgress] = useState(0);

  const getTotalProgress = (prog: any, totalCount: any) => {
    let sum = 0;
    Object.keys(prog).forEach((one) => {
      sum += prog[one];
    });
    setProgress(Math.round(sum / totalCount));
  };

  async function onDownloadCropClick() {
    const image = imgRef.current;

    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      // throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    // @ts-ignore
    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    // @ts-ignore
    const blob = await offscreen.convertToBlob({
      type: type,
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    // hiddenAnchorRef.current.href = blobUrlRef.current
    // hiddenAnchorRef.current.click()
    let file = new File([blob], name, {
      type: blob.type,
    });
    const formData = new FormData();
    formData.append("file", file);

    const fileName = file.name;
    const fileType = file.type;
    const fileSize = file.size;

    const requestHeaders = Storage.accessToken
      ? {
          authorization: `Bearer ${Storage.accessToken}`,
        }
      : null;
    try {
      const response = await axios.get(
        `${endpoint_api}/start-upload`,
        {
          params: {
            fileName,
            fileType,
          },
          headers: requestHeaders,
        }
      );

      const { uploadId } = response.data;

      if (uploadId) {
        setUploading(true);
      }

      let FILE_CHUNK_SIZE;
      // if file size is large than 2GB
      if (fileSize >= 2000000000) {
        FILE_CHUNK_SIZE = 20000000; // 20MB
      } else {
        // if file size is less than 2GB
        FILE_CHUNK_SIZE = 10000000; // 10MB
      }

      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
      const promisesArray = [];
      let start;
      let end;
      let blob;

      /* eslint-disable no-await-in-loop */
      for (let index = 1; index < NUM_CHUNKS + 1; index += 1) {
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = index * FILE_CHUNK_SIZE;
        blob = index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

        // (1) Generate presigned URL for each part
        const getUploadUrlRes = await axios.get(
          `${endpoint_api}/presigned-url`,
          {
            params: {
              fileName,
              partNumber: index,
              uploadId,
            },
            headers: requestHeaders,
          }
        );

        const { presignedUrl } = getUploadUrlRes.data;

        // Puts each file part into the storage server
        const indexSub = index;
        const uploadRes = axios.put(presignedUrl, blob, {
          headers: { "Content-Type": fileType },
          onUploadProgress: (data) => {
            const percent = Math.round((data.loaded * 100) / data.total);
            // @ts-ignore
            totalProgress[indexSub] = percent;
            getTotalProgress(totalProgress, NUM_CHUNKS);
          },
        });

        promisesArray.push(uploadRes);
      }
      /* eslint-enable no-await-in-loop */

      const resolvedArray = await Promise.all(promisesArray);

      // @ts-ignore
      const uploadPartsArray = [];
      resolvedArray.forEach((resolvedPromise, index) => {
        uploadPartsArray.push({
          ETag: resolvedPromise.headers.etag,
          PartNumber: index + 1,
        });
      });

      // Calls the CompleteMultipartUpload endpoint in the backend server

      const completeUploadRes = await axios.post(
        `${endpoint_api}/complete-upload`,
        {
          params: {
            fileName,
            // @ts-ignore
            parts: uploadPartsArray,
            uploadId,
          },
        },
        {
          headers: requestHeaders,
        }
      );

      if (completeUploadRes.data) {
        const resArr = [...thumbnail, completeUploadRes.data.data.Location];
        setThumbnail(resArr);
        setUploading(false);
        toast("Uploaded file successfully.", {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (err) {
      console.log(err);
      setUploading(false);
      alert("Please try again ");
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0
        );
      }
    },
    100,
    [completedCrop, scale]
  );

  function handleToggleAspectClick() {
    setShowClip(false);
    setAspect(1 / 1);
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const newCrop = centerAspectCrop(width, height, 1 / 1);
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
    onDownloadCropClick();
  }

  const deleteFile = (item) => {
    let result = thumbnail.filter((num) => num !== item);
    setThumbnail(result);
  };

  const videoRef =
    React.useRef<
      DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
    >();

  return (
    <div className="App">
      <canvas
        ref={previewCanvasRef}
        style={{
          display: "none",
          border: "1px solid black",
          objectFit: "contain",
          width: 300,
          height: 200,
        }}
      />
      <div
        style={{ width: "100%" }}
        className=" relative flex flex-col items-center "
      >
        <div
          style={{ display: showClip ? "none" : "flex", cursor: "pointer" }}
          className="box-img flex-1"
        >
          <div className="flex-1">
            <span className="inputBtn">
              {!isUploading && (
                <div>
                  <p className="upload-container-wrap-label">{title}</p>
                  <div className="upload-container-wrap-option">
                    {validFormat.map((item, index) => (
                      <div
                        key={index}
                        className="upload-container-wrap-option-element flex justify-center"
                      >
                        <div className="upload-container-wrap-option-rectangle"></div>
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                  <p className="upload-container-wrap-text">{description}</p>
                </div>
              )}
              {isUploading && (
                <div className="progress-bar-container">
                  <div className="progress-bar-value">{progress} %</div>
                  <div className="progress-bar">
                    <div style={{ width: `${progress}%` }} />
                  </div>
                </div>
              )}
              <input
                className="inputFile"
                onChange={onSelectFile}
                type="file"
                id="myImg"
                name="myImg"
                title="Please select image to upload"
                accept={`${fileType === "video/mp4" ? "video/*" : "image/*"}`}
              />
            </span>
            <span id="fileName"></span>
          </div>
        </div>

        <div style={{ display: showClip ? "block" : "none" }}>
          <div className="crop-container">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              // minWidth={400}
              minHeight={200}
              keepSelection={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${0}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>

            <div className="controls">
              <input
                type="range"
                value={scale}
                min={0.5}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setScale(parseFloat(e.target.value));
                }}
                className="zoom-range"
              />
            </div>

            <div>
              <button className="clipping" onClick={handleToggleAspectClick}>
                Clipping
              </button>
            </div>
          </div>
        </div>
      </div>
      {thumbnail.length > 0 ? (
        <div className=" img-list ">
          {thumbnail.map((item: any, i: any) => (
            <div className="relative" key={i}>
              {fileType !== "video/mp4" ? (
                <img className="img-item" src={item} alt="" />
              ) : (
                <video
                  // @ts-ignore for playing video
                  ref={videoRef}
                  className="uploaded-img"
                  preload="metadata"
                >
                  <source src={item} />
                </video>
              )}
              <img
                onClick={() => deleteFile(item)}
                src={closebtn}
                className="closebutton cursor-pointer"
                alt=""
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
