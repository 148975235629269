/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  AddressCont,
  DateAndRateText,
  HeaderPrimary,
  RegisterText,
} from "../pool-status/style";
import { CopyIcon } from "../../../../images";
import {
  ButtonContainer,
  DetailsContainer,
  InfoContainer,
  ProgressBar,
  TimeLeft,
} from "./style";

import { GameModel } from "src/logic/actions";
import { GameRegisterInfo } from "src/core";
import { TokenType, getEtherScanLink, getPoolContract } from "src/utils";
import "./style.scss"

interface Props {
  gameDetail?: GameModel;
  pool: any;
  setShow: (val: any) => void;
  show: boolean;
  isSuccess: boolean;
  startedStatus: any;
}

const PoolDetPoolDetailsNew: React.FC<Props> = ({
  pool,
  setShow,
  startedStatus,
  isSuccess,
  gameDetail,
}) => {
  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );
  const { id } = useParams<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [partingUsers, setPartingUsers] = useState(0);
  const regStartDate = new Date(pool?.startTime).getTime();
  const regEndDate = new Date(pool?.endTime).getTime();
  const regStarted: boolean = new Date(regStartDate).getTime() < Date.now();
  const regEnded: boolean = new Date(regEndDate).getTime() < Date.now();
  const remainingTime: boolean =
    new Date(regEndDate).getTime() - 1000 * 60 * 60 * 24 * 1 < Date.now();
  const regStartTime = moment(new Date(regStartDate)).fromNow();
  const regEndTime = moment(new Date(regEndDate)).fromNow();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => toast("Copied", { type: toast.TYPE.INFO }), 300);
    }
  }, [showAlert]);

  const getProgress = () => {
    const startDate = new Date(pool?.startTime as string);
    const endDate = new Date(pool?.endTime as string);
    const total = +endDate - +startDate;
    const elaps = Date.now() - +startDate;
    if (Date.now() - +endDate < 0) {
      return Math.round((elaps / total) * 100);
    } else {
      return 0;
    }
  };

  const handleRedeem = async () => {
    if (gameDetail && startedStatus === 2) {
      if (isSuccess) {
        await getPoolContract(gameDetail?.poolInfo?.poolAddress)
        .methods.redeemTokens()
        .send({ from: userAddress });
      } else {
        await getPoolContract(gameDetail?.poolInfo?.poolAddress)
        .methods.redeemGivenMinimumGoalNotAchieved()
        .send({ from: userAddress });
      }
    }
  };

  const renderButton = (): JSX.Element | null => {
    if (startedStatus === 0) {
      return (
        <>
          <button
            type="button"
            disabled
            className="btn-action-link1 w-full noncursor-pointer"
          >
            Coming Soon
          </button>
        </>
      );
    } else if (startedStatus === 1) {
      return (
        <>
          <button
            type="button"
            onClick={() => setShow(true)}
            className="btn-action-link w-full"
          >
            {pool?.type ? "Swap" : "Register for the sale"}
          </button>
        </>
      );
    } else if (startedStatus === 2) {
      return (
        <>
          <button
            type="button"
            disabled={pool && pool.type}
            onClick={() => handleRedeem()}
            className="btn-action-link w-full"
          >
            {pool?.type
              ? "Finished"
              : isSuccess
              ? "Redeem"
              : "Redeem Deposited Amount"}
          </button>
        </>
      );
    } else {
      return null;
    }
  };

  const getParticipatingGamesByGameId = async () => {
    const result = await GameRegisterInfo.getParticipatingGamesByGameId(+id);
    if (!result.error && result.results.length > 0) {
      setPartingUsers(result.results.length);
    }
  };

  useEffect(() => {
    getParticipatingGamesByGameId();
  }, [walletConnected, userAddress, id]);
  return (
    <DetailsContainer>
      <AddressCont
        onClick={() => {
          window.navigator.clipboard.writeText(
            `${getEtherScanLink()}/address/${pool?.poolAddress}` || ""
          );
          setShowAlert(true);
        }}
      >
        <div>{pool?.poolAddress || "Coming soon"}</div>
        <div> <img src={CopyIcon.default} alt="copy" /> Copy </div>
      </AddressCont>
      <InfoContainer>
          <div className="col-6">
            <div className="first-sales-col">
              <HeaderPrimary>Fundraised amount</HeaderPrimary>
              <DateAndRateText>
                {pool?.startTime
                  ? `${pool?.totalRaise ? pool?.totalRaise : 0} ${TokenType[pool?.tokenVarious || 0]}`
                  : "Coming Soon"}
              </DateAndRateText>
            </div>
          </div>
          {/* <div className="divider" /> */}
          <div className="col-6">
            <HeaderPrimary>Participants</HeaderPrimary>
            <DateAndRateText>{partingUsers}</DateAndRateText>
          </div>
      </InfoContainer>
      <RegisterText>
        {pool
          ? regEnded
            ? ("IPO - " + (isSuccess ? 'Success' : 'Failed') )
            : regStarted
            ? `IPO Ends ${regEndTime}`
            : `IPO Starts ${regStartTime}`
          : "Coming soon"}
      </RegisterText>
      {!regEnded && (
        <ProgressBar progress={getProgress()}>
          <span className="filled"></span>
        </ProgressBar>
      )}
    <div className="r-button-group">
      <div className="r-btn-col"><ButtonContainer>{renderButton()}</ButtonContainer></div>
    </div>
      {pool && remainingTime && !regEnded && (
        <TimeLeft> {regEndTime} hours left before IPO closes</TimeLeft>
      )}
      <div className="r-bottom-pera">
        <p><span>Provably fair : </span> Our algo will select people in a truly decentralized and random fashion. You will only be allowed to participate in the sale if you are registered and selected.</p>
      </div>
      <div className="footer-social-link ">
          <ul className="social-link">
            <li>
              <a href="https://x.com/weeweepadwtf" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M22.7076 2.45514C22.5914 2.3548 22.45 2.28806 22.2987 2.26213C22.1474 2.23619 21.9919 2.25203 21.8489 2.30795L2.84949 9.74327C2.58023 9.84797 2.35226 10.0373 2.19992 10.2828C2.04758 10.5283 1.9791 10.8166 2.0048 11.1043C2.0305 11.3921 2.14898 11.6637 2.34241 11.8783C2.53584 12.0929 2.79375 12.2389 3.0773 12.2942L8.00011 13.2608V18.7498C7.99915 19.0488 8.088 19.3412 8.25515 19.5891C8.42231 19.837 8.66007 20.029 8.93761 20.1401C9.21473 20.2533 9.51949 20.2805 9.81225 20.2181C10.105 20.1557 10.3722 20.0067 10.5792 19.7905L12.9529 17.3286L16.7189 20.6248C16.9906 20.8658 17.341 20.9992 17.7042 20.9998C17.8633 20.9997 18.0215 20.9747 18.1729 20.9258C18.4204 20.8472 18.6429 20.7053 18.8184 20.514C18.994 20.3227 19.1163 20.0887 19.1732 19.8355L22.9786 3.28108C23.0126 3.13178 23.0054 2.97603 22.9576 2.83053C22.9099 2.68503 22.8235 2.55527 22.7076 2.45514ZM17.7061 19.4998L9.9548 12.703L21.1111 4.70702L17.7061 19.4998Z" fill="black"/>
                </svg>
              </a>
             
            </li>
            <li>
              <a href="https://t.me/weeweepadwtf" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M23.5308 7.28105L20.7277 10.0842C20.1614 16.6485 14.6255 21.7504 8.00017 21.7504C6.63892 21.7504 5.51673 21.5348 4.66454 21.1092C3.97736 20.7651 3.69611 20.3967 3.62579 20.2917C3.5631 20.1976 3.52246 20.0907 3.50692 19.9787C3.49138 19.8668 3.50133 19.7528 3.53604 19.6452C3.57075 19.5377 3.62931 19.4394 3.70735 19.3577C3.7854 19.2759 3.8809 19.2129 3.98673 19.1732C4.01111 19.1639 6.25923 18.3004 7.68704 16.657C6.89522 16.006 6.20399 15.2414 5.63579 14.3882C4.47329 12.6623 3.17204 9.66417 3.57329 5.18386C3.58601 5.0415 3.63915 4.90573 3.72644 4.79256C3.81373 4.67938 3.93154 4.5935 4.066 4.54503C4.20047 4.49657 4.34598 4.48753 4.4854 4.51899C4.62483 4.55045 4.75236 4.6211 4.85298 4.72261C4.88579 4.75542 7.97298 7.82574 11.7474 8.82136V8.25042C11.7459 7.65173 11.8643 7.0588 12.0955 6.50655C12.3267 5.9543 12.6661 5.4539 13.0936 5.0348C13.5088 4.62017 14.0029 4.2929 14.5466 4.07231C15.0904 3.85172 15.6728 3.74227 16.2595 3.75042C17.0466 3.75819 17.8183 3.96947 18.4996 4.36374C19.1808 4.75801 19.7485 5.32184 20.1474 6.00042H23.0002C23.1486 6.00031 23.2937 6.04423 23.4172 6.12663C23.5406 6.20904 23.6368 6.32621 23.6936 6.46333C23.7505 6.60045 23.7653 6.75134 23.7363 6.8969C23.7073 7.04247 23.6358 7.17616 23.5308 7.28105Z" fill="black"/>
                </svg>
              </a>
              
            </li>
            <li>
              <a href="https://t.me/weeweepadwtf" target="_blank" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M22.25 11.9999C22.2469 14.3828 21.3726 16.6824 19.7917 18.4655C18.2109 20.2485 16.0326 21.392 13.6671 21.6805C13.6145 21.6864 13.5611 21.6812 13.5107 21.665C13.4602 21.6489 13.4137 21.6222 13.3743 21.5868C13.3348 21.5513 13.3034 21.5079 13.282 21.4594C13.2605 21.411 13.2496 21.3585 13.25 21.3055V14.2499H15.5C15.6028 14.2501 15.7045 14.2292 15.7989 14.1884C15.8933 14.1476 15.9783 14.0879 16.0487 14.0129C16.119 13.9379 16.1732 13.8493 16.2078 13.7525C16.2424 13.6557 16.2568 13.5528 16.25 13.4502C16.2334 13.2573 16.1444 13.0779 16.0009 12.9479C15.8574 12.818 15.6701 12.7472 15.4765 12.7499H13.25V10.4999C13.25 10.102 13.408 9.7205 13.6893 9.43919C13.9706 9.15789 14.3521 8.99985 14.75 8.99985H16.25C16.3528 9.00008 16.4545 8.97916 16.5489 8.9384C16.6433 8.89764 16.7283 8.83791 16.7987 8.76292C16.869 8.68792 16.9232 8.59927 16.9578 8.50246C16.9924 8.40565 17.0068 8.30276 17 8.20017C16.9834 8.00698 16.8941 7.82727 16.7502 7.6973C16.6063 7.56733 16.4185 7.49677 16.2246 7.49985H14.75C13.9543 7.49985 13.1912 7.81592 12.6286 8.37853C12.066 8.94114 11.75 9.70421 11.75 10.4999V12.7499H9.49996C9.39714 12.7496 9.29538 12.7705 9.20099 12.8113C9.10659 12.8521 9.02159 12.9118 8.95126 12.9868C8.88092 13.0618 8.82676 13.1504 8.79213 13.2472C8.7575 13.3441 8.74315 13.447 8.74996 13.5495C8.76655 13.7427 8.85579 13.9224 8.99968 14.0524C9.14357 14.1824 9.3314 14.2529 9.52527 14.2499H11.75V21.3074C11.7503 21.3603 11.7394 21.4127 11.718 21.4611C11.6967 21.5095 11.6653 21.5528 11.626 21.5882C11.5866 21.6237 11.5403 21.6504 11.4899 21.6666C11.4395 21.6828 11.3863 21.6882 11.3337 21.6824C8.90498 21.3866 6.6758 20.1898 5.0874 18.3288C3.49901 16.4678 2.66716 14.0783 2.75652 11.6333C2.94402 6.57079 7.04464 2.45517 12.1109 2.25829C13.4225 2.20748 14.731 2.42175 15.9579 2.88826C17.1848 3.35477 18.3051 4.06395 19.2516 4.97338C20.1981 5.88281 20.9515 6.97381 21.4667 8.18111C21.9819 9.38842 22.2483 10.6872 22.25 11.9999Z" fill="black"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
    </DetailsContainer>
  );
};

export default PoolDetPoolDetailsNew;
