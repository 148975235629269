import { Route, Switch } from 'react-router-dom';
import { ParticipatingGame } from './participating-game';
import { MyGame } from './my-game';
import { Profile } from './profile';
import { SafeList } from './safelist';
import { PrivateWhitelist } from './private-whitelilst';
import { PublicWhitelist } from './public-whitelist';
import { KYCContainer } from './kyc';
interface Props {
  match: any;
}

export const Account = ({ match }: Props): JSX.Element => {
  const { path } = match;
  return (
    <>
      <div className="container">
        <Switch>
          <Route path={[path, path]} component={Profile} exact />
          <Route path={`${path}/my-project`} component={MyGame} exact />
          <Route
            path={`${path}/participating-game`}
            component={ParticipatingGame}
          />
          <Route path={`${path}/:id/safelist`} component={SafeList} />
          <Route
            path={`${path}/:id/private-whitelist`}
            component={PrivateWhitelist}
          />
          <Route
            path={`${path}/:id/public-whitelist`}
            component={PublicWhitelist}
          />
          <Route path={`${path}/kyc`} component={KYCContainer} />
        </Switch>
      </div>
    </>
  );
};
