/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

import "./styles.scss";

import { UploadCSVModal } from "./upload-modal";

import { Game, Whitelist } from "../../../core";
import { TiersType, getPoolContract, useAppSelector } from "../../../utils";
import { DownloadIcon, ArrowLeftIcon, UploadWhiteIcon } from "../../../images";

import { toast } from "react-toastify";
import { Table, Tr, Th } from "../style";

export const PublicWhitelist = (): JSX.Element => {
  const history = useHistory();

  const [whitelists, setWhitelists] = useState<Array<Whitelist>>([]);
  const [gameInfo, setGameInfo] = useState<any>();
  const [showAdd, setShowAdd] = React.useState(false);

  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (walletConnected) {
      findGameByUser();
    }
  }, [walletConnected]);

  const findPublicWhitelist = async () => {
    const results = await Whitelist.findPublicWhitelist(
      gameInfo.poolInfo.poolAddress
    );

    setWhitelists(results);
  };

  useEffect(() => {
    if (walletConnected && gameInfo) {
      findPublicWhitelist();
    }
  }, [walletConnected, gameInfo, findPublicWhitelist]);


  const findGameByUser = async () => {
    const { error, result } = await Game.findGameByUser();
    if (error) {
      return;
    }

    setGameInfo(result);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleUpload = async (data?: Array<any>, maxWhitelisted?: number) => {
    setShowAdd(false);

    if (!maxWhitelisted) {
      toast.error("Enter max whitelisted count");
      return;
    }

    const prevDataLength = data?.length || 0;

    data =
      data?.filter((x) =>
        whitelists.every((w) => {
          return w.walletAddress !== x[2];
        })
      ) || [];

    if (!data?.length) {
      toast.error("CSV file can not be empty");
      return;
    }

    if (data?.length !== prevDataLength) {
      toast.info("Duplicate entry has been removed");
    }

    if (!data?.length || !maxWhitelisted) return;
    // const nameArray = data.map((x) => x[0]);
    // const emailArray = data.map((x) => x[1]);
    const walletArry = data.map((x) => x[2]);
    const tierArry = data.map((x) => x[3]);
    // const twitterArray = data.map((x) => x[4]);

    try {
      const { error, result } = await Game.findGameByUser();
      if (error) {
        return;
      }
      if (result) {
        const poolAddress = result.poolInfo?.poolAddress;

        if (poolAddress) {
          const contract = getPoolContract(poolAddress);
          
          await contract.methods
            .addPublicRandom(
              walletArry,
              tierArry,
              maxWhitelisted,
              userAddress,
              // nameArray,
              // emailArray,
              // twitterArray
            )
            .send({ from: userAddress });

          // await getPoolContract(poolAddress)
          //   .methods.addPublicRandom(
          //     walletArry,
          //     tierArry,
          //     maxWhitelisted,
          //     userAddress,
          //     nameArray,
          //     emailArray,
          //     twitterArray
          //   )
          //   .send({ from: userAddress });
          findPublicWhitelist();
        }
      }
    } catch (e) {
      toast("Something went wrong!", {
        type: toast.TYPE.ERROR,
      });
      console.log("something went wrong in get pool addresses", e);
    }
  };

  return (
    <div className="w-full my-game-wrapper flex-column account-public-whitelist">
      <div className="title-action" onClick={handleBack}>
        <img src={ArrowLeftIcon.default} alt="arrow left icon" />
        <span>Go Back</span>
      </div>

      <div className="admin-table-box">
        <div className="main-container">
          {walletConnected && gameInfo ? (
            <>
              <div className="table-header">
                <p>Public Whitelist for {gameInfo.name}</p>
                <div className="actions-container">
                  <CSVLink
                    className="btn-action-link"
                    data={whitelists}
                    filename={`${gameInfo.name}-publiclist.csv`}
                    headers={[
                      { label: "Full Name", key: "name" },
                      { label: "Email", key: "email" },
                      { label: "Wallet Address", key: "walletAddress" },
                      { label: "Tier", key: "tier" },
                      { label: "Twitter", key: "twitter" },
                    ]}
                  >
                    <img src={DownloadIcon.default} alt="download icon" />
                    <span>Download CSV</span>
                  </CSVLink>
                  <button
                    className="btn-action-link"
                    onClick={() => setShowAdd(true)}
                  >
                    <img src={UploadWhiteIcon.default} alt="upload" />
                    <span>Add Public Whitelist</span>
                  </button>
                </div>
              </div>
              <Table className="table-body">
                <thead>
                  <Tr header>
                    <Th>Full Name</Th>
                    <Th>Email</Th>
                    <Th>ERC20 Wallet Address</Th>
                    <Th>Your Twitter Handle</Th>
                    <Th>Tier Title</Th>
                  </Tr>
                </thead>
                <tbody>
                  {whitelists &&
                    whitelists.map((i, index) => (
                      <Tr header key={index}>
                        <Th>{i?.name || ""}</Th>
                        <Th>{i?.email || ""}</Th>
                        <Th>{i.walletAddress}</Th>
                        <Th>@{i.twitter || ""}</Th>
                        <Th>{TiersType[i.tier]}</Th>
                      </Tr>
                    ))}
                </tbody>
              </Table>
              {/* <div className="whitelist-table">
                <div className="table-row table-header">
                  <div>Wallet Address</div>
                </div>
                {whitelists.map((item, index) => (
                  <div key={index} className="table-row">
                    <div>{item.walletAddress}</div>
                  </div>
                ))}
              </div> */}
            </>
          ) : (
            <>
              <h3>Please connect wallet first.</h3>
            </>
          )}
        </div>
      </div>

      {showAdd && (
        <UploadCSVModal
          onClose={(data, maxWhitelisted) => {
            handleUpload(data, maxWhitelisted);
          }}
        />
      )}
    </div>
  );
};
