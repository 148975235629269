import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../logic/actions";
import "./style.scss";
import "./styles.scss";
import { Game } from "../../core";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { UploadBlackIcon } from "../../images";
import { UploadLogoContainer } from "./uploadLogo";
import { Dropdown, Modal, Croper } from "../../components";
import LoginModalContainer from "../../components/login-modal";
import { CongratScreen } from "./congrat-screen/congrat-screen";
import {
  isHttpValid,
  validURL,
  validateEmail,
  validateYouTubeUrl,
} from "../../utils/authUtil";

const initialFormData = {
  name: undefined,
  description: undefined,
  fullDescription: "",
  status: undefined,
  raiseAmount: undefined,
  // videoLink: undefined,
  websiteUrl: undefined,
  pitchDeckLink: undefined,
  email: undefined,
  telegram: undefined,
  twitter: undefined,
  thumbnailLink: "",
  TokenLink: "",
  materialsLink: "",
};

const SubmitGame = () => {
  const dispatch = useDispatch();

  const [showMsg, setShowMsg] = useState(false);
  const [formData, updateFormData] = useState(initialFormData);
  const [logoUrl, setLogoUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("Any way");
  const [gameId, setGameId] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [materialImg, setMaterialImg] = useState([]);
  const [showClip, setShowClip] = useState(false);
  const [showMaterialImgClip, setShowMaterialImgClip] = useState(false);
  const { walletConnected } = useSelector((state: any) => state.user);

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };
  const closeModal = () => {
    setShow(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!walletConnected) {
      setShow(true);
      return;
    }
    if (formData.name === "" || formData.name === undefined) {
      toast("Please input project name", { type: toast.TYPE.INFO });
    } else if (
      formData.description === "" ||
      formData.description === undefined
    ) {
      toast("Please input project description", { type: toast.TYPE.INFO });
    } else if (formData.status === "" || formData.status === undefined) {
      toast("Please select current status", { type: toast.TYPE.INFO });
    } else if (
      formData.raiseAmount === "" ||
      formData.raiseAmount === undefined
    ) {
      toast("Please input fundraise goal", { type: toast.TYPE.INFO });
    } else if (
      formData.websiteUrl === "" ||
      formData.websiteUrl === undefined ||
      isHttpValid(formData.websiteUrl) === false ||
      validURL(formData.websiteUrl) === false
    ) {
      toast("Please add website link correctly", { type: toast.TYPE.INFO });
    } else if (
      formData.pitchDeckLink === "" ||
      formData.pitchDeckLink === undefined
      // || validateCloudStorageUrl(formData.pitchDeckLink) === false
    ) {
      toast(
        "Please add pitchDeck link correctly, you need to add full pitchDeck link",
        { type: toast.TYPE.INFO }
      );
    } else if (
      formData.email === "" ||
      formData.email === undefined ||
      validateEmail(formData.email)
    ) {
      toast("Please add email address to contact correctly", {
        type: toast.TYPE.INFO,
      });
    } else if (formData.telegram === "" || formData.telegram === undefined) {
      toast("Please add telegram id to contact", { type: toast.TYPE.INFO });
    } else if (formData.twitter === "" || formData.twitter === undefined) {
      toast("Please add twitter id to verify", { type: toast.TYPE.INFO });
    } else if (
      (formData.thumbnailLink === "" || formData.thumbnailLink === undefined) &&
      thumbnail?.length === 0
      // ||validateThumbnailLink(formData.thumbnailLink) === false
    ) {
      toast(
        "Please add thumbnail link correctly, you need to add full thumbnail link",
        { type: toast.TYPE.INFO }
      );
    } else if (
      (formData.TokenLink === "" || formData.TokenLink === undefined) &&
      !logoUrl
      // ||validateThumbnailLink(formData.TokenLink) === false
    ) {
      toast(
        "Please add Token link correctly, you need to add full Token link",
        {
          type: toast.TYPE.INFO,
        }
      );
    } else if (
      (formData.materialsLink === "" || formData.materialsLink === undefined) &&
      materialImg?.length === 0
      // ||validateThumbnailLink(formData.materialsLink) === false
    ) {
      toast(
        "Please add materials link correctly, you need to add full materials link",
        {
          type: toast.TYPE.INFO,
        }
      );
    } else if (
      (thumbnail.length === 0 || thumbnail === undefined) &&
      (formData.thumbnailLink === "" || formData.thumbnailLink === undefined)
    ) {
      toast("Please add thumbnail of your project", { type: toast.TYPE.INFO });
    } else if (
      (materialImg.length === 0 || materialImg === undefined) &&
      (formData.materialsLink === "" || formData.materialsLink === undefined)
    ) {
      toast("Please add visual material of your project", {
        type: toast.TYPE.INFO,
      });
    // } else if (
    //   formData.videoLink === "" ||
    //   formData.videoLink === undefined ||
    //   validateYouTubeUrl(formData.videoLink) === false
    // ) {
    //   toast(
    //     "Please add video link correctly, you need to add full YouTube video link",
    //     { type: toast.TYPE.INFO }
    //   );
    // } else if (formData.videoLink === "" || formData.videoLink === undefined) {
    //   toast("Please Upload Project explainer video", { type: toast.TYPE.INFO });
    } else {
      const payload = {
        name: formData.name,
        description: formData.description,
        fullDescription: formData.fullDescription,
        thumbnail: thumbnail,
        status: formData.status,
        raiseAmount: formData.raiseAmount,
        logo: logoUrl,
        // videoLink: formData.videoLink,
        websiteUrl: formData.websiteUrl,
        pitchDeckLink: formData.pitchDeckLink,
        email: formData.email,
        telegram: formData.telegram,
        twitter: formData.twitter,
        preferChat: selectedOption,
        isActive: true,
        thumbnailURL: formData.thumbnailLink,
        tokenURL: formData.TokenLink,
        // video: "",
        visualMaterialLink: JSON.stringify(materialImg),
        visualMaterialURL: formData.materialsLink,
      };
      dispatch(setLoading(true));
      const result = await Game.add(payload);
      dispatch(setLoading(false));
      if (!result.error) {
        setShowMsg(true);
        setGameId(result.results.id);
        window.scroll(0, 0);
      } else {
        toast(result.error, { type: toast.TYPE.WARNING });
      }
    }
  };

  return (
    <div className="submit-game">
      {!showMsg && (
        <div className="container">
          <div className="submit-wrap">
            <div className="title-wrap">
              <div className="heading-row">
                <div className="headingbox">
                  <h2>Submit your project and start raising!</h2>
                </div>
              </div>
              <h3>WeeWeePad crowdfunding platfrom and marketplace</h3>
            </div>
            <div className="submit-row">
              <div className="submit-container">
                <div className="submit-box-wrap">
                  <div className="border-box-wrap">
                    <div className="submit-inner-box">
                      <div className="text-input-group">
                        <div className="submit-form-group">
                          <label className="submit-label">Meme Name</label>
                          <input
                            type="text"
                            placeholder="Enter the name of your meme project"
                            className="submit-form-control"
                            name="name"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">
                            Meme Description
                          </label>
                          <input
                            type="text"
                            placeholder="Tell us about your meme in a few sentences: genre, idea, concept."
                            className="submit-form-control"
                            name="description"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">Project Status</label>
                          <input
                            type="text"
                            placeholder="Status of your project"
                            className="submit-form-control"
                            name="status"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">
                            Expected budget
                          </label>
                          <input
                            type="text"
                            placeholder="Enter the amount you need to deliver your meme (e.g. “$50k”)."
                            className="submit-form-control"
                            name="raiseAmount"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">Website URL</label>
                          <input
                            type="text"
                            placeholder="Your website address."
                            className="submit-form-control"
                            name="websiteUrl"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">Pitchdeck link</label>
                          <input
                            type="text"
                            placeholder="Google drive / Dropbox link."
                            className="submit-form-control"
                            name="pitchDeckLink"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">
                            Preferred way of communication
                          </label>
                          <Dropdown
                            initialValue={selectedOption}
                            handleSelect={(e) => setSelectedOption(e)}
                            optionItems={["Any way", "Email", "Telegram"]}
                            className="w-100 drop-down"
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">Contact email</label>
                          <input
                            type="text"
                            placeholder="We will contact you by your email."
                            className="submit-form-control"
                            name="email"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">
                            Telegram handle
                          </label>
                          <input
                            type="text"
                            placeholder="We may contact you on Telegram."
                            className="submit-form-control"
                            name="telegram"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="submit-form-group">
                          <label className="submit-label">Twitter handle</label>
                          <input
                            type="text"
                            placeholder="We may contact you on Twitter."
                            className="submit-form-control"
                            name="twitter"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="upload-input-group">
                        <div className="upload-wrap">
                          <Croper
                            title="Upload Thumbnail Image"
                            validFormat={["PNG or JPG", "up to 20 MB"]}
                            fileType="image/png, image/gif, image/jpeg"
                            thumbnail={thumbnail}
                            setThumbnail={setThumbnail}
                            showClip={showClip}
                            setShowClip={setShowClip}
                            description="Upload a Thumbnail Image for your project page"
                          />
                        </div>
                        <div className="upload-wrap">
                          <UploadLogoContainer
                            type="image"
                            imgUrl={UploadBlackIcon.default}
                            label="Upload Token Logo Image"
                            options={["PNG or JPG", "up to 20 MB"]}
                            text="Upload Token logo"
                            setMediaURL={setLogoUrl}
                            mediaURL={logoUrl}
                          />
                        </div>
                        <div className="upload-wrap">
                          <Croper
                            title="Upload Visual Material"
                            validFormat={["PNG or JPG", "20mb too small"]}
                            fileType="image/png, image/gif, image/jpeg"
                            thumbnail={materialImg}
                            setThumbnail={setMaterialImg}
                            showClip={showMaterialImgClip}
                            setShowClip={setShowMaterialImgClip}
                            description="Upload a logo, meme screenshots or artwork"
                          />
                        </div>
                        {/* <div className="submit-form-group">
                          <label className="submit-label">
                            Project explainer video
                          </label>
                          <input
                            type="text"
                            placeholder="Add an explainer YouTube video via Youtube embed option"
                            className="submit-form-control"
                            name="videoLink"
                            onChange={handleChange}
                          />
                        </div> */}
                        <div className="submit-form-group submit-form-review mt-5">
                          <p>
                            We will review your submissions within 48 hours. If
                            your meme potentially fits our platform, we will
                            contact you through your provided email address or
                            on Telegram to arrange a call.
                          </p>
                        </div>
                        <div className="submit-action-row">
                          <button
                            type="button"
                            className="btn-action-link full-width"
                            onClick={handleSubmit}
                          >
                            SUBMIT MEME
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showMsg && <CongratScreen id={gameId} />}
      <Modal show={show} closeModal={closeModal}>
        <LoginModalContainer show={show} closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default SubmitGame;
