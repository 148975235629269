import {
  GameListIcon,
  ParticipatedIcon,
  ProfileIcon,
  KycIcon,
} from "../../../images";
import { Wrapper, Link, MobileWrapper, MobileLink } from "./style";

export const Sidebar = (): JSX.Element => (
  <Wrapper>
    <Link to={"/account"} exact>
      <img src={ProfileIcon.default} alt="Profile" />
      <span>Profile</span>
    </Link>
    <Link to={"/account/my-project"} exact>
      <img src={GameListIcon.default} alt="My projects" />
      <span>Edit my project</span>
    </Link>
    <Link to={"/account/participating-game"}>
      <img src={ParticipatedIcon.default} alt="Participated" />
      <span>Participating Memes</span>
    </Link>

    <Link to={"/account/kyc"}>
      <img src={KycIcon.default} alt="kyc" />
      <span>KYC</span>
    </Link>
  </Wrapper>
);

export const MobileSidebar = (): JSX.Element => (
  <MobileWrapper>
    <MobileLink to={"/account"} exact>
      <img src={ProfileIcon.default} alt="Profile" />
      Profile
    </MobileLink>
    <MobileLink to={"/account/my-project"} exact>
      <img src={GameListIcon.default} alt="My projects" />
      My Project
    </MobileLink>
    <MobileLink to={"/account/participating-game"}>
      <img src={ParticipatedIcon.default} alt="Participated" />
      Participating Projects
    </MobileLink>

    <MobileLink to={"/account/kyc"}>
      <img src={KycIcon.default} alt="Participated" />
      KYC
    </MobileLink>
  </MobileWrapper>
);
