import styled from "styled-components";
import { Theme } from "../../utils";

interface Props {
  show: boolean;
}

export const ModalWrapper = styled.div<Props>`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: ${Theme.modalBg};
display: ${(props) => (props.show ? "block" : "none")};
z-index: ${(props) => (props.show ? "3" : "-3")};
opacity: ${(props) => (props.show ? "1" : "0")};
`;

export const ModalBorder = styled.div`
position: relative;
padding: 1px;
width: 750px;
border-radius: 12px;
margin: 105px 25rem 0;


@media screen and (max-width: 586px) {
  width: 95vw;
  margin: 10px;
  padding: 1px;
  height: auto;
}
`;

export const ModalContainer = styled.div`
width: 600px;
padding: 27px 30px 41px 30px;
box-shadow: ${Theme.modalShadow};
border-radius: ${Theme.componentBorderRadius};
position: relative;
background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.15) 98.47%);
backdrop-filter: blur(40px);
border-radius: 24px;


@media (max-width: 600px) {
  width: 100%;
  margin: 0;
  padding: 50px 30px 41px 30px;
}

@media (max-width: 500px) {
  max-height: 99%;
  overflow-y: auto;
}
`;

export const CrossIconContainer = styled.div`
position: absolute;
top: -25px;
right: -30px;
width: 64px;
height: 64px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

img {
width: 100%;
height: 100%;
}

@media (max-width: 600px) {
width: 30px;
  height: 30px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}
`;
