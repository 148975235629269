import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  width: 100%;
  color: #ffffff40;
  cursor: pointer;
`;

// const Item = styled.div`
//   width: 100%;
//   padding: 0 20px;
//   height: 44px;
//   color: #ffffff;
//   box-shadow: 0px 2px 0px 1px #000,
//     0px 1.2px 0px 0px rgba(255, 255, 255, 0.25) inset,
//     0px -2px 0px 0px #915101 inset;
//   outline: none;
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 18px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   letter-spacing: 0.3px;
//   cursor: pointer;
// `;

const Item = styled.div`
  width: 100%;
  padding: 0 20px;
  height: 44px;
  color: #ffffff;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
  cursor: pointer;
`;

interface RadioOptionProps {
  label: string;
  id: string;
  checked?: boolean;
  onChange: () => void;
}

export default function RadioButton({
  label,
  id,
  checked,
  onChange,
}: RadioOptionProps) {
  return (
    <Container id={id}>
      {checked ? (
        <Item className="btn-action-link w-full">{label}</Item>
      ) : (
        <div onClick={onChange}>
          <Label>{label}</Label>
        </div>
      )}
    </Container>
  );
}
