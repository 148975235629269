import styled from 'styled-components';
import { Field } from 'formik';
import { Color, PrimaryButton } from '../../utils';

export const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${Color.blueTertiary};
  padding: 30px 0;
`;

export const Container = styled.div`
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  & > form {
    width: 70%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    & > form {
      width: 100%;
    }
  }
`;

export const PreviewContainer = styled.div`
  width: 28.5%;
  margin-left: auto;
  @media only screen and (max-width: 768px) {
    margin: 0px auto 30px;
    width: 40%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const PreviewHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
`;

export const FormContainer = styled.div`
  min-height: 420px;
  width: 100%;
  margin: 0 auto;
  border-radius: 2px;
  padding: 35px 40px 32px 40px;
  border-radius: 8px;
  @media only screen and (max-width: 500px) {
    padding: 20px;
    label {
      width: auto;
      margin-left: 0 !important;
    }
  }
`;

export const BtnContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media only screen and (max-width: 500px) {
    gap: 20px;
    flex-direction: column;
    & button {
      width: auto;
      height: auto;
      font-size: 16px;
      padding: 8px 20px;
    }
  }
`;

export const SecondaryBtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media only screen and (max-width: 500px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const NextButton = styled(PrimaryButton)`
  width: 126px;
  height: 40px;
  font-size: 16px;
  line-height: 22px;
  margin-left: 20px;
`;

export const CancelBtn = styled(PrimaryButton)`
  width: 126px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #2cfee1;
  background: #152040;
  border-radius: 30px;
  border: 1px solid #2cfee1;
  box-shadow: none;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    & label {
      margin-bottom: 10px;
    }
    & input[type='text'] {
      margin-left: 0;
      max-width: 100%;
      width: 100% !important;
      padding: 16px 10px;
    }
  }
`;

export const FieldLabel = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 140px;
`;

export const FieldLabelLarge = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 250px;
`;

export const InputField = styled(Field)`
width: 100%;
border-radius: 4px;
border: 2px solid var(--Black, #111);
background: var(--White-White-100, #FFF);
box-shadow: 4px 4px 0px 0px #111;
padding: 12px 15px;
font-weight: 400;
font-size: 15px;
color: #000;
`;

export const InputFieldSmall = styled(Field)`
  margin-left: 21px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a2b8db;
  padding: 10px 15px 12px 15px;
  width: 200px;
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #FFF);
  box-shadow: 4px 4px 0px 0px #111;
`;

export const InputFieldSmall1 = styled(Field)`
  min-width: 100px;
  min-height: 20px;
  border-radius: 4px;
  border: 2px solid var(--Black, #111);
  background: var(--White-White-100, #FFF);
  box-shadow: 4px 4px 0px 0px #111;
  padding: 12px 15px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
`;
export const ErrorContainer = styled.div`
  margin-top: 10px;
  color: red;
`;

interface PoolTypeItemProps {
  selected: boolean;
}

export const PoolTypeItem = styled.div<PoolTypeItemProps>`
  flex: 1;
  background: #000e36;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.selected ? '#2CFEE1' : '#18355b')};
  min-height: 210px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 300ms all;
  padding: 21px;
`;

export const CheckBox = styled.div<PoolTypeItemProps>`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? '#2CFEE1' : '#18355b')};
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms all;
`;

export const PoolTypeContainer = styled.div`
  display: flex;
  margin-top: 19px;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const PoolTypeOptions = styled.div`
  display: flex;
  margin-left: 10px;
  flex: 1;

  ${PoolTypeItem}:first-child {
    margin-right: 10px;
  }

  ${PoolTypeItem}:last-child {
    margin-left: 10px;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    margin-top: 10px;
    & > div {
      margin: 24px 0 !important;
    }
  }
`;

export const SummaryTop = styled.div`
  padding: 12px;
  background: #071233;
  border: 1px solid #18355b;
  border-radius: 8px;
  margin-top: 13px;
`;

export const SummaryImgContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 9px;
`;

export const SummaryImg = styled.div`
  width: 60px;
  height: 60px;

  img {
    width: 100%;
  }
`;

export const PoolContainer = styled.div`
  margin-left: 9px;
`;

export const PoolName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const PoolType = styled.div`
  margin-top: 4px;
  width: 100px;
  height: 28px;
  border: 1px solid #18355b;
  border-radius: 50px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #2cfee1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PoolBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 4px;
  margin-top: 10px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      color: #a2b8db;
    }
    & > h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.1px;
      color: white;
      height: 30px;
      margin-top: 6px;
    }
  }
`;
