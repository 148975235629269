import gql from 'graphql-tag';

export const GET_NONCE_BY_WALLET = gql`
  query ($data: GetNonceByWalletInput!) {
    getNonceByWallet(data: $data) {
      nonce
    }
  }
`;

export const AUTH_BY_WALLET = gql`
  mutation ($data: AuthByWalletAddressInput!) {
    authByWalletAddress(data: $data) {
      accessToken
      refreshToken
    }
  }
`;

export const GENERATE_SIGNATURE = gql`
  mutation ($tokenId: Int!, $tokenQuantity: Int!) {
    genereateECDSASignature(tokenId: $tokenId, tokenQuantity: $tokenQuantity) {
      expirationTime
      signature
      valueSent
    }
  }
`;
