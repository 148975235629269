import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Sidebar } from "../sidebar";
import "./style.scss";
import { BlockPassImg, CheckMarkImg, NonEyeIcon } from "src/images";
import { kycClientId } from "src/utils";
import { User } from "../../../core";
import axios from "axios";
import { endpoint_api } from "src/lib/network/apiClient";

export const KYCContainer = (): JSX.Element => {
  const [loader, setLoader] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const { walletConnected, userAddress, kycVerified } = useSelector(
    (state: any) => state.user
  );
  const [kycStatus, setKycStatus] = useState("PROGRESS");

  useEffect(() => {
    if (walletConnected) {
      setKycStatus(kycVerified);
      setLoader(false);
    }
  }, [kycVerified, walletConnected]);
  const handleKYCStatus = (data: any) => {
    const payload = {
      refId: userAddress,
      event: data.status,
    };
    axios.post(`${endpoint_api}/api/blockpass_hook`, payload).then((res) => {
      User.fetchUserInfo().then(async (result: any) => {
        if (result && result.data) {
          setKycStatus(result.data.kycVerified);
        }
      });
    });
  };
  const loadBlockpassWidget = async () => {
    if (walletConnected && window && userAddress && kycClientId) {
      // @ts-ignore
      const blockpass = await new window.BlockpassKYCConnect(kycClientId, {
        refId: userAddress,
      });
      await blockpass.startKYCConnect();
      await blockpass.on("KYCConnectLoad", () => {
        setLoader(true);
        //add code that will trigger when the iframe is loaded.
        //ex: stop loading animation
      });
      await blockpass.on("KYCConnectSuccess", (data: any) => {
        setKycStatus("PROGRESS");
        setLoader(false);
        handleKYCStatus(data);
        //add code that will trigger when data have been sent.
      });
      await blockpass.on("KYCConnectCancel", () => {
        setLoader(true);

        User.fetchUserInfo().then(async (result: any) => {
          if (result && result.data) {
            setKycStatus(result.data.kycVerified);
            setLoader(false);
          }
        });
      });
      await blockpass.on("KYCConnectClose", () => {
        setLoader(false);
      });
    }
  };

  const getScreens = () => {
    if (loader) {
      return (
        <>
          <svg
            width="186"
            height="187"
            viewBox="0 0 186 187"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4545_1393)">
              <path
                d="M135.239 21.9998C135.239 21.9998 185.657 32.6519 185.997 59.611C186.337 86.5701 156.716 91.1717 168.643 107.113C181.823 124.73 179.548 148.749 134.922 160.264C90.2953 171.781 54.2052 161.408 31.4129 138.695C-2.16347 105.235 -17.18 65.9547 29.3167 46.5757C75.8135 27.1966 135.241 22.0012 135.241 22.0012L135.239 21.9998Z"
                fill="#1D1928"
              />
            </g>
            <g clip-path="url(#clip1_4545_1393)">
              <path
                d="M111.041 177.681C133.892 173.166 150.931 161.987 149.098 152.714C147.266 143.44 127.256 139.582 104.405 144.098C81.5537 148.613 64.5149 159.791 66.3474 169.065C68.1799 178.339 88.1899 182.197 111.041 177.681Z"
                fill="#302E37"
              />
              <path
                d="M136.257 119.62C132.872 102.491 118.316 90.4093 101.644 89.4315C99.3871 89.3 97.6783 87.2962 98.024 85.0636C98.033 85.0035 98.044 84.9429 98.0554 84.8844C98.2683 83.7226 99.0157 82.7305 100.064 82.1815C114.516 74.6114 123.94 57.2867 120.637 40.5692L115.764 15.9112C112.321 -1.51268 38.61 13.0527 42.0529 30.4766L46.9254 55.1346C50.3791 72.6127 65.5368 84.4336 82.6328 84.9586C83.1159 84.9737 83.5955 85.0666 84.0383 85.2584C86.6312 86.3807 87.6785 87.9732 87.2295 89.7675C86.948 90.8885 86.0988 91.7882 85.0275 92.2292C68.8037 98.9275 59.0172 116.328 62.5459 134.185L67.4183 158.843C71.9529 181.792 145.664 167.226 141.129 144.278L136.257 119.62Z"
                fill="#433F51"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M128.44 118.463C125.935 105.788 115.677 96.6211 103.558 94.8999C100.268 94.4324 97.2819 92.5976 95.5249 89.7797C94.0827 87.4668 93.374 84.3875 95.2687 80.6771C97.4902 76.3287 110.523 70.0526 113.463 61.0626L63.2572 70.9834C63.2572 70.9834 72.9237 80.2281 83.9428 82.5127C89.1468 83.5923 90.0851 87.6333 88.9449 91.6867C88.1131 94.6387 86.075 97.1056 83.38 98.5722C72.3063 104.599 65.9164 117.302 68.4871 130.311L73.1168 153.741C76.7731 172.245 136.726 160.396 133.07 141.894L128.44 118.465L128.44 118.463Z"
                fill="#302E37"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M113.63 59.6765C114.438 63.7681 103.692 69.3382 89.6241 72.118C75.5585 74.8974 63.499 73.8338 62.6905 69.7422C61.882 65.6506 72.6286 60.0805 86.6962 57.3007C100.764 54.5209 112.821 55.5849 113.63 59.6765Z"
                fill="#25222F"
              />
              <path
                d="M80.3316 35.869C103.183 31.3536 120.508 21.6271 119.03 14.1442C117.551 6.66125 97.828 4.25558 74.977 8.77096C52.126 13.2863 34.8002 23.0129 36.2789 30.4958C37.7575 37.9787 57.4806 40.3844 80.3316 35.869Z"
                fill="#302E37"
              />
              <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
                <path
                  d="M141.237 145.055C141.204 144.77 141.168 144.483 141.111 144.196L136.239 119.538C132.854 102.409 118.298 90.3272 101.626 89.3494C99.369 89.2179 97.6601 87.214 98.0059 84.9815C98.0149 84.9213 98.0259 84.8608 98.0372 84.8022C98.2502 83.6405 98.9976 82.6484 100.045 82.0994C114.498 74.5292 123.922 57.2046 120.618 40.487L116.608 20.1896C118.51 18.083 119.395 15.9984 119.012 14.064C117.533 6.57712 97.809 4.17156 74.9588 8.68678C71.6362 9.34332 68.4342 10.1136 65.3863 10.9661C83.8636 8.79537 112.262 8.64248 113.525 15.0377C113.908 16.9722 115.307 22.3836 98.6017 30.5239L101.323 44.2977C103.965 57.6641 98.4664 71.415 88.7698 80.2596C86.8616 82.0002 89.2952 94.0941 92.3781 95.1613C104.574 99.3859 114.263 109.781 116.944 123.346C116.944 123.346 132.511 171.854 101.283 179.036C104.438 178.725 107.699 178.25 111.021 177.593C133.871 173.078 150.911 161.9 149.078 152.625C148.442 149.407 145.612 146.84 141.234 145.051L141.237 145.055Z"
                  fill="#C0B9D6"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_4545_1393">
                <rect
                  width="186"
                  height="143"
                  fill="white"
                  transform="matrix(-1 0 0 -1 186 165)"
                />
              </clipPath>
              <clipPath id="clip1_4545_1393">
                <rect
                  width="86.9862"
                  height="172.781"
                  fill="white"
                  transform="translate(33.584 16.8625) rotate(-11.1777)"
                />
              </clipPath>
            </defs>
          </svg>

          <p className="text-color">Wait for Review Finish...</p>
        </>
      );
    }
    if (isStart) {
      return (
        <>
          <img src={BlockPassImg.default} alt="" />
          <p className="text-color">Verify Your Account with Blockpass</p>
          <button
            id="blockpass-kyc-connect"
            className="btn-action-link"
            onClick={() => loadBlockpassWidget()}
          >
            Submit
          </button>
        </>
      );
    }
    // if (kycStatus === "PROGRESS") {
    //   return (
    //     <>
    //       <svg
    //         width="186"
    //         height="187"
    //         viewBox="0 0 186 187"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //       >
    //         <g clip-path="url(#clip0_4545_1393)">
    //           <path
    //             d="M135.239 21.9998C135.239 21.9998 185.657 32.6519 185.997 59.611C186.337 86.5701 156.716 91.1717 168.643 107.113C181.823 124.73 179.548 148.749 134.922 160.264C90.2953 171.781 54.2052 161.408 31.4129 138.695C-2.16347 105.235 -17.18 65.9547 29.3167 46.5757C75.8135 27.1966 135.241 22.0012 135.241 22.0012L135.239 21.9998Z"
    //             fill="#1D1928"
    //           />
    //         </g>
    //         <g clip-path="url(#clip1_4545_1393)">
    //           <path
    //             d="M111.041 177.681C133.892 173.166 150.931 161.987 149.098 152.714C147.266 143.44 127.256 139.582 104.405 144.098C81.5537 148.613 64.5149 159.791 66.3474 169.065C68.1799 178.339 88.1899 182.197 111.041 177.681Z"
    //             fill="#302E37"
    //           />
    //           <path
    //             d="M136.257 119.62C132.872 102.491 118.316 90.4093 101.644 89.4315C99.3871 89.3 97.6783 87.2962 98.024 85.0636C98.033 85.0035 98.044 84.9429 98.0554 84.8844C98.2683 83.7226 99.0157 82.7305 100.064 82.1815C114.516 74.6114 123.94 57.2867 120.637 40.5692L115.764 15.9112C112.321 -1.51268 38.61 13.0527 42.0529 30.4766L46.9254 55.1346C50.3791 72.6127 65.5368 84.4336 82.6328 84.9586C83.1159 84.9737 83.5955 85.0666 84.0383 85.2584C86.6312 86.3807 87.6785 87.9732 87.2295 89.7675C86.948 90.8885 86.0988 91.7882 85.0275 92.2292C68.8037 98.9275 59.0172 116.328 62.5459 134.185L67.4183 158.843C71.9529 181.792 145.664 167.226 141.129 144.278L136.257 119.62Z"
    //             fill="#433F51"
    //           />
    //           <path
    //             fill-rule="evenodd"
    //             clip-rule="evenodd"
    //             d="M128.44 118.463C125.935 105.788 115.677 96.6211 103.558 94.8999C100.268 94.4324 97.2819 92.5976 95.5249 89.7797C94.0827 87.4668 93.374 84.3875 95.2687 80.6771C97.4902 76.3287 110.523 70.0526 113.463 61.0626L63.2572 70.9834C63.2572 70.9834 72.9237 80.2281 83.9428 82.5127C89.1468 83.5923 90.0851 87.6333 88.9449 91.6867C88.1131 94.6387 86.075 97.1056 83.38 98.5722C72.3063 104.599 65.9164 117.302 68.4871 130.311L73.1168 153.741C76.7731 172.245 136.726 160.396 133.07 141.894L128.44 118.465L128.44 118.463Z"
    //             fill="#302E37"
    //           />
    //           <path
    //             fill-rule="evenodd"
    //             clip-rule="evenodd"
    //             d="M113.63 59.6765C114.438 63.7681 103.692 69.3382 89.6241 72.118C75.5585 74.8974 63.499 73.8338 62.6905 69.7422C61.882 65.6506 72.6286 60.0805 86.6962 57.3007C100.764 54.5209 112.821 55.5849 113.63 59.6765Z"
    //             fill="#25222F"
    //           />
    //           <path
    //             d="M80.3316 35.869C103.183 31.3536 120.508 21.6271 119.03 14.1442C117.551 6.66125 97.828 4.25558 74.977 8.77096C52.126 13.2863 34.8002 23.0129 36.2789 30.4958C37.7575 37.9787 57.4806 40.3844 80.3316 35.869Z"
    //             fill="#302E37"
    //           />
    //           <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
    //             <path
    //               d="M141.237 145.055C141.204 144.77 141.168 144.483 141.111 144.196L136.239 119.538C132.854 102.409 118.298 90.3272 101.626 89.3494C99.369 89.2179 97.6601 87.214 98.0059 84.9815C98.0149 84.9213 98.0259 84.8608 98.0372 84.8022C98.2502 83.6405 98.9976 82.6484 100.045 82.0994C114.498 74.5292 123.922 57.2046 120.618 40.487L116.608 20.1896C118.51 18.083 119.395 15.9984 119.012 14.064C117.533 6.57712 97.809 4.17156 74.9588 8.68678C71.6362 9.34332 68.4342 10.1136 65.3863 10.9661C83.8636 8.79537 112.262 8.64248 113.525 15.0377C113.908 16.9722 115.307 22.3836 98.6017 30.5239L101.323 44.2977C103.965 57.6641 98.4664 71.415 88.7698 80.2596C86.8616 82.0002 89.2952 94.0941 92.3781 95.1613C104.574 99.3859 114.263 109.781 116.944 123.346C116.944 123.346 132.511 171.854 101.283 179.036C104.438 178.725 107.699 178.25 111.021 177.593C133.871 173.078 150.911 161.9 149.078 152.625C148.442 149.407 145.612 146.84 141.234 145.051L141.237 145.055Z"
    //               fill="#C0B9D6"
    //             />
    //           </g>
    //         </g>
    //         <defs>
    //           <clipPath id="clip0_4545_1393">
    //             <rect
    //               width="186"
    //               height="143"
    //               fill="white"
    //               transform="matrix(-1 0 0 -1 186 165)"
    //             />
    //           </clipPath>
    //           <clipPath id="clip1_4545_1393">
    //             <rect
    //               width="86.9862"
    //               height="172.781"
    //               fill="white"
    //               transform="translate(33.584 16.8625) rotate(-11.1777)"
    //             />
    //           </clipPath>
    //         </defs>
    //       </svg>

    //       <p className="text-color">
    //         Your application is submitted Successfully and ready for Review
    //       </p>
    //     </>
    //   );
    // }

    if (kycStatus === "VERIFIED") {
      return (
        <>
          <img className="kyc-check-icon" src={CheckMarkImg.default} alt="" />
          <h4 className="text-color">Verified Successfully</h4>
        </>
      );
    }
    if (kycStatus === "FAILED") {
      return (
        <>
          <svg
            width="170"
            height="170"
            viewBox="0 0 170 170"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_4545_1914)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M85 159.038C125.89 159.038 159.038 125.89 159.038 85C159.038 44.1097 125.89 10.9615 85 10.9615C44.1097 10.9615 10.9615 44.1097 10.9615 85C10.9615 125.89 44.1097 159.038 85 159.038ZM85 162C127.526 162 162 127.526 162 85C162 42.4741 127.526 8 85 8C42.4741 8 8 42.4741 8 85C8 127.526 42.4741 162 85 162Z"
                fill="#6C6C6C"
              />
            </g>
            <path
              d="M157.188 85C157.188 124.868 124.868 157.188 85 157.188C45.1319 157.188 12.8125 124.868 12.8125 85C12.8125 45.1319 45.1319 12.8125 85 12.8125C124.868 12.8125 157.188 45.1319 157.188 85Z"
              fill="#191E25"
            />
            <g filter="url(#filter1_d_4545_1914)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M85 154.226C123.232 154.226 154.226 123.232 154.226 85C154.226 46.7676 123.232 15.774 85 15.774C46.7676 15.774 15.774 46.7676 15.774 85C15.774 123.232 46.7676 154.226 85 154.226ZM85 157.188C124.868 157.188 157.188 124.868 157.188 85C157.188 45.1319 124.868 12.8125 85 12.8125C45.1319 12.8125 12.8125 45.1319 12.8125 85C12.8125 124.868 45.1319 157.188 85 157.188Z"
                fill="#6C6C6C"
              />
            </g>
            <path
              d="M107.25 62.3842L63.75 105.884M63.75 62.3842L107.25 105.884"
              stroke="#6C6C6C"
              strokeWidth="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <filter
                id="filter0_d_4545_1914"
                x="0"
                y="0"
                width="170"
                height="170"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_4545_1914"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_4545_1914"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d_4545_1914"
                x="4.8125"
                y="4.8125"
                width="160.375"
                height="160.375"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.45 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_4545_1914"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_4545_1914"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>

          <h4 className="text-color">Verification Failed</h4>
          <button
            id="blockpass-kyc-connect"
            onClick={() => loadBlockpassWidget()}
            className="btn-action-link"
          >
            Redo verification
          </button>
        </>
      );
    }
    if (!isStart) {
      return (
        <>
          <img src={NonEyeIcon.default} alt="not verified icon" />
          <h2 className="kyc-check-text">You are not verified</h2>
          <p>Fill in the form to complete verification</p>
          <button
            id="blockpass-kyc-connect"
            onClick={() => setIsStart(true)}
            className="btn-action-link"
          >
            Get Verified
          </button>
        </>
      );
    }
  };

  return (
    <div className="my-game-wrapper">
      <Sidebar />
      <div className="content">
        <div className="title-action">KYC</div>
        <div className="admin-table-box">
          <div className="main-container kyc-container">
            {walletConnected ? (
              <>{getScreens()}</>
            ) : (
              <div className="no-wallet-connected">
                <h3>Please connect wallet first.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
