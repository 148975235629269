import { useSelector } from 'react-redux';

import { Loading } from '../../images';
import { LoaderComponent } from './style'

const Loader = () => {
    const { loading } = useSelector((state: any) => state.loading);
    return (
        <>
        {
            loading &&
            <LoaderComponent>
                <img src={ Loading.default } alt='loading'/>
            </LoaderComponent>
        }
        </>
    )
}

export default Loader