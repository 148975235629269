/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

import { Game } from "../../../core";
import { TiersType, useAppSelector } from "../../../utils";
import { DownloadIcon, ArrowLeftIcon } from "../../../images";

import "./style.scss";
import { Table, Tr, Th, Td } from "../style";

interface Params {
  id: string;
}

const headers = [
  { label: "Fullname", key: "name" },
  { label: "Email", key: "email" },
  { label: "Wallet", key: "user.walletAddress" },
  { label: "Tier", key: "tier" },
  { label: "Twitter", key: "twitter" },
];

export const SafeList = (): JSX.Element => {
  const [safeLists, setSafeLists] = useState<any>();
  const [gameInfo, setGameInfo] = useState<any>();

  const history = useHistory();
  const { id }: Params = useParams();
  const { walletConnected } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (walletConnected) {
      findGameByUser();
      findSafelistById();
    }
  }, [walletConnected]);

  const findSafelistById = async () => {
    const result = await Game.findSafelistById(+id);
    if (!result.error) {
      setSafeLists(result.results);
    }
  };

  const findGameByUser = async () => {
    const { error, result } = await Game.findGameByUser();
    if (error) {
      return;
    }
    setGameInfo(result);
  };

  return (
    <div className="w-full my-game-wrapper flex-column">
      <div className="title-action" onClick={() => history.goBack()}>
        <img src={ArrowLeftIcon.default} alt="arrow left icon" />
        <span>Go Back</span>
      </div>

      <div className="admin-table-box">
        <div className="main-container">
          {walletConnected && gameInfo ? (
            <>
              <div className="table-header">
                <p>
                  Safelist for {gameInfo.name} - {gameInfo.name}
                </p>
                {safeLists && (
                  <CSVLink
                    className="btn-action-link"
                    data={safeLists}
                    filename={`${gameInfo.name}-safelist.csv`}
                    headers={headers}
                  >
                    <img src={DownloadIcon.default} alt="download icon" />
                    <span>Download CSV</span>
                  </CSVLink>
                )}
              </div>
              <Table className="table-body">
                <thead>
                  <Tr header>
                    <Th>Full Name</Th>
                    <Th>Email</Th>
                    <Th>ERC20 Wallet Address</Th>
                    <Th>Your Twitter Handle</Th>
                    <Th>Tier Title</Th>
                  </Tr>
                </thead>
                <tbody>
                  {safeLists && safeLists.length > 0 ? (
                    safeLists.map((i: any, index: number) => (
                      <Tr header key={'safelist_' + index}>
                        <Td>{i.name}</Td>
                        <Td>{i.email}</Td>
                        <Td>{i.user.walletAddress}</Td>
                        <Td>@{i.twitter}</Td>
                        <Td>{TiersType[i.tier]}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Th colSpan={5} className="text-center">
                        No data found
                      </Th>
                    </Tr>
                  )}
                </tbody>
              </Table>
            </>
          ) : !walletConnected ? (
            <h3>Please connect wallet first.</h3>
          ) : (
            <h3>Please wait...</h3>
          )}
        </div>
      </div>
    </div>
  );
};
