import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { buyArchonroyaLot } from "../../logic/actions";
import {
  Header,
  BuyLotButton,
  CancelLotButton,
  BtnContainerSecondary,
  LotSelect,
  ImgSignContainer,
  LotSelectContainer,
  LotText,
  LotTextSecondary,
  ErrorText,
  //DiscountBox,
  //DiscountBoxText,
  //DiscountField,
  LotAmountText,
  WarningTextCenter,
  LoadingText,
} from "../style";
import { MinusIcon, PlusIcon } from "../../images";
import {
  getConfig,
  fromRoya,
  renderTokenAmountText,
  StakingLotModals,
  toRoya,
  LotPrice,
  thousandSeparator,
  BorderForBtn,
  // getRoyaNftContract,
} from "../../utils";

interface Props {
  closeModal: () => void;
}

const initialValues = {
  lotAmount: 1,
  nftLotAmount: 1,
  nftDiscount: "",
};

const BuyArchon = (props: Props) => {
  const { closeModal } = props;

  const [royaSelected, setRoyaSelected] = useState(true);
  const [nftAmount] = useState(0);
  const { lotOperation } = useSelector((state: any) => state.stakingLots);

  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );

  const { userRoyaBalance } = useSelector((state: any) => state.royaReserve);

  const dispatch = useDispatch();

  // const getKNFTAmount = async () => {
  //   const amount = await getRoyaNftContract()
  //     .methods.balanceOf(userAddress, 3)
  //     .call();
  //   setNFTAmount(amount);
  // };

  useEffect(() => {
    if (userAddress) {
      // getKNFTAmount();
    }
  }, [userAddress]);

  const handleBuyArchon = (values: any) => {
    const { lotAmount, nftLotAmount } = values;

    let amount = lotAmount;
    if (!royaSelected) {
      amount = nftLotAmount;
    }
    if (walletConnected) {
      dispatch(buyArchonroyaLot(userAddress, amount, royaSelected, closeModal));
    }
  };

  const schema = Yup.object().shape({
    lotAmount: Yup.string()
      .required("Enter value")
      .test(
        "lowAmount",
        `Should be greater than or equal to 1`, 
        (val) => parseFloat(val) >= 1
      )
      .test(
        "InsufficientFunds",
        `Insufficient Funds`, 
        (val) =>
          !royaSelected
            ? true
            : BigInt(userRoyaBalance) >=
              BigInt(
                toRoya((parseInt(val as string) * LotPrice.ArchonLot).toString())
              )
      ),
    nftLotAmount: Yup.string()
      .required("Enter value")
      .test(
        "lowAmount",
        `Should be greater than or equal to 1`, 
        (val) => parseFloat(val) >= 1
      )
      .test(
        "InsufficientFunds",
        `Insufficient Balance`, 
        (val) => (royaSelected ? true : nftAmount >= parseInt(val as string))
      ),
    nftDiscount: Yup.string(),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleBuyArchon}
    >
      {({ values, setFieldValue }) => {
        const handleIncrement = () => {
          setFieldValue("lotAmount", values.lotAmount + 1);
        };

        const handleDecrement = () => {
          const { lotAmount } = values;
          if (lotAmount > 1) {
            setFieldValue("lotAmount", lotAmount - 1);
          }
        };

        const handleNFTIncrement = () => {
          setFieldValue("nftLotAmount", values.nftLotAmount + 1);
        };

        const handleNFTDecrement = () => {
          const { nftLotAmount } = values;
          if (nftLotAmount > 1) {
            setFieldValue("nftLotAmount", nftLotAmount - 1);
          }
        };
        const { lotAmount, nftLotAmount } = values;

        return (
          <Form>
            <Header>Archon $WEE Staking Lot</Header>

            <LotSelectContainer>
              <label className="checkbox-label staking-lot-modal-check">
                <input
                  type="checkbox"
                  id="agree"
                  alt=""
                  checked={royaSelected}
                  onChange={() => setRoyaSelected(true)}
                />
                <span className="mark2"></span>
              </label>
              <LotSelect>
                <ImgSignContainer onClick={handleDecrement}>
                  <img src={MinusIcon.default} alt="subtract" />
                </ImgSignContainer>
                <div>{lotAmount}</div>
                <ImgSignContainer onClick={handleIncrement}>
                  <img src={PlusIcon.default} alt="add" />
                </ImgSignContainer>
              </LotSelect>

              <LotText>x</LotText>
              <LotTextSecondary className="notranslate">
                {thousandSeparator(LotPrice.ArchonLot.toString())} $WEE
              </LotTextSecondary>
              <LotTextSecondary>=</LotTextSecondary>
              <LotTextSecondary className="notranslate">
                {thousandSeparator((LotPrice.ArchonLot * lotAmount).toString())}{" "}
                $WEE
              </LotTextSecondary>
            </LotSelectContainer>

            <LotAmountText>
              Available:{" "}
              <span className="notranslate">
                {`${
                  !!userRoyaBalance &&
                  renderTokenAmountText(fromRoya(userRoyaBalance))
                } $WEE`}
              </span>
            </LotAmountText>

            <ErrorText>
              <ErrorMessage name="lotAmount" />
            </ErrorText>

            <LotSelectContainer>
              <label className="checkbox-label staking-lot-modal-check">
                <input
                  type="checkbox"
                  id="agree"
                  alt=""
                  checked={!royaSelected}
                  onChange={() => setRoyaSelected(false)}
                />
                <span className="mark2"></span>
              </label>
              <LotSelect>
                <ImgSignContainer onClick={handleNFTDecrement}>
                  <img src={MinusIcon.default} alt="subtract" />
                </ImgSignContainer>
                <div>{nftLotAmount}</div>
                <ImgSignContainer onClick={handleNFTIncrement}>
                  <img src={PlusIcon.default} alt="add" />
                </ImgSignContainer>
              </LotSelect>

              <LotText>x</LotText>
              <LotTextSecondary className="notranslate">NFT</LotTextSecondary>
              <LotTextSecondary>=</LotTextSecondary>
              <LotTextSecondary className="notranslate">
                {nftLotAmount} NFT
              </LotTextSecondary>
            </LotSelectContainer>
            <LotAmountText>
              Available:{" "}
              <span className="notranslate">{`${nftAmount} NFT`}</span>
            </LotAmountText>
            <ErrorText>
              <ErrorMessage name="nftLotAmount" />
            </ErrorText>

            <WarningTextCenter>
              Disclaimer: <br /> There is an 8 day lockup period
            </WarningTextCenter>

            <BtnContainerSecondary>
              <BuyLotButton
                type="submit"
              >
                Stake
              </BuyLotButton>
              <BorderForBtn>
                <CancelLotButton type="button" onClick={closeModal}>
                  Cancel
                </CancelLotButton>
              </BorderForBtn>
            </BtnContainerSecondary>
            {lotOperation === StakingLotModals.buyArchon && (
              <LoadingText>{getConfig().transactionText}</LoadingText>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default BuyArchon;
