const environment =
  process.env.REACT_APP_ENV || process.env.REACT_APP_LOCAL_API || 'local';

export const endpoint =
  environment === 'production'
    ? process.env.REACT_APP_PROD_API
    : environment === 'staging'
    ? process.env.REACT_APP_STAGE_API
    : environment === 'local'
    ? process.env.REACT_APP_LOCAL_API
    : process.env.REACT_APP_DEV_API;

export const endpoint_api = environment==="dev"?process.env.REACT_APP_LOCAL_API:"http://localhost:3006";

export const API_URL = endpoint + '/graphql';