export const validatePassword = (value: string) => {
  let error = "";
  if (!value) {
    error = "Please enter your password.";
  } else if (value.length < 4) {
    error = "Value must be longer than 3 characters.";
  }
  return error;
};

export const validateEmail = (value: string) => {
  let error = "";
  if (!value) {
    error = "Please enter your email address.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address.";
  }
  return error;
};

export const validateYouTubeUrl = (url: string) => {
  if (url === undefined || url === "") {
    return false;
  } else {
    // eslint-disable-next-line no-useless-escape
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match === null || match === undefined) {
      return false;
    } else if (match && match[2].length !== 11) {
      return false;
    }
  }
};

export const isHttpValid = (url: string) => {
  try {
    const newUrl = new URL(url, 'http://example.com');
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
};

export const validURL = (url: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?"+ // protocol (optional)
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  ); // case-insensitive
  return !!pattern.test(url);
};

export const validateCloudStorageUrl = (url: string) => {
  if (url === undefined || url === "") {
    return false;
  } else {
    // Google Drive link pattern
    const googleDriveRegExp =
      /^https:\/\/drive\.google\.com\/(?:[^/]+\/){3}([^/?]+).*$/;
    // Dropbox link pattern
    const dropboxRegExp = /^https:\/\/www\.dropbox\.com\/.*$/;
    return googleDriveRegExp.test(url) || dropboxRegExp.test(url);
  }
};

export const validateThumbnailLink = (url: string) => {
  if (url === undefined || url === "") {
    return true; // Optional: You can decide whether an empty field is valid or not
  } else {
    // Assuming a general URL format validation
    const urlRegExp = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegExp.test(url);
  }
};
