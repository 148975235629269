/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import { setLoading } from "../../../logic/actions/loading.action";
import {
  ContractAddress,
  Paths,
  getPoolContract,
  getTokenContract,
  useAppSelector,
} from "../../../utils";

import {
  ArrowDownBlackIcon,
  NoListImg,
  PlaceHolderImg,
} from "../../../images";
import { GameDetailContainer } from "./item";
import { Game, PoolInfo } from "../../../core";

import "./style.scss";
import { Sidebar } from "../sidebar";
import { GameDetailCardContainer } from "./game-detail";
import { toast } from "react-toastify";
import { SetFeeAddress } from "./modals/set-fee-address";
import { SetFeePercentage } from "./modals/set-fee-percentage";
import { SetTreasureAddress } from "./modals/set-treasure-address";
import Web3 from "web3";
import { ethers } from "ethers";
const web3 = new Web3(Web3.givenProvider);

const GameLinkProps = [
  {
    type: 1,
    status: false,
    text: "Deploy Contract",
    iconStatus: true,
    btnText: "Deploy",
  },
  {
    type: 2,
    status: false,
    text: "Set Pool Details",
    btnText: "Set Pool",
  },
  {
    type: 3,
    status: false,
    text: "Approve Allowance",
    iconStatus: true,
    btnText: "Approve",
  },
  {
    type: 4,
    status: false,
    text: "Add Funds",
    iconStatus: true,
    btnText: "Add Funds",
  },
  {
    type: 6,
    status: false,
    text: "Set Treasurer Address",
    iconStatus: true,
    btnText: "Set Treasurer Address",
  },
  // {
  //   type: 7,
  //   status: false,
  //   text: "Set Fund Address",
  //   iconStatus: true,
  //   btnText: "Set Fee Address",
  // },
  // {
  //   type: 8,
  //   status: false,
  //   text: "Set Fee Percentage",
  //   iconStatus: true,
  //   btnText: "Set Fee Percentage",
  // },
  {
    type: 9,
    status: false,
    text: "See Safelist",
    btnText: "Edit",
  },
  {
    type: 10,
    status: false,
    text: "PRIVATE - Edit Whitelist",
    btnText: "Edit",
  },
  { type: 11, status: false, text: "PUBLIC - Edit Whitelist", btnText: "Edit" },
  {
    type: 12,
    status: false,
    text: "Withdraw Unsold Tokens",
    btnText: "WITHDRAW",
  },
  { type: 13, status: false, text: "Withdraw Funds", btnText: "WITHDRAW" },
];

export const MyGame = (): JSX.Element => {
  const dispatch = useDispatch();

  const [gameInfo, setGameInfo] = useState<Game>();
  const [show, setShow] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [showFeeAddress, setShowFeeAddress] = useState(false);
  const [showFeePercentage, setShowFeePercentage] = useState(false);
  const [showTreasureAddress, setShowTreasureAddress] = useState(false);

  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  const history = useHistory();

  useEffect(() => {
    if (walletConnected) {
      findGameByUser();
    }
  }, [walletConnected, updateIndex]);

  const findGameByUser = async () => {
    const result = await Game.findGameByUser();
    if (!result.error) {
      setGameInfo(result.result);

      // TODO: need to store redux
    }
  };

  const handleClick = (type: number) => {
    if (gameInfo) {
      if (type === 2) {
        if (gameInfo.poolInfo) {
          history.push(`/${gameInfo.id}${Paths.editPool}`);
        } else {
          history.push(`/${gameInfo.id}${Paths.createPool}`);
        }
      } else if (type === 9) {
        history.push(`/account/${gameInfo.id}/safelist`);
      } else if (type === 10) {
        history.push(`/account/${gameInfo.id}/private-whitelist`);
      } else if (type === 11) {
        history.push(`/account/${gameInfo.id}/public-whitelist`);
      } else if (type === 1) {
        callNewPoolMethod();
      } else if (type === 3) {
        approveOperation();
      } else if (type === 4) {
        addFundOperation();
      } else if (type === 12) {
        withdrawTokens();
      } else if (type === 13) {
        withdrawFunds();
      } else if (type === 6) {
        setShowTreasureAddress(true);
      }
    }
  };

  const withdrawTokens = async () => {
    if (gameInfo?.poolInfo) {
      dispatch(setLoading(true));
      getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.withdrawUnsoldTokens()
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transaction hash unstake rpt ", hash);
        })
        .on("receipt", (receipt: any) => {
          toast("Withdraw Funds successfully!", { type: toast.TYPE.SUCCESS });
          setUpdateIndex(updateIndex + 1);
          dispatch(setLoading(false));
        })
        .on("error", (error: any) => {
          dispatch(setLoading(false));
          toast(
            "Transaction has failed, please contact admin to get support!",
            { type: toast.TYPE.WARNING }
          );
        });
    }
  };

  const withdrawFunds = async () => {
    if (gameInfo?.poolInfo) {
      dispatch(setLoading(true));
      getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.withdrawFunds()
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transaction hash unstake rpt ", hash);
        })
        .on("receipt", (receipt: any) => {
          toast("Withdraw Funds successfully!", { type: toast.TYPE.SUCCESS });
          setUpdateIndex(updateIndex + 1);
          dispatch(setLoading(false));
        })
        .on("error", (error: any) => {
          dispatch(setLoading(false));
          toast(
            "Transaction has failed, please contact admin to get support!",
            { type: toast.TYPE.WARNING }
          );
        });
    }
  };

  const approveOperation = async () => {
    if (gameInfo?.poolInfo) {
      dispatch(setLoading(true));
      getTokenContract(gameInfo.poolInfo?.tokenAddress)
        .methods.increaseAllowance(
          gameInfo.poolInfo?.poolAddress,
          gameInfo.poolInfo?.totalTokens
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transaction hash unstake rpt ", hash);
        })
        .on("receipt", (receipt: any) => {
          toast("Token Allowance successfully!", { type: toast.TYPE.SUCCESS });
          setUpdateIndex(updateIndex + 1);
          dispatch(setLoading(false));
        })
        .on("error", (error: any) => {
          dispatch(setLoading(false));
          toast(
            "Transaction has failed, please contact admin to get support!",
            { type: toast.TYPE.WARNING }
          );
        });
    }
  };

  const addFundOperation = async () => {
    if (gameInfo?.poolInfo && gameInfo.poolInfo?.poolAddress) {
      dispatch(setLoading(true));
      getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.addTokensForSale(gameInfo.poolInfo?.totalTokens)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transaction hash unstake rpt ", hash);
        })
        .on("receipt", (receipt: any) => {
          toast("Adding Fund successfully!", { type: toast.TYPE.SUCCESS });
          setUpdateIndex(updateIndex + 1);
          dispatch(setLoading(false));
        })
        .on("error", (error: any) => {
          dispatch(setLoading(false));
          toast(
            "Transaction has failed, please contact admin to get support!",
            { type: toast.TYPE.WARNING }
          );
        });
    }
  };

  async function callNewPoolMethod() {
    try {
      if (window.ethereum) {
        dispatch(setLoading(true));
        const poolFactory = require("../../../utils/abis/pool-factory.json");
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new web3.eth.Contract(
          poolFactory.abi,
          ContractAddress.sepolia.poolFactory
        );
        const signer = await provider?.getSigner();
        const userAddress = await signer?.getAddress();

        try {
          await contract.methods
            .createNewPool(gameInfo?.name)
            .send({ from: userAddress })
            .on("transactionHash", (hash: string) => {})
            .on("receipt", async (receipt: any) => {
              toast("Pool created successfully!", {
                type: toast.TYPE.SUCCESS,
              });
              const { error } = await PoolInfo.upsertPool({
                gameId: gameInfo?.id,
                name: "",
                poolAddress: receipt.events["PoolCreated"]["returnValues"][1],
              });
              if (error) {
                toast(
                  "Saving Pool info failed, please contact admin to get support!",
                  { type: toast.TYPE.WARNING }
                );
              }
              dispatch(setLoading(false));
              setUpdateIndex(updateIndex + 1);
            })
            .on("error", (error: any) => {
              dispatch(setLoading(false));
              toast(
                "Transaction has failed, please contact admin to get support!",
                { type: toast.TYPE.WARNING }
              );
            });
        } catch (error) {
          // debugger
          console.error("Error in contract method execution:", error);
          dispatch(setLoading(false));
        }
      } else {
        console.error("MetaMask is not installed or not available.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
  return (
    <div className="my-game-wrapper">
      <Sidebar />
      <div className="content">
        <div className="title-action">Edit my project</div>
        <div className="admin-table-box">
          <div className="main-container">
            {walletConnected ? (
              gameInfo ? (
                <>
                  <div className="game-title-container">
                    <div
                      className="game-title-container-row"
                      onClick={() => setShow(!show)}
                    >
                      <div>
                        <img
                          src={
                            gameInfo?.thumbnail
                              ? gameInfo?.thumbnail.split(",")[0]
                              : PlaceHolderImg.default
                          }
                          alt="game icon"
                        />
                        <span>{gameInfo?.name}</span>
                      </div>
                      <img
                        src={
                          show ? ArrowDownBlackIcon.default : ArrowDownBlackIcon.default
                        }
                        alt="arrow down icon"
                      />
                    </div>
                    {show && gameInfo && (
                      <GameDetailCardContainer gameInfo={gameInfo} />
                    )}
                  </div>
                  <div className="game-detail-container">
                    {GameLinkProps.map((item, index) => (
                      <GameDetailContainer
                        key={index}
                        type={item.type}
                        iconStatus={item.iconStatus}
                        status={item.status}
                        text={item.text}
                        gameInfo={gameInfo}
                        btnText={item.btnText}
                        handleClick={handleClick}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="project-nosubmit-container">
                  <img src={NoListImg.default} alt="" />
                  <h3>You haven’t submitted a project yet</h3>
                </div>
              )
            ) : (
              <div className="project-nosubmit-container">
                <h3>Please connect wallet first.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      {showFeeAddress && (
        <SetFeeAddress
          onClose={() => {
            setUpdateIndex(updateIndex + 1);
            setShowFeeAddress(false);
          }}
          poolInfo={gameInfo?.poolInfo}
        />
      )}
      {showFeePercentage && (
        <SetFeePercentage
          onClose={() => {
            setUpdateIndex(updateIndex + 1);
            setShowFeePercentage(false);
          }}
          poolInfo={gameInfo?.poolInfo}
        />
      )}
      {showTreasureAddress && (
        <SetTreasureAddress
          onClose={() => {
            setUpdateIndex(updateIndex + 1);
            setShowTreasureAddress(false);
          }}
          poolInfo={gameInfo?.poolInfo}
        />
      )}
    </div>
  );
};
