import {GET_GAMES_SUCCESS, SET_GAME_APPROVE_SUCCESS} from "../actions";

const initialState = {
  games: []
}

const gameReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_GAMES_SUCCESS:
      return {
        ...state,
        games: action.games
      }

    case SET_GAME_APPROVE_SUCCESS:
      return {
        ...state,
        games: action.games
      }
    default:
      return state;
  }
}

export default gameReducer;
