import {
  Tier01GifImg,
  Tier02GifImg,
  Tier03GifImg,
  Tier04GifImg,
  Tier05GifImg,
  Tier01Img,
  Tier02Img,
  Tier03Img,
  Tier04Img,
  Tier05Img,
} from "../images";

export type NetworkTypes =
  | "mainnet"
  | "sepolia";

export const configNetwork: NetworkTypes =
  process.env.CURRENT_NETWORK === "mainnet" ? "mainnet" : "sepolia";

export const Paths = {
  landingZone: "/",
  allProjects: "/projects",
  submitGame: "/submit-project",
  poolDetails: "/pool",
  createPool: "/create-pool",
  stakingLots: "/staking-lots",
  terms: "/terms-of-use",
  // gameDetails: '/game-details',
  gameDetails: "/projects",

  poolregistration: "/pool-registration",
  dashboard: "/dashboard",

  editPool: "/edit-pool",
  royaReserve: "/roya-reserve",
  account: "/account",
  nfts: "/nfts",
};

export const StableCoins = ["ETH", "USDT", "USDC", "DAI"];

export const PoolsTab = {
  allSales: 1,
  upcomingAndLive: 2,
  applied: 3,
  completed: 4,
};

export const PoolDetailTab = {
  detail: 1,
  about: 2,
};

export const CreatePoolSteps = {
  setPool: 0,
  tokenDetails: 1,
  swapDetails: 2,
  setDates: 3,
  poolAllocation: 4,
};

export const PoolTypes = {
  standard: false,
  instant: true,
};

export const AccessType = {
  public: "public",
  private: "private",
};

export const AllocationType = {
  limited: "limited",
  unlimited: "unlimited",
};

export const WalletTypes = {
  default: 0,
  metamask: 1,
  walletConnect: 2,
  authereum: 3,
  burnerConnect: 4,
  uniLogin: 5,
  mewWallet: 6,
};

export const ConnectSteps = {
  select: 1,
  connect: 2,
  accept: 3,
};

export const ModalTypes = {
  closed: 0,
  stake: 1,
  claimMRoya: 2,
  claimRewards: 3,
  withdrawFunds: 4,
  claimStableCoins: 5,
};

export const StakeSteps = {
  confirm: 0,
  processing: 1,
  success: 2,
};

export const WithdrawSteps = {
  confirm: 0,
  success: 1,
};

export const StoreageKey = {
  doNotShowAgain: "doNotShowAgain",
  walletType: "walletType",
};

export const StakingLotModals = {
  closed: 0,
  buysettler: 1,
  buymerchant: 2,
  buyKnight: 3,
  buyArchon: 4,
  buyMonarch: 5,
  sellSettler: 6,
  sellMerchant: 7,
  sellKnight: 8,
  sellArchon: 9,
  sellMonarch: 10,
};

export const LotRewardProcess = {
  idle: 0,
  settlerReward: 1,
  merchantReward: 2,
  qlotReward: 3,
  klotReward: 4,
  flotReward: 5,
};

export const RtpStakingModal = {
  closed: 0,
  unlockRtp: 1,
  lockRtp: 2,
};

export const RoyalFlushModals = {
  closed: 0,
  acquireLots: 1,
  convertLots: 2,
};

export const LockRptSteps = {
  confirm: 1,
  success: 2,
};

export const Config = {
  mainnet: {
    etherscanLink: "https://base-expolorer.base.org",
    apiUrl: "https://transactionapiprod.royale.finance",
    transactionText: "Transaction Pending",
    iGamingOperator: "https://royalefinance.typeform.com/to/Yy7Nb02c",
    coinGeckoApi: "https://api.coingecko.com/api/v3/coins",
    infuraId: "c7a95b91ffae44e3b7fb80d9fbb98939",
  },
  sepolia: {
    etherscanLink: "https://testnet.bscscan.com",
    apiUrl: "https://transactionapiprod.royale.finance",
    defaultGasPrice: "15",
    transactionText: "Transaction Pending",
    iGamingOperator: "https://royalefinance.typeform.com/to/Yy7Nb02c",
    coinGeckoApi: "https://api.coingecko.com/api/v3/coins",
    infuraId: "c7a95b91ffae44e3b7fb80d9fbb98939",
  },
};

export const getConfig = () => {
  return Config[configNetwork!];
};

export const SuccessMsgType = {
  hide: 0,
  showBuySuccess: 1,
  showSellSuccess: 2,
};

export const RoyaReserveModal = {
  closed: 0,
  cooldownActivated: 1,
  unstake: 2,
};

export const SocialLinks = {
  twitter: "https://x.com/weeweepadwtf",
  telegramOfficial: "https://t.me/weeweepadwtf",
  telegramAnnouncement: "https://t.me/weeweepadwtf",
  medium: "https://medium.com/@officialroyale",
  docs: "https://royalefinance.gitbook.io/royale-finance-docs/",
  mRoyaSwap: "https://staging.d2brf9r7tb77dk.amplifyapp.com/",
};

export const Networks = {
  mainnet: "0x2105",
  ropsten: "0x3",
  rinkeby: "0x4",
  goerli: "0x5",
  kovan: "0x2a",
  moonBaseAlpha: "0x507",
  bscTestnet: "0x61",
  bscMainnet: "0x38",
  maticTestnet: "0x13881",
  maticMainnet: "0x89",
  sepolia: "0x14a34",
};

export const LotPrice = {
  settlerLot: 1000,
  merchantLot: 5000,
  KnightLot: 10000,
  ArchonLot: 50000,
  MonarchLot: 250000,
};

export const RewardsPerBlock = {
  rpt: 4,
  roya: 0.2,
};

export const EtherscanLinks = {
  ethereum: "https://etherscan.io",
  binance: "https://bscscan.com",
  matic: "https://polygonscan.com",
  base: "https://base-explorer.base.org",
};

export const NetworkOptions = {
  ethereum: "ethereum",
  matic: "matic",
  binance: "binance",
};

export const SwapProcess = {
  idle: 0,
  inWallet: 1,
  stakeRoya: 2,
};

export interface RoyaStakingInterface {
  none: RoyaStakeModel;
  settler: RoyaStakeModel;
  merchant: RoyaStakeModel;
  knight: RoyaStakeModel;
  archon: RoyaStakeModel;
  monarch: RoyaStakeModel;
}

export interface RoyaStakeModel {
  title: string;
  img: string;
  icon: string;
  price: number;
  description: string;
  stake: number;
  slot: number;
  allocation: number;
  buy: number;
  sell: number;
}

export const RoyaStaking: RoyaStakingInterface = {
  none: {
    buy: 0,
    sell: 0,
    title: "No Tier",
    img: Tier01GifImg.default,
    icon: Tier01Img.default,
    price: 0,
    slot: 0,
    stake: 0,
    allocation: 0,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  settler: {
    buy: 1,
    sell: 6,
    title: "Settler",
    img: Tier01GifImg.default,
    icon: Tier01Img.default,
    price: 1000,
    slot: 150,
    stake: 1000,
    allocation: 30,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  merchant: {
    buy: 2,
    sell: 7,
    title: "Merchant",
    img: Tier02GifImg.default,
    icon: Tier02Img.default,
    price: 5000,
    slot: 100,
    stake: 5000,
    allocation: 45,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  knight: {
    buy: 3,
    sell: 8,
    title: "Knight",
    img: Tier03GifImg.default,
    icon: Tier03Img.default,
    price: 10000,
    slot: 80,
    stake: 10000,
    allocation: 60,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  archon: {
    buy: 4,
    sell: 9,
    title: "Archon",
    img: Tier04GifImg.default,
    icon: Tier04Img.default,
    price: 50000,
    slot: 50,
    stake: 50000,
    allocation: 75,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  monarch: {
    buy: 5,
    sell: 10,
    title: "Monarch",
    img: Tier05GifImg.default,
    icon: Tier05Img.default,
    price: 250000,
    slot: 25,
    stake: 250000,
    allocation: 90,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
};

export interface NftOptionInterface {
  settler: NftModel;
  merchant: NftModel;
  knight: NftModel;
  archon: NftModel;
  monarch: NftModel;
}

export interface NftModel {
  title: string;
  img: string;
  icon: string;
  price: number;
  description: string;
  stake: number;
  slot: number;
  allocation: number;
  buy: number;
  sell: number;
}

export const NftOptions: NftOptionInterface = {
  settler: {
    buy: 1,
    sell: 6,
    title: "Settler",
    img: Tier01GifImg.default,
    icon: Tier01Img.default,
    price: 0.03,
    slot: 150,
    stake: 1000,
    allocation: 30,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  merchant: {
    buy: 2,
    sell: 7,
    title: "Merchant",
    img: Tier02GifImg.default,
    icon: Tier02Img.default,
    price: 0.05,
    slot: 100,
    stake: 5000,
    allocation: 45,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  knight: {
    buy: 3,
    sell: 8,
    title: "Knight",
    img: Tier03GifImg.default,
    icon: Tier03Img.default,
    price: 0.08,
    slot: 80,
    stake: 10000,
    allocation: 60,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  archon: {
    buy: 4,
    sell: 9,
    title: "Archon",
    img: Tier04GifImg.default,
    icon: Tier04Img.default,
    price: 0.1,
    slot: 50,
    stake: 50000,
    allocation: 75,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
  monarch: {
    buy: 5,
    sell: 10,
    title: "Monarch",
    img: Tier05GifImg.default,
    icon: Tier05Img.default,
    price: 0.13,
    slot: 25,
    stake: 250000,
    allocation: 90,
    description:
      "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard",
  },
};

///// Need to remove later
export const RoyaTier = {
  prince: 25,
  princess: 50,
  queen: 75,
  king: 100,
};

export const TierType = {
  settler: 0,
  merchant: 1,
  knight: 2,
  archon: 3,
  monarch: 4,
};

export const TiersType = ["Settler", "Merchant", "Knight", "Archon", "Monarch"];

export const TokenType = ["ETH", "USDT", "USDC", "DAI"];
