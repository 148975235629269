import React from 'react';
import Papa from 'papaparse';

import './styles.scss';

import { UploadWhiteIcon, LinkIcon } from '../../../../images';
import { SelectFileForm, NewModal, Input } from 'src/components';

interface Props {
  onClose: (values?: Array<any>, maxWhitelisted?: number) => void;
}

export const UploadCSVModal: React.FC<Props> = ({ onClose = () => true }) => {
  const [data, setData] = React.useState<Array<any>>([]);
  const [maxWhitelisted, setMaxWhitelisted] = React.useState('');
  const [fileName, setFileName] = React.useState('')

  async function parseFile(file: any): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          resolve(results.data);
        }
      });
    });
  }

  async function handleFile(files: any) {
    let results: Array<any> = [];
    for (const file of files) {
      const result = await parseFile(file);
      if (result.length <= 0) continue;

      results = [...results, ...result.slice(1, result.length)];
    }

    setFileName(files[0].name)
    setData(results);
  }

  function handleSave() {
    if (data.length <= 0 || !!!Number(maxWhitelisted)) return;

    onClose(data, parseInt(maxWhitelisted));
  }

  return (
    <NewModal
      className="upload-csv-modal"
      title="Public Whitelist Bulk Upload"
      actions={[
        {
          type: 'primary',
          label: 'Save',
          onClick: () => handleSave()
        }
      ]}
      onClose={() => onClose()}
    >
      <div className="upload-csv-modal-body">
        <label>Upload</label>
        <SelectFileForm fileName={fileName} onSelect={(files) => handleFile(files)}>
          <span className="btn-upload">
            <img src={UploadWhiteIcon.default} alt="upload" />
            <span>UPLOAD CSV</span>
          </span>
        </SelectFileForm>
        <a
          className="btn-sample-csv"
          href="/test-safelist.csv"
          download="sampleCSV.csv"
        >
          <img src={LinkIcon.default} alt="sample" />
          <span>See CSV sample</span>
        </a>
        <Input
          className="max-whitelisted-input"
          type="number"
          placeholder="Maximum Whitelisted"
          value={maxWhitelisted}
          onChange={(event) => setMaxWhitelisted(event.target.value)}
          style={{ "color": "black" }}
        />
      </div>
    </NewModal>
  );
};
